import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { updateProductProgressPoint } from "components/UI/Functions/progressFunctions";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

export default function ProductKickoffModal({
  show,
  handleClose,
  product,
  productPersonnel,
  refetch,
}) {
  const [cookies] = useCookies();
  const { authToken, csrfToken } = useAuth();

  const [rejected, setRejected] = useState(false);
  const [kickoffValidator, setKickoffValidator] = useState({});
  const [reason, setReason] = useState("");
  const [estCompletionDates, setEstimatedCompletionDates] = useState({});

  useEffect(() => {
    const validator = productPersonnel?.find(
      (person) => person?.user_id?.id === product?.kickoff_recommended_by?.id,
    );
    setKickoffValidator(validator);
  }, [product, productPersonnel]);

  const handleSend = (action) => {
    const payload = {
      status: action === "approved" ? "In Progress" : "Pre-Evaluation",
      rejected_rationale: action === "approved" ? null : reason,
      kickoff_status:
        action === "approved" ? "Kicked Off" : "Ready for Recommendation",
    };

    if (action === "approved") {
      payload["kicked_off_date"] = new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      );
      payload["evaluator_checkout_date"] =
        estCompletionDates["evaluator_checkout_date"];
      payload["validator_checkout_date"] =
        estCompletionDates["validator_checkout_date"];
      payload["pcl_posting_date"] = estCompletionDates["pcl_posting_date"];
    }

    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
        payload,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(async (response) => {
        if (action === "approved") {
          await updateProductProgressPoint(
            response.data.product_id,
            2,
            "Completed",
            cookies,
          );
        }

        refetch();
        handleClose(response.data, action);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    setEstimatedCompletionDates({
      ...estCompletionDates,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setRejected(false);
        handleClose();
      }}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title color="primary">
            {rejected ? "Rationale" : "Proposed Kickoff Recommendation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5 className="mx-2">
                <strong>{product?.vendor_id?.name}</strong>
              </h5>
              <h5 className="mx-2">
                <strong>
                  VID{product?.vid} - {product?.product_name}
                </strong>
              </h5>
              <h6 className="mx-2 mb-4 position-end">
                Project Requested: <br />
                By: {product?.created_by?.first_name}{" "}
                {product?.created_by?.last_name} <br />
                From: {product?.assigned_lab?.name} <br />
                On:{" "}
                {product?.created_on &&
                  moment.utc(product?.created_on).format("MM/DD/YYYY")}
              </h6>
              <h6 className="mx-2 mb-4 position-end">
                Recommended For Kickoff: <br />
                By: {product?.kickoff_recommended_by?.first_name}{" "}
                {product?.kickoff_recommended_by?.last_name} <br />
                As: {kickoffValidator?.role} <br />
                On:{" "}
                {product?.kickoff_recommended_date &&
                  moment
                    .utc(product?.kickoff_recommended_date)
                    .format("MM/DD/YYYY")}
              </h6>
              <h6 className="mx-2 mb-4 position-end">
                Sync Required?: {product?.sync_required ? "Yes" : "No"}
                <br />
                {product?.sync_justification &&
                  "Justification: " + product.sync_justification}
              </h6>
            </Col>
            <Col>
              <Form.Group controlId="evaluator_checkout_date">
                <Form.Label> Progress Point 6 Est. Completion Date:</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="date"
                  defaultValue={moment
                    .utc(product?.evaluator_checkout_date)
                    .format("YYYY-MM-DD")}
                  name="evaluator_checkout_date"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="validator_checkout_date">
                <Form.Label> Progress Point 7 Est. Completion Date:</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="date"
                  name="validator_checkout_date"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="completion_date">
                <Form.Label> Progress Point 8 Est. Completion Date:</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="date"
                  name="pcl_posting_date"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <ResponsiveMaterialTable
            title="Proposed Validation Team"
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  team: "Validation",
                  product: product?.product_id,
                };
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                    {
                      withCredentials: true,
                      headers: {
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            columns={[
              { title: "VID", field: "product_id.v_id" },
              { title: "Company", field: "pp_team_id.name" },
              { title: "Product", field: "product_id.product_name" },
              { title: "First Name", field: "user_id.first_name" },
              { title: "Last Name", field: "user_id.last_name" },
              { title: "Role", field: "role" },
            ]}
          />
          {rejected && (
            <Form.Group className="mt-4">
              <Form.Label as="h4">Rationale For Rejection</Form.Label>
              <Form.Control
                rows={3}
                as="textarea"
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
              setRejected(false);
            }}
          >
            Cancel
          </Button>
          {rejected ? (
            <Button type="button" onClick={() => handleSend("rejected")}>
              Send
            </Button>
          ) : (
            <>
              <Button
                variant="danger"
                type="button"
                onClick={() => setRejected(true)}
              >
                Reject
              </Button>
              <Button type="button" onClick={() => handleSend("approved")}>
                Approve For Kickoff
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
