import { baseApi } from "api/BaseApi";

import { AllArchivedOrRelatedTdsResponse } from "./technicalDecisions.types";

const TECHNICAL_DECISIONS_BASE_URL = "technical-decision";

const technicalDecisionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllArchivedOrRelatedTdsById: build.query<
      AllArchivedOrRelatedTdsResponse,
      number | string
    >({
      query: (id) => ({
        url: `${TECHNICAL_DECISIONS_BASE_URL}/get_all_archived_or_related_tds_by_id/`,
        params: {
          profile: id,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetAllArchivedOrRelatedTdsByIdQuery } = technicalDecisionsApi;
