import { Row, Col, Form } from "react-bootstrap";

import { TECH_TYPE_OPTIONS_ARRAY } from "common/constants/techTypes";
import HTMLEditor from "components/UI/HTMLEditor";

import PPFormSection from "./PPFormSection";

export default function EvaluationInfoFormSection({
  product,
  editedProduct,
  handleChange,
  setProdDescValid,
  prodDescValid,
  ppValid,
  nameValid,
  productPps,
}) {
  return (
    <Row className="mb-3">
      <p className="fw-bold form-title">EVALUATION INFORMATION</p>
      <Row>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="product_name">
            <Form.Label>Product Name:*</Form.Label>
            <Form.Control
              className="form-indent-readOnly"
              name="product_name"
              defaultValue={product?.product_name}
              placeholder="Product Name"
              onChange={handleChange}
              isValid={nameValid}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="version">
            <Form.Label>Product Version:</Form.Label>
            <Form.Control
              className="form-indent-readOnly"
              type="text"
              name="version"
              defaultValue={product?.version}
              onChange={handleChange}
              placeholder="Version"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} sm={12}>
          <Form.Group className="mb-3" controlId="tech_type">
            <Form.Label>Recommended Technology Type:*</Form.Label>
            <Form.Select
              name="tech_type"
              onChange={handleChange}
              defaultValue={product?.tech_type?.split(",") ?? []}
              required
              multiple={true}>
              <option value="">Please Select Tech Type</option>
              {TECH_TYPE_OPTIONS_ARRAY?.map((filteredVal, i) => (
                <option key={i} value={filteredVal}>
                  {filteredVal}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <PPFormSection
        product={product}
        editedProduct={editedProduct}
        handleChange={handleChange}
        ppValid={ppValid}
        productPps={productPps}
      />
      <Row className="mt-4">
        <small className="p-3">
          Include Administrative Guide, Product URL, or other information to prove details about the product to be
          evaluated.
        </small>
        <Form.Group className="my-3" controlId="url">
          <Form.Label>Product URL:*</Form.Label>
          <Form.Control
            className="form-indent-readOnly"
            type="url"
            name="url"
            defaultValue={product?.url}
            onChange={handleChange}
            placeholder="https://example.com"
            required
          />
        </Form.Group>
      </Row>
      <Row>
        <Col sm={12} className="mt-3 mb-4">
          <Form.Group className="mb-3" controlId="product_description">
            <Form.Label>Product Description:*</Form.Label>
            <HTMLEditor
              name="product_description"
              handleChange={handleChange}
              setIsValid={setProdDescValid}
              isValid={prodDescValid}
              defaultValue={product?.product_description}
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
