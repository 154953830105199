import { valueof } from "api/common/utils/type.utils";

export const ACCESS_LEVEL = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DESTROY: "destroy",
} as const;

export type AccessLevel = valueof<typeof ACCESS_LEVEL>;

export const AUTH_ROLES = {
  NIAP: "NIAP",
  LAB: "Lab",
  VALIDATOR: "Validator",
  TECH_COMMUNITY: "Tech Community",
  VENDOR: "Vendor",
  SME: "SME",
  EXTERNAL: "External",
  UNAUTHORIZED: "UNAUTHORIZED",
} as const;

export type AuthRole = valueof<typeof AUTH_ROLES>;

export const PERMISSION_AREAS = {
  AM_APPROVAL_AUTHORITY: "AM Approval Authority",
  ASSURANCE_MAINTENANCE: "Assurance Maintenance",
  COMPANY_PROFILE: "Company Profile",
  DOCUMENT_MANAGER: "Document Manager",
  EAR_REVIEWS: "EAR Reviews",
  ECR_COMMENTS: "ECR Comments",
  EVALUATION_TEAM: "Evaluation Team",
  ORGANIZATION_MEMBERS: "Organization Members",
  ORGANIZATION_PROJECTS: "Organization Projects",
  PROJECT_MEMBERS: "Project Members",
  PROTECTION_PROFILE: "Protection Profile",
  TECHNICAL_DECISIONS: "Technical Decisions",
  TECHNICAL_QUESTIONS: "Technical Questions",
  VALIDATION_TEAM: "Validation Team",
  VIRTUAL_TEAMS: "Virtual Teams",
  ACCOUNTS: "Accounts",
  COMMUNITY: "Community",
  ORGANIZATION: "Organization",
  PROJECT: "Project",
} as const;

export type PermissionArea = valueof<typeof PERMISSION_AREAS>;

export interface Role {
  role_id: number;
  role_name: AuthRole;
  role_type: AuthRole;
  deleted: boolean;
  is_lead: boolean;
  is_senior: boolean;
}

export interface RolePermission {
  id: number;
  area: PermissionArea;
  local_only: boolean;
  create: boolean;
  read: boolean;
  update: boolean;
  destroy: boolean;
  role: Role;
}
