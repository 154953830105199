import { baseApi } from "api/BaseApi";

import { File, FilesPayload } from "./files.types";

const FILE_BASE_URL = "file";

const filesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFilesByTypeAndId: build.query<File[], FilesPayload>({
      query: (params) => ({
        url: `${FILE_BASE_URL}/get_all_files_by_type_and_type_id/`,
        params,
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetFilesByTypeAndIdQuery } = filesApi;
