import { Link } from "react-router-dom";

interface VidLinkProps {
  vid: number;
}

const VidLink = ({ vid }: VidLinkProps) => {
  return (
    <Link
      to={`/community/products/details/${vid}`}
      style={{
        flex: 1,
        textAlign: "center",
        padding: "1em 0",
      }}
    >
      {vid}
    </Link>
  );
};

export default VidLink;
