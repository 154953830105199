import { Paper } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import file_types from "./DocumentTypes.json";

import "./fileUpload.css";
import { useSelector } from "react-redux";

const FileUpload = ({ show, updateFiles, type, id, vid, ccid }) => {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [file, setFile] = useState({});
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [alertSet, setAlertSet] = useState({ message: "", type: "" });
  const currentUser = useSelector((state) => state.user.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertSet({ message: "File Uploading...", type: "secondary" });
    const submittedData = new FormData();
    let currentDate = new Date(
      new Date().setMinutes(
        new Date().getMinutes() - new Date().getTimezoneOffset(),
      ),
    ).toISOString();
    submittedData.append("file_type", type);
    submittedData.append("file_type_id", id);
    submittedData.append(`file`, file?.file);
    submittedData.append("file_display_name", file?.file_type);
    submittedData.append("file_name", file?.file?.name);
    submittedData.append("file_label", file?.file_label);
    submittedData.append("file_location", "uploads");
    submittedData.append("file_mime_type", file?.file?.type);
    submittedData.append("uploaded_on", currentDate);
    submittedData.append("file_source_type", "Other");
    submittedData.append("active", JSON.stringify(true));
    if (file.file_public_status === "Proprietary") {
      submittedData.append("private", JSON.stringify(true));
    } else {
      submittedData.append("private", JSON.stringify(false));
    }
    if (type === "ccp" && ccid) {
      submittedData.append("ccid", ccid);
    }
    axios
      .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/`, submittedData, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const currentFiles = uploadedFiles;
        currentFiles.push(response.data);
        setUploadedFiles([...currentFiles]);
        setFile({});
        setEditedFileLabel("");
        e.target.reset();
        setAlertSet({ message: "File Uploaded Successfully", type: "success" });
      })
      .catch((error) => {
        if (file?.file === undefined || file?.file === null) {
          setAlertSet({
            message: `Error Uploading File: No File was Attached`,
            type: "danger",
          });
        } else {
          setAlertSet({
            message: `Error Uploading File: ${error.response.data.file}`,
            type: "danger",
          });
        }
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setFile({ ...file, [e.target.name]: e.target.files[0] });
    } else if (e.target.name === "file_type") {
      setEditedFileLabel(e.target.value);
      setFile({
        ...file,
        [e.target.name]: e.target.value,
        file_label: e.target.value,
      });
    } else if (e.target.name === "file_label") {
      setEditedFileLabel(e.target.value);
      setFile({ ...file, [e.target.name]: e.target.value });
    } else {
      setFile({ ...file, [e.target.name]: e.target.value });
    }
  };

  const columns = [
    { title: "File Type", field: "file_display_name" },
    { title: "Label", field: "file_label" },
    {
      title: "Name",
      field: "file_name",
      render: (rowData) =>
        rowData.file_new_name ? rowData.file_new_name : rowData.file_name,
    },
    { title: "Location", field: "file_location" },
    {
      title: "Document Visibility",
      field: "private",
      render: (rowData) =>
        rowData.private === true ? "Proprietary" : "Public",
    },
  ];

  const options = {
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    pageSize: 20,
    emptyRowsWhenPaging: false,
  };

  let filteredFileTypes = [];

  if (type === "product") {
    filteredFileTypes = file_types[type]?.[currentUser?.user_role?.role_type];
  } else {
    filteredFileTypes = file_types[type];
  }

  return (
    <Modal size="lg" show={show} onHide={updateFiles}>
      <Modal.Header closeButton>
        <Modal.Title as="h1">
          Upload Files {type === "product" ? "VID " + vid : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Container fluid>
            <Row className="pt-2">
              <Col xl={3} sm={12}>
                <Form.Group className="text-start" controlId="file_type">
                  <Form.Label className="small text-secondary">Type</Form.Label>
                  <Form.Select
                    name="file_type"
                    value={file?.file_type ?? ""}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {filteredFileTypes?.map((value, idx) => (
                      <option key={idx} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_public_status"
                >
                  <Form.Label className="small text-secondary">
                    Document Visibility
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    value={file?.file_public_status ?? ""}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="Public">Public</option>
                    <option value="Proprietary">Proprietary</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={5} sm={12}>
                <Form.Group className="text-start" controlId="file_label">
                  <Form.Label className="small text-secondary">
                    File Label
                  </Form.Label>
                  <Form.Control
                    value={editedFileLabel}
                    type="text"
                    name="file_label"
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={6} sm={12}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach Files
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    disabled={
                      !file.file_type ||
                      !file.file_public_status ||
                      !file.file_label
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Text className="text-muted">
                Please include File Type, Document Visibility, and File Label
                before uploading.
              </Form.Text>
            </Row>
            <Row>
              <Col className="d-flex justify-content-start mb-3 mt-2">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !file.file_type ||
                    !file.file_public_status ||
                    !file.file_label
                  }
                >
                  Upload File
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Alert
                  variant={alertSet.type}
                  dismissible={alertSet.type !== "secondary"}
                  onClose={() => setAlertSet({ message: "", type: "" })}
                >
                  {alertSet.type === "secondary" && (
                    <Spinner animation="border" size="sm" className="mx-2">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                  {alertSet.message}
                </Alert>
              </Col>
            </Row>
          </Container>
        </Form>
        <ResponsiveMaterialTable
          title={"Uploaded Files"}
          columns={columns}
          data={[...uploadedFiles]}
          options={options}
          components={{
            Container: (props) => <Paper {...props} />,
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FileUpload;
