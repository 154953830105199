import axios from "axios";
import qs from "qs";
import { useState, useRef, useEffect } from "react";

import ManageAMValidationTeamModal from "components/Dashboard/ProductsModule/ManageAMValidationTeamModal";
import ManageValidationTeamModal from "components/Dashboard/ProductsModule/ManageValidationTeamModal";
import DeleteModal from "components/UI/DeleteModal";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import buildProductColumns from "./ProductTable/ProductTable.columns";

export default function ProductsTable({
  sidebarFilters,
  refreshKey,
  refetch,
  projpps,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [product, setProduct] = useState({});
  const [showValTeamModal, setShowValTeamModal] = useState(false);
  const [showAMValTeamModal, setShowAMValTeamModal] = useState(false);
  const tableRef = useRef();
  const { authToken, csrfToken, permissions } = useAuth();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [sidebarFilters]);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const fetchProductURL = () => {
    if (
      permissions?.role_permissions?.find(
        (permission) => permission.area === "Project",
      )?.local_only
    ) {
      if (permissions?.role_type === "Lab") {
        return "project/product/current_user_products/";
      } else {
        return "project/product/table_view/";
      }
    } else {
      if (permissions?.role_permissions) {
        return "project/product/table_view/";
      }
    }
  };

  const handleDeleteDraft = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        {
          deleted: true,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
        setShowDeleteModal(false);
      })
      .catch((error) => console.log("Unable to update product: " + error));
  };

  const columns = buildProductColumns({
    sidebarFilters,
    setProduct,
    setShowDeleteModal,
    setShowAMValTeamModal,
    setShowValTeamModal,
    projpps,
  });

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    search: true,
    pageSizeOptions: [25, 50, 75, 100],
    pageSize: 50,
    debounceInterval: 500,
    emptyRowsWhenPaging: false,
  };

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        type="Draft"
        deleteFunction={handleDeleteDraft}
      />
      <ResponsiveMaterialTable
        title="Evaluation Projects"
        columns={columns}
        key={refreshKey}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () =>
              tableRef.current &&
              tableRef.current.dataManager.columns.forEach((item) => {
                tableRef.current.onFilterChange(item.tableData.id, "");
              }),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters
              .map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else if (
                  ["kicked_off_date"]?.includes(filter?.column?.field)
                ) {
                  const dateObj = new Date(filter?.value);
                  const isoStr = dateObj.toISOString();
                  const formattedStr = isoStr.split("T")[0];
                  value = formattedStr;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                if (filter.column.field === "status") {
                  if (value?.length > 0) {
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  } else {
                    return null;
                  }
                } else {
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                }
              })
              .filter((filter) => filter !== null);

            const params = {
              ...sidebarFilters,
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };

            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchProductURL()}?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
      <ManageValidationTeamModal
        show={showValTeamModal}
        setShow={setShowValTeamModal}
        currentProductInEval={product}
      />
      <ManageAMValidationTeamModal
        show={showAMValTeamModal}
        setShow={setShowAMValTeamModal}
        currentProductInEval={product}
      />
    </>
  );
}
