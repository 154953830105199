import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

// NOTE: This structure is based on PfaActions.cell.jsx
// NOTE: if we do another Action menu we should probably think about extracting this structure to a reusable component
const TqActionsCell = ({ tq, setDeleteTQ, setShowDeleteModal }) => {
  const { permissions } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isLab = permissions.role_type === "Lab";
  if (isLab) return null;

  const isNiapUser = permissions.role_type === "NIAP";
  const canDelete = permissions.role_permissions?.find(
    (permission) => permission.area === "Technical Questions",
  )?.destroy;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (canDelete && isNiapUser) {
    return (
      <>
        <div>
          <IconButton
            id={`actions-menu-button-${tq.tq_id}`}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
        </div>
        <Menu
          id={`actions-menu-${tq.tq_id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            "aria-labelledby": `actions-menu-button-${tq.tq_id}`,
          }}
        >
          {/* View Item */}
          <MenuItem
            component={Link}
            to={`/community/technicalqueries/details/${tq.tq_id}`}
          >
            <ListItemIcon>
              <RemoveRedEyeIcon color="action" />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>

          {/* Delete Item */}
          <MenuItem
            onClick={() => {
              setDeleteTQ(tq);
              setShowDeleteModal(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon color="action" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  // Everyone else can only view
  return (
    <Button
      variant="link"
      className="m-1 p-0 btn-link"
      href={`/community/technicalqueries/details/${tq.tq_id}`}
    >
      <RemoveRedEyeIcon color="action" /> View
    </Button>
  );
};
export default TqActionsCell;
