import axios from "axios";
import parse from "html-react-parser";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { handleAlertAndMailer } from "components/Products/Helper/functions";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

export default function ECRReleaseModal({
  show,
  handleClose,
  toRelease,
  refetch,
  project_id,
  vidNumber,
}) {
  const { authToken, csrfToken } = useAuth();

  const columns = [
    { title: "ID", field: "ecrid" },
    {
      title: "Author",
      render: (rowData) => (
        <div>
          {rowData.creator?.first_name} {rowData.creator?.last_name}
        </div>
      ),
    },
    {
      title: "VID",
      field: "pid.v_id",
      render: (rowData) => (
        <Link
          to={`/community/products/details/${rowData.pid?.product_id}`}
          className="text-decoration-underline"
        >
          {rowData?.pid?.v_id}
        </Link>
      ),
    },
    { title: "Type", field: "type" },
    { title: "Status", field: "status" },
    {
      title: "Comment",
      field: "cmnt",
      render: (rowData) => rowData?.cmnt && <p>{parse(rowData?.cmnt)}</p>,
    },
    { title: "Requirement", field: "req" },
    { title: "Added", field: "entrydate", type: "date" },
    { title: "Modified", field: "moddate", type: "date" },
  ];

  const handleSendAlertAndMail = () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: project_id,
      alert_type: "Product",
      alert_source: "ECR",
      subject: `ECR Comments have been released for Product VID${vidNumber}`,
      recipients: { to: ["Lab"], cc: ["Validators"] },
      alert_text: `ECR Comments have been released for Product VID${vidNumber}`,
    });
  };

  const releaseComments = () => {
    toRelease.forEach((ecr) => {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${ecr?.ecrid}/`,
          { status: "Released" },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          console.log("ECR Comment Released");
        })
        .catch((e) => console.log(e));
    });
    handleClose();
    refetch();
    handleSendAlertAndMail();
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="me-2">Release ECR Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ResponsiveMaterialTable data={toRelease} columns={columns} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="warning"
          onClick={() => releaseComments()}
        >
          Release Comments
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
