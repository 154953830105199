import axios from "axios";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Form,
  Alert,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { handleAlertAndMailer } from "components/Products/Helper/functions";
import ReactSelect from "components/ReactSelect";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

export default function ManageValidationTeamModal({
  show,
  setShow,
  currentProductInEval,
  refetch,
  alertView,
  setAlertTable,
}) {
  const [availableValidators, setAvailableValidators] = useState([]); //holds not selected validation team members
  const [selectedValidationMembers, setSelectedValidationMembers] = useState(
    [],
  ); //state holds existing validation team members
  const [productsInEval, setProductsInEval] = useState([]);
  const [alert, setAlert] = useState({});
  const [myOrg, setMyOrg] = useState({});
  const [newValidator, setNewValidator] = useState({}); //state for tracking changes to new validator before save
  const [validations, setValidations] = useState([]); //to hold active validations for each validation member
  const [fullAvailableValInfo, setFullAvailableValInfo] = useState([]); //used to store member id and name, using to find pre-roles
  const [contractVehicles, setContractVehicles] = useState([]);
  const tableRef = useRef();
  const productPreEvalStatus = currentProductInEval?.pre_eval_status;

  const { authToken, csrfToken, currentUser, permissions } = useAuth();

  const handleSendAlertAndMail = (product) => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: product?.product_id,
      alert_type: "Product",
      alert_source: "Validation Team Recommended",
      subject: `Validation Team Recommended for VID${product?.v_id}.`,
      recipients: { to: ["NIAP Management"], cc: ["Validator Resource Team"] },
      alert_text: `Validation Team Recommended for VID${product?.v_id}.`,
    });
  };

  const handleClose = () => {
    setShow(false);
    setAlert({});
    setNewValidator({});
  };

  //get currentUser's Org
  const getCurrentUser = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setMyOrg(response.data);
        tableRef.current.onQueryChange();
      })
      .catch((error) => {
        console.log("error getting user org", error);
      });
  };

  //get validation team from project/personnel for product
  const fetchSelectedPersonnel = useCallback(() => {
    if (currentProductInEval?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_validators?product=${currentProductInEval?.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setSelectedValidationMembers(response.data);
        })
        .catch((error) => {
          setSelectedValidationMembers([]);
        });
    }
  }, [currentProductInEval?.product_id, myOrg?.org_id]);

  useEffect(() => {
    fetchSelectedPersonnel();
  }, [currentProductInEval?.product_id, productsInEval]);

  const fetchAvailableValidators = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/available_validators/?product=${currentProductInEval?.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setFullAvailableValInfo(response.data);
        let availableMemberOptions = response.data.map((member) => ({
          value: member?.user_id?.id,
          label: member?.user_id?.first_name + " " + member?.user_id?.last_name,
        }));
        setAvailableValidators(availableMemberOptions);
      })
      .catch((error) => {
        console.log("error getting available members", error);
      });
  }, [show, currentProductInEval?.product_id]);

  //get all productsInEval from project/personnel
  const fetchEvalProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?submission_type=Evaluation`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProductsInEval(response.data.results);
      })
      .catch((error) => {
        console.log("error getting products", error);
      });
  }, []);

  const fetchValidations = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/filter_validations/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setValidations(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchContractVehicles = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}settings/vehicles/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setContractVehicles(response.data.results);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (show && currentProductInEval?.product_id) {
      fetchAvailableValidators();
    }
  }, [show, currentProductInEval?.product_id]);

  useEffect(() => {
    if (show) {
      fetchEvalProducts();
    }
  }, [show, fetchEvalProducts]);

  useEffect(() => {
    if (show) {
      fetchValidations();
    }
  }, [show, fetchValidations]);

  useEffect(() => {
    if (show) {
      fetchContractVehicles();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      getCurrentUser();
    }
  }, [show]);

  const refetchValInfo = () => {
    tableRef.current.onQueryChange();
    fetchAvailableValidators();
    fetchSelectedPersonnel();
  };

  const handleChange = (e) => {
    setNewValidator({ ...newValidator, [e.target.name]: e.target.value });
  };

  const handleRemoveMember = async (rowData) => {
    await axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${rowData.id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        refetchValInfo();
        setAlert({
          type: "Successfully Removed a Member!",
          variant: "success",
        });
      })
      .catch((error) =>
        setAlert({ type: "Error Removing Member.", variant: "danger" }),
      );
  };

  //checking if team has a senior and lead validator before submission to NIAP
  const checkTeamMemberRequirements = () => {
    const hasSeniorValidator = (member) =>
      member.role === "Sr. Validator" || member.role === "Senior Validator";
    const hasLeadValidator = (member) => member.role === "Lead Validator";

    const seniorExists = selectedValidationMembers?.some(hasSeniorValidator);
    const leadExists = selectedValidationMembers?.some(hasLeadValidator);

    return seniorExists && leadExists;
  };

  const updateProposedStatus = () => {
    if (checkTeamMemberRequirements() === false) {
      setAlert({
        type: "Senior Validator and Lead Validator Required For Validation Team Submission.",
        variant: "danger",
      });
    } else {
      setAlert({});
      let data = {
        pre_eval_status: "Submitted",
        submission_type: currentProductInEval?.submission_type || "Evaluation",
      };
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${currentProductInEval?.product_id}/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          handleSendAlertAndMail(response.data);
          setAlertTable({
            type: "Successfully sent for review!",
            variant: "success",
          });
          handleClose();
          refetch();
        })
        .catch((error) => {
          console.log("error updating product: ", error);
          setAlert({
            type: "Error submitting team for review",
            variant: "danger",
          });
        });
    }
  };

  const handleAddMember = async () => {
    if (newValidator["role"] && newValidator["validator"]) {
      let data = {
        pp_team_id: myOrg?.org_id ?? null,
        assigned_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
        contract_vehicle: newValidator["contract_vehicle"],
        role: newValidator["role"],
        user_id: newValidator["validator"],
        assigned_by: currentUser?.id,
        team_type: "Validation",
        product_id: currentProductInEval?.product_id,
      };
      //if NIAP, auto approve
      if (
        permissions?.role_type === "NIAP" ||
        (productPreEvalStatus !== "Ready for Assignment" &&
          productPreEvalStatus !== "Rejected")
      ) {
        data["approved"] = true;
      }
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setAlert({
            type: "Successfully Added a New Member!",
            variant: "success",
          });
          refetchValInfo();
          setNewValidator({});
        })
        .catch((error) =>
          setAlert({ type: "Error Adding Member.", variant: "danger" }),
        );
    } else {
      setAlert({
        type: "Please select both a name and a role to add member.",
        variant: "danger",
      });
    }
  };

  const handleUpdateMember = async (member) => {
    let currentProductInEvalID =
      currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
      currentProductInEval?.current_assurance_maintenance;
    let data = {
      pp_team_id: myOrg.org_id,
      assigned_on: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
      role: member.role,
      user_id: member.user_id?.id,
      contract_vehicle: member.contract_vehicle?.veid,
      assigned_by: currentUser?.id,
      team_type: "Validation",
      maintenance_id: currentProductInEvalID,
    };
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${member?.id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setAlert({
          type: "Successfully Updated a Member!",
          variant: "success",
        });
        setNewValidator({});
        refetchValInfo();
      })
      .catch((error) =>
        setAlert({ type: "Error Adding Member.", variant: "danger" }),
      );
  };

  const getValidationsByUser = (user_id) => {
    let validationLength = validations?.filter(
      (val) => val.user_id === user_id,
    ).length;
    return validationLength;
  };

  const findPreRoleOfMember = (user_id) => {
    let membersRole = fullAvailableValInfo.find(
      (member) => member?.user_id?.id === user_id,
    )?.existing_role;
    if (membersRole) {
      return membersRole;
    } else {
      return "No Pre-Role";
    }
  };

  const allowedRoles = [
    "Lead Validator",
    "Senior Validator",
    "ECR Team",
    "ECR Team(Trainee)",
    "Lead Validator(Trainee)",
    "Staff Liaison",
  ];

  let filteredAvailableValidators = availableValidators.filter(
    (validator) =>
      !selectedValidationMembers?.some(
        (member) => member?.user_id?.id === validator.value,
      ),
  );

  let currentValidators = selectedValidationMembers.map((validator) => {
    return {
      value: validator?.user_id?.id,
      label:
        validator?.user_id?.first_name + " " + validator?.user_id?.last_name,
    };
  });

  const columns = [
    {
      title: "Name",
      field: "user_id.id",
      render: (rowData) =>
        rowData.user_id?.first_name + " " + rowData.user_id?.last_name,
      editComponent: (props) => {
        let availableValidatorOptions = props.value
          ? currentValidators
          : filteredAvailableValidators;
        return (
          <ReactSelect
            options={availableValidatorOptions}
            newObj={
              newValidator?.validator
                ? newValidator
                : { validator: props.value }
            }
            handleChange={handleChange}
            name={"validator"}
            isValid={true}
            editComponent={true}
            readOnly={props.value ? true : false}
            id="val_team_select"
          />
        );
      },
    },
    {
      title: "Active Validations",
      field: "count_validations",
      editComponent: (props) => {
        if (newValidator["validator"]) {
          return (
            <p className="m-0">
              {getValidationsByUser(newValidator["validator"])}
            </p>
          );
        } else if (props.value) {
          return <p className="m-0">{props.value}</p>;
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Pre-Roles",
      field: "existing_role",
      editComponent: (props) => {
        if (newValidator["validator"]) {
          return (
            <p className="m-0">
              {findPreRoleOfMember(newValidator["validator"])}
            </p>
          );
        } else if (props.value) {
          return <p className="m-0">{props.value}</p>;
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Contract Vehicle",
      field: "contract_vehicle.veid",
      render: (rowData) =>
        rowData.contract_vehicle &&
        (rowData?.contract_vehicle?.desig
          ? rowData?.contract_vehicle?.desig
          : rowData?.contract_vehicle?.vehicle),
      editComponent: (props) => {
        const changeFunction = (e) =>
          props.rowData.id ? props.onChange(e.target.value) : handleChange(e);
        return (
          <>
            <Form.Select
              name="contract_vehicle"
              onChange={changeFunction}
              value={newValidator?.contract_vehicle ?? props.value}
              id="val_team_vehicle_assignment"
            >
              <option value={""}>Select</option>
              {contractVehicles?.map((vehicle, idx) => (
                <option value={vehicle.veid} key={idx}>
                  {vehicle.desig !== "" && vehicle.desig !== null
                    ? vehicle.desig
                    : vehicle.vehicle}
                </option>
              ))}
            </Form.Select>
          </>
        );
      },
    },
    {
      title: "Assigned",
      field: "role",
      editComponent: (props) => {
        const changeFunction = (e) =>
          props.rowData.id ? props.onChange(e.target.value) : handleChange(e);
        return (
          <>
            <Form.Select
              name="role"
              onChange={changeFunction}
              value={newValidator?.role ?? props.value}
              id="val_team_role_assignment"
            >
              <option value={""}>Select</option>
              {allowedRoles?.map((role, idx) => (
                <option value={role} key={idx}>
                  {role}
                </option>
              ))}
            </Form.Select>
          </>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    pageSize: 20,
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase me-2">
          Manage and Assign Validation Team{" "}
          {currentProductInEval ? "VID " + currentProductInEval.v_id : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.type && (
          <Alert
            variant={alert.variant}
            className="m-3"
            onClose={() => setAlert({})}
            dismissible
          >
            {alert.type}
          </Alert>
        )}
        <Container>
          {productPreEvalStatus === "Rejected" && (
            <Row>
              <Col sm={6}>
                <Row>
                  <h5 className="fw-bold">Rejection Rationale:</h5>
                </Row>
                <Row className="border border-dark p-3">
                  <p>
                    {currentProductInEval?.validation_team_rejection_rationale}
                  </p>
                </Row>
              </Col>
            </Row>
          )}
          {permissions?.role_type !== "NIAP" && !alertView && (
            <Row className="mb-2 mt-3">
              <Col sm={10}>
                <p className="fst-italic mb-0">
                  Senior Validator and Lead Validator Required Before Submission
                  of Validation Team.
                </p>
              </Col>
              <Col
                sm={2}
                style={{ width: "fit-content" }}
                className="d-flex justify-content-end"
              >
                {(productPreEvalStatus === "Rejected" ||
                  productPreEvalStatus === "Ready for Assignment") && (
                  <Button variant="success" onClick={updateProposedStatus}>
                    {productPreEvalStatus === "Rejected"
                      ? "Resubmit"
                      : "Submit"}{" "}
                    Team
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Container>
        <ResponsiveMaterialTable
          title="Validation Team"
          columns={columns}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });
              let newOrderBy = orderBy?.field;
              if (orderBy?.title === "Active Validations") {
                newOrderBy = "count_validations";
              } else if (orderBy?.title === "Pre-Roles") {
                newOrderBy = "existing_role";
              } else if (orderBy?.title === "Contract Vehicle") {
                newOrderBy = "contract_vehicle.vehicle";
              } else if (orderBy?.title === "Name") {
                newOrderBy = "user_id.first_name";
              }
              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: newOrderBy,
                orderDirection: orderDirection,
                // org: myOrg.org_id,
                product: currentProductInEval?.product_id,
              };
              axios
                .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/my_product_active_validators/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
          editable={
            alertView
              ? false
              : {
                  onRowAdd: () => handleAddMember(),
                  onRowDelete: (rowData) => handleRemoveMember(rowData),
                  onRowUpdate: (newData, oldData) =>
                    handleUpdateMember(newData),
                }
          }
        />
      </Modal.Body>
    </Modal>
  );
}
