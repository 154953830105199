import { baseApi } from "api/BaseApi";

import {
  AssociatedProductPayload,
  ProjectPp,
  ProtectionProfile,
  SetAssociationResponse,
} from "./protectionProfile.types";

const PROJECT_PPS_BASE_URL = "project/protection-profiles";
const PROTECTION_PROFILES_BASE_URL = "protection-profile";

const protectionProfilesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProjectPp: build.query<ProjectPp[], number | string>({
      query: (ppId) => ({
        url: `${PROJECT_PPS_BASE_URL}/get_all_project_pp/`,
        params: {
          pp: ppId,
        },
      }),
      providesTags: ["ASSOCIATED_PRODUCTS"],
    }),

    getProtectionProfile: build.query<ProtectionProfile, number | string>({
      query: (ppId) => `${PROTECTION_PROFILES_BASE_URL}/${ppId}`,
    }),

    associateProduct: build.mutation<
      SetAssociationResponse[],
      AssociatedProductPayload
    >({
      query: (body) => ({
        url: `${PROJECT_PPS_BASE_URL}/set_association/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ASSOCIATED_PRODUCTS"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useAssociateProductMutation,
  useGetAllProjectPpQuery,
  useGetProtectionProfileQuery,
} = protectionProfilesApi;
