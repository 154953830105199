import { MTableToolbar } from "@material-table/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import qs from "qs";
import { useCallback, useEffect, useState, useRef } from "react";
import {
  ButtonGroup,
  Button,
  OverlayTrigger,
  Popover,
  ListGroup,
  Form,
  Modal,
  Container,
  Row,
  Col,
  Breadcrumb,
  Alert,
} from "react-bootstrap";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import ECRCommentForm from "./ECRCommentForm";
import ECRReleaseModal from "./ECRReleaseModal";
import ExportEcrModal from "./ExportEcrModal";

export default function ECRCommentsTable({
  vidNumber,
  maintenance,
  fetchEcrCounts,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [docTypeList, setDocTypeList] = useState([]);

  const { authToken, csrfToken, currentUser, permissions } = useAuth();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [toRelease, setToRelease] = useState([]);
  const [releaseFilter, setReleaseFilter] = useState(false);
  const [ecr, setEcr] = useState({ state: "Proposed" });

  const [ecrToDelete, setEcrToDelete] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [isTrainee, setIsTrainee] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [toExport, setToExport] = useState([]);
  const [alert, setAlert] = useState([]);
  const fileInputRef = useRef(null);
  const [selectComments, setSelectComments] = useState(false);

  const resetFileInput = () => {
    // Check if the current property of the ref is not null
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    fetchDocumentTypes();
  }, [refresh, releaseFilter, vidNumber, maintenance, selectComments]);

  useEffect(() => {
    if (
      location.pathname === "/community/ecr-comments" ||
      location.pathname === "/community/ecr-comments/"
    ) {
      document.title = "NIAP Community - ECR Comments Table";
    }
  }, []);

  const fetchEcrURL = () => {
    if (!maintenance) {
      return "ecr/";
    } else {
      return "ecr/comments_by_maintenance_id";
    }
  };

  const fetchIsTrainee = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/is_trainee/?product=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setIsTrainee(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchIsTrainee();
    }
  }, [id]);

  const fetchDocumentTypes = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/get_all_ecr_doc_types/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setDocTypeList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const handleDeleteECR = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${ecrToDelete?.ecrid}/`,
        {
          active: false,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        tableRef.current.onQueryChange();
        setShowDeleteModal(false);
      })
      .catch((error) => console.log("Unable to update product: " + error));
  };

  const handleUploadExcel = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("excel_file", file);

      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/excel_import/?${maintenance ? "maintenance" : "pid"}=${id}`,
          formData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          if (response.data.success) {
            tableRef.current.onQueryChange();
            fetchEcrCounts();
            fetchDocumentTypes();
            if (response.data.additional_message.length > 0) {
              setAlert([
                {
                  id: new Date().toISOString(),
                  message: response.data.success,
                  type: "success",
                },
                ...response.data.additional_message.map((msg) => ({
                  id: new Date().toISOString(),
                  message: msg,
                  type: "warning",
                })),
              ]);
            } else {
              setAlert([
                {
                  id: new Date().toISOString(),
                  message: response.data.success,
                  type: "success",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          setAlert([
            {
              id: new Date().toISOString(),
              message: "Error importing ECRs",
              type: "danger",
            },
          ]);
        });
    }
    resetFileInput();
  };

  const handleUploadECRNotes = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("excel_file", file);

      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/excel_import_notes/?${maintenance ? "maintenance_id" : "product_id"}=${id}`,
          formData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          if (response.data.success) {
            setAlert([
              {
                id: new Date().toISOString(),
                message: "Successfully imported ECR Responses",
                type: "success",
              },
            ]);
          }
          setRefresh(!refresh);
        })
        .catch((error) => {
          if (error.response.data.error) {
            setAlert([
              {
                id: new Date().toISOString(),
                message: `Error importing ECR Responses: ${error.response.data.error}`,
                type: "danger",
              },
            ]);
          } else {
            setAlert([
              {
                id: new Date().toISOString(),
                message: "Error importing ECR Responses",
                type: "danger",
              },
            ]);
          }
          console.error("Error uploading the file:", error);
        });
    }
    resetFileInput();
  };

  const handleDownloadTemplate = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/export_ecr_template_excel/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `ecr_comments_template.xlsx`;

        a.click();

        window.URL.revokeObjectURL(url);
        setAlert([
          {
            id: new Date().toISOString(),
            message: "Successfully downloaded ECR Import Template!",
            type: "success",
          },
        ]);
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
        setAlert([
          {
            id: new Date().toISOString(),
            message: "Error downloading ECR Import Template.",
            type: "danger",
          },
        ]);
      });
  };

  const columns = [
    {
      title: "ID",
      field: "ecrid",
      headerStyle: { position: "sticky", left: 0, zIndex: 99 },
      cellStyle: { position: "sticky", left: 0 },
      render: (rowData) =>
        permissions?.role_permissions?.find(
          (permission) => permission.area === "ECR Comments",
        )?.read ? (
          <Link
            to={`/community/ecr-comments/details/${rowData?.ecrid}`}
            className="text-decoration-underline"
          >
            {rowData?.ecrid}
          </Link>
        ) : (
          <>{rowData?.ecrid}</>
        ),
    },
    {
      title: "VID",
      field: "pid.v_id",
      hidden: Boolean(maintenance),
      render: (rowData) => (
        <Link
          to={`/community/products/details/${rowData.pid?.product_id}`}
          className="text-decoration-underline"
        >
          {rowData.pid?.v_id}
        </Link>
      ),
    },
    {
      title: "AM",
      field: "maintenance_id.maintenance_id",
      hidden: Boolean(vidNumber),
      render: (rowData) => <>{rowData.maintenance_id?.maintenance_id}</>,
    },
    {
      title: "Author",
      field: "creator",
      render: (rowData) => (
        <div>
          {rowData.creator?.first_name} {rowData.creator?.last_name}
        </div>
      ),
    },
    { title: "Type", field: "type" },
    { title: "Status", field: "status" },
    {
      title: "Relates To",
      field: "process_location",
      hidden: true,
      hiddenByColumnsButton: true,
    },
    {
      title: "Document Type",
      render: (rowData) => (
        <ListGroup>
          {docTypeList
            ?.filter((dt) => dt.ecrid__ecrid === rowData.ecrid)
            ?.map((dt, idx) => (
              <ListGroup.Item key={idx} disabled>
                {dt.dtid__doctype}
              </ListGroup.Item>
            ))}
        </ListGroup>
      ),
      sorting: false,
    },
    {
      title: "Comment Title",
      field: "gist",
    },
    { title: "Requirement", field: "req" },
    { title: "Added", field: "entrydate", type: "date" },
    { title: "Modified", field: "moddate", type: "date" },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      headerStyle: {
        position: "sticky",
        right: "0",
        textAlign: "center",
      },
      cellStyle: {
        padding: "0",
        position: "sticky",
        right: "0",
        backgroundColor: "var(--bs-white)",
      },
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <Link
                  to={`../../ecr-comments/details/${rowData.ecrid}`}
                  relative="route"
                  className="text-decoration-none"
                >
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <VisibilityIcon color="action" /> View
                  </Button>
                </Link>
                {(currentUser?.id === rowData?.creator?.id ||
                  permissions?.role_type === "Validator" ||
                  permissions?.role_type === "NIAP") && (
                  <>
                    {permissions?.role_permissions?.find(
                      (permission) => permission.area === "ECR Comments",
                    )?.update &&
                      (permissions?.role_type === "Validator" ||
                        permissions?.role_type === "NIAP") &&
                      isTrainee?.is_trainee === false && (
                        <Button
                          variant="link"
                          className="m-1 p-0 btn-link"
                          onClick={() => {
                            setEcr(rowData);
                            setIsEditing(true);
                            setShowCommentForm(true);
                          }}
                        >
                          <EditIcon color="action" /> Edit
                        </Button>
                      )}
                    {permissions?.role_permissions?.find(
                      (permission) => permission.area === "ECR Comments",
                    )?.destroy &&
                      (permissions?.role_type === "Validator" ||
                        permissions?.role_type === "NIAP") &&
                      isTrainee?.is_trainee === false && (
                        <Button
                          variant="link"
                          className="m-1 p-0 btn-link"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setEcrToDelete(rowData);
                          }}
                        >
                          <DeleteIcon color="action" /> Delete
                        </Button>
                      )}
                  </>
                )}
              </Popover.Body>
            </Popover>
          }
        >
          <Button
            variant="link"
            className="d-flex justify-content-center align-items-center"
          >
            <MoreVertIcon />
            <span className="visually-hidden">Actions</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    showSelectAllCheckbox: selectComments,
    selection:
      permissions?.role_type === "Validator" ||
      permissions?.role_type === "NIAP" ||
      permissions?.role_type === "Lab",
    selectionProps: (rowData) => ({
      disabled:
        rowData.status === "Concealed" || rowData.status === "Cancelled",
      hidden:
        !selectComments ||
        rowData.status === "Concealed" ||
        rowData.status === "Cancelled",
    }),
    pageSize: selectComments ? tableRef.current.state.query.totalCount : 20,
  };

  const actions = () => {
    if (
      permissions?.role_type === "Validator" &&
      permissions?.is_senior &&
      releaseFilter === false
    ) {
      return [
        {
          tooltip: "Export Selected Comments",
          icon: () => (
            <span className="rounded-pill small-font text-white bg-primary px-2 py-1">
              Export Selected Comments
            </span>
          ),
          onClick: (e, data) => {
            setToExport(data);
            setShowExportModal(true);
          },
        },
        {
          tooltip: "Release All Selected ECR Comments",
          icon: () => (
            <span className="rounded-pill small-font bg-warning px-2 py-1">
              Release ECRs
            </span>
          ),
          onClick: (e, data) => {
            const releaseData = data.filter(
              (row) => row.status !== "Concealed" && row.status !== "Cancelled",
            );
            setToRelease(releaseData);
            setShowReleaseModal(true);
          },
        },
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () =>
            tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
              tableRef.current.onFilterChange(item.tableData.id, "");
            }),
        },
        {
          icon: "close",
          tooltip: "Clear Filters",
          isFreeAction: true,
          onClick: () => {
            let self = tableRef.current;
            let query = self.state.query;
            self.setState({
              ...self.dataManager.getRenderState(),
              query: {
                ...query,
                filters: [],
              },
            });
            for (let item in tableRef.current.state.query.filters) {
              let tableColumn =
                tableRef.current.state.query.filters[item].column;
              tableRef.current.dataManager.changeFilterValue(
                tableColumn.tableData.id,
                null,
              );
              tableRef.current.onFilterChange(tableColumn.tableData.id, "");
            }
          },
        },
      ];
    } else if (
      permissions?.role_type === "Validator" &&
      permissions?.is_senior
    ) {
      return [
        {
          tooltip: "Export Selected Comments",
          icon: () => (
            <span className="rounded-pill small-font text-white bg-primary px-2 py-1">
              Export Selected Comments
            </span>
          ),
          onClick: (e, data) => {
            setToExport(data);
            setShowExportModal(true);
          },
        },
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () =>
            tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
              tableRef.current.onFilterChange(item.tableData.id, "");
            }),
        },
        {
          icon: "close",
          tooltip: "Clear Filters",
          isFreeAction: true,
          onClick: () => {
            let self = tableRef.current;
            let query = self.state.query;
            self.setState({
              ...self.dataManager.getRenderState(),
              query: {
                ...query,
                filters: [],
              },
            });
            for (let item in tableRef.current.state.query.filters) {
              let tableColumn =
                tableRef.current.state.query.filters[item].column;
              tableRef.current.dataManager.changeFilterValue(
                tableColumn.tableData.id,
                null,
              );
              tableRef.current.onFilterChange(tableColumn.tableData.id, "");
            }
          },
        },
      ];
    } else if (permissions?.role_type === "Validator") {
      return [
        {
          tooltip: "Export Selected Comments",
          icon: () => (
            <span className="rounded-pill small-font text-white bg-primary px-2 py-1">
              Export Selected Comments
            </span>
          ),
          onClick: (e, data) => {
            setToExport(data);
            setShowExportModal(true);
          },
        },
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () =>
            tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
              tableRef.current.onFilterChange(item.tableData.id, "");
            }),
        },
        {
          icon: "close",
          tooltip: "Clear Filters",
          isFreeAction: true,
          onClick: () => {
            let self = tableRef.current;
            let query = self.state.query;
            self.setState({
              ...self.dataManager.getRenderState(),
              query: {
                ...query,
                filters: [],
              },
            });
            for (let item in tableRef.current.state.query.filters) {
              let tableColumn =
                tableRef.current.state.query.filters[item].column;
              tableRef.current.dataManager.changeFilterValue(
                tableColumn.tableData.id,
                null,
              );
              tableRef.current.onFilterChange(tableColumn.tableData.id, "");
            }
          },
        },
      ];
    } else if (permissions?.role_type === "Lab") {
      return [
        {
          tooltip: "Export Selected Comments",
          icon: () => (
            <span className="rounded-pill small-font bg-warning px-2 py-1">
              Export Selected Comments
            </span>
          ),
          onClick: (e, data) => {
            setToExport(data);
            setShowExportModal(true);
          },
        },
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () =>
            tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
              tableRef.current.onFilterChange(item.tableData.id, "");
            }),
        },
        {
          icon: "close",
          tooltip: "Clear Filters",
          isFreeAction: true,
          onClick: () => {
            let self = tableRef.current;
            let query = self.state.query;
            self.setState({
              ...self.dataManager.getRenderState(),
              query: {
                ...query,
                filters: [],
              },
            });
            for (let item in tableRef.current.state.query.filters) {
              let tableColumn =
                tableRef.current.state.query.filters[item].column;
              tableRef.current.dataManager.changeFilterValue(
                tableColumn.tableData.id,
                null,
              );
              tableRef.current.onFilterChange(tableColumn.tableData.id, "");
            }
          },
        },
      ];
    } else {
      return [
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () =>
            tableRef.current &&
            tableRef.current.dataManager.columns.forEach((item) => {
              tableRef.current.onFilterChange(item.tableData.id, "");
            }),
        },
        {
          icon: "close",
          tooltip: "Clear Filters",
          isFreeAction: true,
          onClick: () => {
            let self = tableRef.current;
            let query = self.state.query;
            self.setState({
              ...self.dataManager.getRenderState(),
              query: {
                ...query,
                filters: [],
              },
            });
            for (let item in tableRef.current.state.query.filters) {
              let tableColumn =
                tableRef.current.state.query.filters[item].column;
              tableRef.current.dataManager.changeFilterValue(
                tableColumn.tableData.id,
                null,
              );
              tableRef.current.onFilterChange(tableColumn.tableData.id, "");
            }
          },
        },
      ];
    }
  };

  const getTitle = () => {
    if (vidNumber) {
      return `ECR Comments for VID ${vidNumber}`;
    } else if (maintenance) {
      return `ECR Comments for AM ${maintenance?.title}`;
    } else {
      return "ECR Comments";
    }
  };

  return permissions?.role_permissions?.find(
    (permission) => permission.area === "ECR Comments",
  )?.read ? (
    <Container fluid className="my-3">
      {alert.length > 0 &&
        alert.map((alertItem) => (
          <Alert
            log={alertItem}
            key={`alert-${alertItem.id}-${alertItem.type}`}
            variant={alertItem.type}
            className="m-3"
            dismissible
          >
            {alertItem.message}
          </Alert>
        ))}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/community/`)}>
          Community
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ECR Comments</Breadcrumb.Item>
      </Breadcrumb>
      {showDeleteModal && (
        <Modal
          size="md"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Delete ECR Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center">
              Are You Sure You Want to Delete ECR Comment: {ecrToDelete?.ecrid}?
            </h4>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="primary"
                className="m-1"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="m-1"
                onClick={handleDeleteECR}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="py-4 text-end">
        <ButtonGroup>
          {permissions?.role_type === "Validator" &&
            (permissions?.is_lead || permissions?.is_senior) && (
              <Form.Switch
                className="mx-4"
                onChange={(e) => {
                  setReleaseFilter(e.target.checked);
                }}
                label="Toggle Released"
              />
            )}
          {id &&
            permissions?.role_type === "Lab" && ( //only show on details page for labs
              <>
                <Button
                  variant="warning"
                  className="rounded-0 mx-2"
                  onClick={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  Import ECR Comment Responses
                </Button>
                <input
                  type="file"
                  accept=".xlsx"
                  style={{ display: "none" }}
                  data-testid="file_import_ecr_notes"
                  ref={fileInputRef}
                  onChange={handleUploadECRNotes}
                />
              </>
            )}
          {permissions?.role_type !== "Lab" && (
            <Row className="justify-content-end">
              <Col xs={12} md="auto" className="mb-2 mb-md-0">
                <Button
                  className="rounded-0 w-100"
                  onClick={() => {
                    setIsEditing(false);
                    setShowCommentForm(true);
                  }}
                >
                  + Add ECR Comment
                </Button>
              </Col>
              <Col xs={12} md="auto" className="mb-2 mb-md-0">
                <Button
                  variant="success"
                  className="rounded-0 w-100"
                  onClick={handleDownloadTemplate}
                >
                  Download ECR Template
                </Button>
              </Col>
              {id && (
                <Col xs={12} md="auto">
                  <Button
                    variant="warning"
                    className="rounded-0 w-100"
                    onClick={() => {
                      if (fileInputRef && fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    Import ECR Comments
                  </Button>
                  <input
                    type="file"
                    accept=".xlsx"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleUploadExcel}
                  />
                </Col>
              )}
            </Row>
          )}
        </ButtonGroup>
      </div>
      <ECRReleaseModal
        show={showReleaseModal}
        handleClose={() => setShowReleaseModal(false)}
        toRelease={toRelease}
        refetch={() => {
          setRefresh(!refresh);
        }}
        project_id={id}
        vidNumber={vidNumber}
      />
      <ExportEcrModal
        show={showExportModal}
        handleClose={() => setShowExportModal(false)}
        toExport={toExport}
        docTypeList={docTypeList}
        maintenance={maintenance}
      />
      <ECRCommentForm
        show={showCommentForm}
        handleClose={() => {
          setShowCommentForm(false);
          setEcr({ state: "Proposed" });
        }}
        vidNumber={vidNumber ? vidNumber : null}
        maintenance={maintenance}
        refetch={() => {
          setRefresh(!refresh);
        }}
        project_id={id}
        ecr={ecr}
        setEcr={setEcr}
        isEditing={isEditing}
        fetchEcrCounts={fetchEcrCounts}
      />
      <ResponsiveMaterialTable
        title={getTitle()}
        columns={columns}
        tableRef={tableRef}
        actions={actions()}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              {(permissions?.role_type === "Validator" ||
                permissions?.role_type === "Lab") &&
                (vidNumber || maintenance) && (
                  <div style={{ padding: "0px 10px" }}>
                    {/* <Form.Check
                  type="checkbox"
                  id="selectCommentsCheckbox"
                  label="Select Comments"
                  onChange={() => setSelectComments(!selectComments)}
                  checked={selectComments}
                /> */}
                    <Button
                      variant={selectComments ? "primary" : "outline-primary"}
                      className="m-3"
                      onClick={() => {
                        setSelectComments(!selectComments);
                      }}
                    >
                      Select Comments
                    </Button>
                  </div>
                )}
            </div>
          ),
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let responsivePageSize = pageSize;
            if (selectComments) {
              responsivePageSize = tableRef.current.state.query.totalCount;
            }

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else if (
                ["entrydate", "moddate"]?.includes(filter?.column?.field)
              ) {
                const dateObj = new Date(filter?.value);
                const isoStr = dateObj.toISOString();
                const formattedStr = isoStr.split("T")[0];
                value = formattedStr;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * responsivePageSize,
              limit: responsivePageSize,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            if (search) {
              params["search"] = search;
            }

            if (vidNumber) {
              params["vid"] = vidNumber;
            }

            if (maintenance) {
              params["maintenance"] = maintenance?.maintenance_id;
            }

            if (
              permissions?.role_type === "Validator" &&
              (permissions?.is_senior === true ||
                permissions?.is_lead === true) &&
              releaseFilter
            ) {
              params["status"] = "Released";
            } else if (
              permissions?.role_type === "Validator" &&
              (permissions?.is_senior === true ||
                permissions?.is_lead === true) &&
              !releaseFilter
            ) {
              params["get_unreleased"] = "true";
            }

            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchEcrURL()}?${qs.stringify(
                  params,
                  {
                    arrayFormat: "comma",
                  },
                )}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
        onSelectionChange={(rows) => {
          rows.forEach((row) => {
            if (row.status !== "Concealed" && row.status !== "Cancelled") {
              row.tableData.checked = true;
            } else {
              row.tableData.checked = false;
            }
          });
        }}
      />
    </Container>
  ) : (
    <UnauthorizedView />
  );
}
