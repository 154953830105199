import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import {
  Stack,
  Row,
  Accordion,
  Container,
  Button,
  Form,
} from "react-bootstrap";
import { useCookies } from "react-cookie";

import SendToCustomerModal from "./SendToCustomerModal";

export default function QADetailsSideInfo({ qa, refetch, setAlert }) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [isEditing, setIsEditing] = useState(false);
  const [custInfo, setCustInfo] = useState({});
  const [showSendToCustomerModal, setShowSendToCustomerModal] = useState(false);

  const handleChange = (e) => {
    setCustInfo({ ...custInfo, [e.target.name]: e.target.value });
  };

  const handleSave = (e, sendEmailToCustomer) => {
    e.preventDefault();
    //if the user is passing custInfo with an email, we show the SendToCustomerModal to ask
    //if they want to send an email to the customer
    //we are checking to make sure sendEmailToCustomer is undefined (aka the modal hasn't been opened and the param isn't passed)
    if (
      custInfo.custemail &&
      custInfo.custemail !== "" &&
      sendEmailToCustomer === undefined
    ) {
      setShowSendToCustomerModal(true);
    } else {
      let data = {
        ...custInfo,
        recaptcha_token: "member_portal",
      };

      //iterating over the data to check if any of the values are empty strings and if so, we set them to null
      for (const key of Object.keys(data)) {
        if (data[key] === "") {
          data[key] = null;
        }
      }
      //if the user wants to send an email to the customer, we add send_email to the data
      //for the backend to handle
      if (sendEmailToCustomer) {
        data["send_email"] = true;
      }

      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${qa?.rid}/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setCustInfo({});
          setIsEditing(false);
          refetch();
          setShowSendToCustomerModal(false);
          if (sendEmailToCustomer) {
            setAlert({
              message: "Saved Customer Information and Sent Email to Customer!",
              type: "success",
            });
          } else {
            setAlert({
              message: "Saved Customer Information!",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log("Unable to save customer information: " + error);
          setAlert({
            message: "Unable to update customer information.",
            type: "danger",
          });
        });
    }
  };

  return (
    <>
      <SendToCustomerModal
        show={showSendToCustomerModal}
        handleClose={() => setShowSendToCustomerModal(false)}
        custInfo={custInfo}
        handleSave={handleSave}
      />
      <Accordion flush className="border border-dark mb-3 hr">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Customer Information</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="border-bottom">
              <Row>
                {isEditing ? (
                  <Button
                    variant="link"
                    className="btn-link d-flex m-0 justify-content-end"
                    onClick={handleSave}
                    data-testid="save-button"
                  >
                    <SaveIcon color="action" />
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    className="btn-link d-flex m-0 justify-content-end"
                    onClick={() => setIsEditing(true)}
                    data-testid="edit-button"
                  >
                    <EditIcon color="action" />
                  </Button>
                )}
              </Row>
              {isEditing ? (
                <Row>
                  <Form>
                    <Form.Group className="mb-3" controlId="custuname">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        defaultValue={qa?.custuname}
                        name="custuname"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="custorg">
                      <Form.Label>Organization</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Organization"
                        defaultValue={qa?.custorg}
                        name="custorg"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="custemail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        defaultValue={qa?.custemail}
                        name="custemail"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone1">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone"
                        defaultValue={qa?.phone1}
                        name="phone1"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form>
                </Row>
              ) : (
                <Row>
                  <Stack className="mt-2">
                    <p>
                      <strong>Name:</strong> {qa?.custuname}
                    </p>
                  </Stack>
                  <Stack>
                    <p>
                      <strong>Organization:</strong> {qa?.custorg}
                    </p>
                  </Stack>
                  <Stack>
                    <p>
                      <strong>Email:</strong> {qa?.custemail}
                    </p>
                  </Stack>
                  <Stack>
                    <p>
                      <strong>Phone:</strong> {qa?.phone1}
                    </p>
                  </Stack>
                </Row>
              )}
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Dates</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="border-bottom">
              <Row>
                <Stack className="mt-2">
                  <p>
                    <strong>Date of Receipt: </strong>{" "}
                    {qa.entrydate
                      ? moment.utc(qa.entrydate).format("MM/DD/YYYY")
                      : "No Date Set"}
                  </p>
                </Stack>
                <Stack>
                  <p>
                    <strong>Requested Date: </strong>{" "}
                    {qa.requestedby
                      ? moment.utc(qa.requestedby).format("MM/DD/YYYY")
                      : "No Date Set"}
                  </p>
                </Stack>
                <Stack>
                  <p>
                    <strong>Deadline Date: </strong>{" "}
                    {qa.deadline
                      ? moment.utc(qa.deadline).format("MM/DD/YYYY")
                      : "No Date Set"}
                  </p>
                </Stack>
                {qa?.status === "Completed" && (
                  <Stack>
                    <p>
                      <strong>Completion Date: </strong>
                      {moment.utc(qa.enddate).format("YYYY.MM.DD [at] HHmm")}
                    </p>
                  </Stack>
                )}
                {qa?.status === "Cancelled" && (
                  <Stack>
                    <p>
                      <strong>Cancellation Date: </strong>
                      {moment.utc(qa.enddate).format("YYYY.MM.DD [at] HHmm")}
                    </p>
                  </Stack>
                )}
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Attribution Information</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="border-bottom">
              <Row>
                <Stack className="mt-2">
                  <div>
                    <p>
                      Record Created:{" "}
                      {moment.utc(qa.entrydate).format("YYYY.MM.DD [at] HHmm")}{" "}
                      by {qa.custuname}
                    </p>
                  </div>
                </Stack>
                <Stack className="border-top border-dark mt-2">
                  <p>
                    Last Modified:{" "}
                    {qa.moddate
                      ? moment.utc(qa.moddate).format("YYYY.MM.DD [at] HHmm")
                      : "Not Modified"}
                  </p>
                </Stack>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
