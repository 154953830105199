import {
  Container,
  Row,
  Col,
  Alert,
  OverlayTrigger,
  Button,
  Popover,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import { useState, useEffect, useCallback, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useCookies } from "react-cookie";

import "./AnnouncementsManager.css";
import qs from "qs";

import { useInterval } from "../../../hooks/useInterval";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import AnnouncementsModal from "./AnnouncementsModal";

export default function EventsTable() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [filtering, setFiltering] = useState(false);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [announcement, setAnnouncement] = useState({});
  // const [files, setFiles] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    //   fetchFiles()
  }, [refresh]);

  // const fetchFiles = useCallback(() => {
  //     axios
  //         .get(
  //             `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_grouped_files/`,
  //             {
  //                 withCredentials: true,
  //                 headers: { Authorization: `Token ${authToken}` },
  //             }
  //         )
  //         .then((response) => {
  //             setFiles(response.data);
  //         })
  //         .catch((error) => setFiles([]));
  // }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  // useEffect(() => {
  //     fetchFiles()
  // }, [])

  // useInterval(()=>{
  //     fetchFiles()
  // }, 300000) //5 min

  const handleShowNewsModal = (rowData) => {
    if (rowData) {
      setAnnouncement(rowData);
    } else {
      setAnnouncement({});
    }
    setShowNewsModal(!showNewsModal);
  };

  // const downloadFile = (file) => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
  //         {
  //           withCredentials: true,
  //           headers: {
  //             Authorization: `Token ${authToken}`,
  //           },
  //           responseType: "blob",
  //         }
  //       )
  //       .then((response) => {
  //         FileDownload(response.data, (file.file_new_name ? file.file_new_name : file.file_name));
  //       })
  //       .catch((error) => {
  //         console.log("Error");
  //       });
  //   };

  const columns = [
    {
      title: "Posting Date",
      field: "posted",
      render: (rowData) => {
        return rowData?.posted !== null
          ? moment.utc(rowData?.posted).format("MM/DD/YYYY")
          : "";
      },
      type: "date",
      defaultSort: "desc",
    },
    {
      title: "Expiration Date",
      field: "expires",
      render: (rowData) => {
        return rowData?.expires !== null
          ? moment.utc(rowData?.expires).format("MM/DD/YYYY")
          : "";
      },
      type: "date",
    },
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Type",
      field: "types",
      render: (rowData) => {
        let fieldName = ["Announcements NIAP", "Evolution", "Partner"];
        let fieldData = rowData?.types?.split(",");
        let mappedData = fieldData?.map((item) => fieldName[item - 1]);
        return mappedData?.join(", ");
      },
    },
    // {
    //     title: "Uploaded Documents",
    //     render: (rowData) => {
    //         return <ListGroup className="custom-list-group">
    //         { files && files[rowData?.id]?.map((file, idx) => (
    //           <OverlayTrigger
    //           key={idx}
    //           placement="top"
    //           overlay={<Tooltip>{file?.file_label}</Tooltip>}
    //         >
    //             <ListGroup.Item onClick={() => downloadFile(file)} className='text-decoration-underline'>
    //               {file?.file_label}
    //             </ListGroup.Item>
    //         </OverlayTrigger>
    //         ))}
    //       </ListGroup>
    //     },
    // },
    { title: "URL", field: "link" },
    {
      title: "Recorded By",
      field: "creator",
      render: (rowData) =>
        rowData?.creator?.first_name + " " + rowData?.creator?.last_name,
      filtering: false,
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowNewsModal(rowData)}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
                <span className="visually-hidden">Action</span>
              </Button>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const options = {
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: filtering,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <AnnouncementsModal
        show={showNewsModal}
        handleShow={handleShowNewsModal}
        refetch={() => {
          setRefresh(!refresh);
        }}
        setAlert={setAlert}
        announcement={
          Object.keys(announcement)?.length > 0 ? announcement : null
        }
      />
      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={7}>
              <h3 className="text-bright-navy">
                NEWS / UPDATES / ANNOUNCEMENTS MANAGER
              </h3>
            </Col>
            <Col sm={12} md={3} className="d-flex justify-content-end mb-xs-3">
              <Button
                variant="primary"
                className="rounded-0 w-100"
                onClick={() => setShowNewsModal(true)}
              >
                + Add News / Updates / Announcements
              </Button>
            </Col>
            <Col sm={12} md={2} className="d-flex justify-content-end mb-xs-3">
              <Button
                className="account-btn w-100"
                variant={filtering ? "primary" : "light"}
                onClick={(e) => setFiltering(!filtering)}
              >
                Filter
              </Button>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              title={"NEWS / UPDATES / ANNOUNCEMENTS"}
              columns={columns}
              tableRef={tableRef}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else if (
                      ["posted", "expires"]?.includes(filter?.column?.field)
                    ) {
                      const dateObj = new Date(filter?.value);
                      const isoStr = dateObj.toISOString();
                      const formattedStr = isoStr.split("T")[0];
                      value = formattedStr;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_news/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
