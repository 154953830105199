import axios from "axios";
import moment from "moment";
import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";

const CctlCheckOutModal = ({
  show,
  handleClose,
  product,
  validation_report,
  certificate,
  assurance_activity_report,
  setOverlayActive,
  setOverlayText,
  setAlert,
}) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [postingData, setPostingData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/checkout-data/for_product/?pid=${product.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setPostingData({ ...postingData, ...response.data });
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setPostingData({
      ...postingData,
      signer: "CCTL",
      product_id: product?.product_id,
      post_certificate: "false",
      post_to_iccc: "false",
      post_to_ccuf: "false",
    });
  }, [product]);

  const handleChange = (e) => {
    if (e.target.name === "post_certificate" && e.target.value === "false") {
      setPostingData({
        ...postingData,
        [e.target.name]: e.target.value,
        post_to_iccc: "false",
        post_to_ccuf: "false",
      });
    } else {
      setPostingData({ ...postingData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity()) {
      signProduct();
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
  };

  const signProduct = () => {
    setValidated(false);
    setOverlayText("Redirecting");
    setOverlayActive(true);
    handleClose();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/sign_checkout/`,
        { ...postingData, product_id: product.product_id, signer: "CCTL" },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setOverlayActive(false);
        window.location.href = response.data.redirect_url;
      })
      .catch((error) => {
        setOverlayActive(false);
        setAlert({
          variant: "danger",
          message:
            "An error occurred during signing process. Please try again.",
        });
        console.log(error);
      });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Release of Information</Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="submit-contact-us-form"
      >
        <Modal.Body>
          <h1 className="text-capitalize text-primary">Check Out Package</h1>
          <div className="text-center">
            <h3>CCTL Approval for Release of Information</h3>
          </div>
          <p className="fst-italic">
            I hereby grant to the National Information Assurance Partnership
            Common Criteria Evaluation and Validation Scheme, hereinafter
            referred to as the NIAP CCEVS, permission to disseminate, through
            use of the NIAP CCEVS web site, the Product Compliant List Entry
            (PCL Entry), Security Target (ST), Validation Report (VR), Assurance
            Activity Report (AAR) and CC Certificate pertaining to the product
            identified below.
          </p>
          <h5 className="text-capitalize">PRODUCT INFORMATION</h5>
          <p>Product Name: {product?.product_name}</p>

          <h5 className="text-capitalize">
            PERTINENT DOCUMENT IDENTIFICATION/ VALIDATION RESULTS
          </h5>

          <p>
            <Form.Group controlId="st_data_1">
              <Form.Label inline={true}>ST Name*: </Form.Label>{" "}
              <Form.Control
                className="d-inline-block w-50"
                type="text"
                name="st_name"
                defaultValue={postingData.st_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="st_data_2">
              <Form.Label inline={true}>ST Date*: </Form.Label>{" "}
              <Form.Control
                className="d-inline-block w-50"
                type="date"
                name="st_date"
                defaultValue={postingData.st_date}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="vr_data_1">
              <Form.Label inline={true}>VR - Report Number: </Form.Label>{" "}
              {validation_report.file_label ? (
                <Form.Text className="d-inline-block w-50">{`CCEVS-VR-VID${product?.v_id}-${new Date(validation_report?.uploaded_on).getFullYear()}`}</Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-50"
                  type="text"
                  name="vr_number"
                  defaultValue={postingData.vr_number}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="vr_data_2">
              <Form.Label inline={true}>VR - Final Draft Version: </Form.Label>{" "}
              {validation_report?.file_name ? (
                <Form.Text className="d-inline-block w-50">
                  {validation_report?.file_name}
                </Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-50"
                  type="date"
                  name="vr_name"
                  defaultValue={postingData.vr_name}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="aar_data_1">
              <Form.Label inline={true}>AAR Name*: </Form.Label>{" "}
              <Form.Control
                className="d-inline-block w-50"
                required
                type="text"
                name="aar_name"
                defaultValue={postingData.aar_name}
                onChange={handleChange}
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="aar_data_2">
              <Form.Label inline={true}>AAR Date*: </Form.Label>{" "}
              <Form.Control
                required
                className="d-inline-block w-50"
                type="date"
                name="aar_date"
                defaultValue={postingData.aar_date}
                onChange={handleChange}
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="cert_data">
              <Form.Label inline={true}>
                CC Certificate (File Name):{" "}
              </Form.Label>{" "}
              {certificate?.file_name ? (
                <Form.Text inline={true}>{certificate?.file_name}</Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-50"
                  type="text"
                  name="cert_name"
                  defaultValue={postingData.cert_name}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            NIAP must adhere to the conditions outlined in the Common Criteria
            Recognition Arrangement (CCRA) which requires valid Common Criteria
            Certificates and their associated Certification/validation Reports
            to published and made available to the public. Approval to release
            by both the CCTL and the Vendor of the evaluation is necessary prior
            to NIAP/CCEVS web posting.
          </p>

          <p>
            The NIAP CCEVS maintains a Product Compliant List for evaluated
            products and a NIAP-approved PP list for PPs. The information and
            documentation will be made publicly available and can be found on
            the NIAP CCEVS web site.
          </p>

          <p className="fw-bold fst-italic">
            The Digital Signature on this form confirms that the PCL Entry, ST,
            VR, AAR and Certificate have been reviewed and determined that the
            documents are free of proprietary information, contain no technical
            inaccuracies, and are authorized for public release through
            employment of the NIAP CCEVS web site.
          </p>

          <h5 className="text-capitalize">VENDOR INFORMATION</h5>
          <div className="row">
            <div className="col-sm-6">
              <p>Vendor: {product?.vendor_id?.name}</p>
              <p>Vendor POC: {product?.vendor_id?.poc}</p>
              <p>Title of Vendor POC: {product?.vendor_id?.poc_title}</p>
              <p>Email: {product?.vendor_id?.poc_email}</p>
            </div>
            <div className="col-sm-6">
              <p>
                Full Mailing Address: {product?.vendor_id?.primary_address_1}{" "}
                {product?.vendor_id?.primary_address_2}
                <br />
                {product?.vendor_id?.primary_city},{" "}
                {product?.vendor_id?.primary_state}{" "}
                {product?.vendor_id?.primary_zip}
                <br />
                {product?.vendor_id?.primary_country}
              </p>
              <p>Phone: {product?.vendor_id?.primary_phone}</p>
            </div>
          </div>

          <h5 className="text-capitalize">CCTL APPROVAL</h5>
          <div className="row">
            <div className="col-sm-6">
              <p>CCTL: {product?.assigned_lab?.name}</p>
              <p>CCTL POC: {product?.assigned_lab?.poc}</p>
              <p>Title of POC: {product?.sponsor_title}</p>
            </div>
            <div className="col-sm-6">
              <p>Phone: {product?.assigned_lab?.primary_phone}</p>
              <p>Email: {product?.assigned_lab?.poc_email}</p>
            </div>
          </div>

          <h5 className="text-capitalize">CERTIFICATE AWARD</h5>

          <p>
            Would you like the certificate presented publicly? If yes, please
            select a venue below. If no, the signed certificate will be emailed
            to the POC Listed above.
          </p>

          <div className="row my-2">
            <div className="col-5 d-flex">
              <Form.Check
                id="post-yes"
                label="Yes"
                value={true}
                name="post_certificate"
                type="radio"
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-5 d-flex">
              <Form.Check
                id="post-no"
                label="No"
                value={false}
                name="post_certificate"
                type="radio"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <p>
            <span className="fw-bold">Note:</span>
            If yes, NIAP cannot agree meeting/conference coordinators will agree
            to host a CC Ceremony. For registration requirements including
            presentation ceremony details and submission deadlines, please visit
            <a href="https://iccconference.org" target="__blank">
              {" "}
              https://iccconference.org
            </a>
          </p>

          <div className="d-flex my-2">
            <Form.Check
              id="post-iccc"
              label="International Common Criteria Conference (ICCC) - September timeframe, rotates Internationally"
              value={true}
              name="post_to_iccc"
              type="checkbox"
              onChange={handleChange}
              disabled={
                !postingData.post_certificate ||
                postingData.post_certificate === "false"
              }
              checked={postingData.post_to_iccc === "true"}
            />
          </div>

          <div className="d-flex my-2">
            <Form.Check
              id="post-ccuf"
              label="Common Criteria User's Forum Meeting - April a& September timeframe, rotates Internationally."
              value={true}
              name="post_to_ccuf"
              type="checkbox"
              onChange={handleChange}
              disabled={
                !postingData.post_certificate ||
                postingData.post_certificate === "false"
              }
              checked={postingData.post_to_ccuf === "true"}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button type="submit">Click to Sign Digitally</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CctlCheckOutModal;
