import { Paper } from "@mui/material";
import { DataGrid, type DataGridProps } from "@mui/x-data-grid";
// This is a shallow wrapper of the Mui DataGrid
// to allow consistent default styling throughout the app

const DefaultDataGrid = ({ rows, columns, sx, ...props }: DataGridProps) => {
  return (
    <Paper
      elevation={1}
      sx={{
        overflowX: "auto",
        margin: "0 auto",
        padding: 0,
      }}
    >
      <DataGrid
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          ".MuiDataGrid-cell": {
            padding: "1em",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-iconButtonContainer > .MuiIconButton-root:hover:hover":
            {
              backgroundColor: "inherit",
            },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#f0f0f0", // Light gray for odd rows
          },
          ".MuiTablePagination-toolbar > p": {
            marginBottom: "initial",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            lineHeight: "1.25em",
            padding: ".5em",
          },
          ...sx,
        }}
        {...props}
      />
    </Paper>
  );
};
export default DefaultDataGrid;
