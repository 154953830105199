import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Form, Col } from "react-bootstrap";

import { useUpdateNistMutation } from "api/memberPortal/certReview/certReview.api";

const CertReqdEditableField = ({ value, canEditNist, certReview }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});

  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = () => {
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...updatedNist,
      },
    });
    setIsEditing(false);
  };

  return canEditNist ? (
    isEditing ? (
      <Form className="mb-3" style={{ display: "flex", alignItems: "center" }}>
        <Col sm={12} md={8}>
          <Form.Group controlId="note">
            <Form.Select
              name="reqd"
              data-cy="nist-details-reqd-select"
              onChange={(e) =>
                setUpdatedNist({ ...updatedNist, reqd: e.target.value })
              }
            >
              <option value="">Select is Required</option>
              {[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ].map((required) => (
                <option key={required.label} value={required.value}>
                  {required.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={12} md={4}>
          <span onClick={handleSave} style={{ cursor: "pointer" }}>
            <SaveIcon style={{ marginLeft: "8px" }} />
          </span>
        </Col>
      </Form>
    ) : (
      <p
        onClick={() => setIsEditing(true)}
        style={{ cursor: "pointer" }}
        data-cy="nist-details-reqd"
      >
        {value}
        <EditIcon style={{ marginLeft: "8px" }} />
      </p>
    )
  ) : (
    <p>{value}</p>
  );
};

export default CertReqdEditableField;
