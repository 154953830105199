import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import { handleAlertAndMailer } from "components/Products/Helper/functions";
import ReactSelect from "components/ReactSelect";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

export default function NIAPValidationTeamModal({
  show,
  handleClose,
  productInfo,
  ears,
  refetch,
  alertView,
}) {
  const [rejected, setRejected] = useState(false);
  const [validators, setValidators] = useState([]);
  const [newValidator, setNewValidator] = useState({});
  const [validations, setValidations] = useState([]);
  const [productsInEval, setProductsInEval] = useState([]);
  const [availableValidators, setAvailableValidators] = useState([]);
  const [reason, setReason] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [alert, setAlert] = useState({});
  const [acmrDueDate, setAcmrDueDate] = useState("");

  const { authToken, csrfToken, currentUser } = useAuth();

  useEffect(() => {
    setRejected(false);
  }, [show]);

  const handleSendAlertAndMail = (product, approved) => {
    let subject = "";
    let recipients = { to: ["Lab"], cc: ["NIAP Management", "Validators"] };
    if (approved) {
      if (productInfo?.maintenance_id !== null) {
        subject = `IAR Assignment for VID ${product?.product}`;
        recipients = {
          to: ["Validators"],
          cc: ["NIAP Management", "AM Approver"],
        };
      } else {
        subject = `Product Assignment for VID${product?.v_id}`;
      }

      handleAlertAndMailer(csrfToken, authToken, {
        alert_type_id: productInfo?.maintenance_id?.maintenance_id
          ? product?.product
          : product?.product_id,
        alert_type: "Product",
        subject: subject,
        recipients: recipients,
        alert_text: subject,
      });
    }
  };

  const fetchEvalProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?submission_type=Evaluation`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProductsInEval(response.data.results);
      })
      .catch((error) => {
        console.log("error getting products", error);
      });
  }, []);

  const fetchValidations = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/filter_validations/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setValidations(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchValidators = useCallback(() => {
    if (
      productInfo?.product_id?.product_id &&
      productInfo?.pp_team_id?.org_id &&
      show
    ) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_validators/?product=${productInfo?.product_id?.product_id}&org=${productInfo.pp_team_id.org_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setValidators(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [productInfo, show === true]);

  const fetchAMValidators = useCallback(() => {
    if (
      productInfo?.maintenance_id?.maintenance_id &&
      productInfo?.pp_team_id?.org_id &&
      show
    ) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_validators/?maintenance=${productInfo?.maintenance_id?.maintenance_id}&org=${productInfo.pp_team_id.org_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setValidators(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [productInfo, show === true]);

  const fetchAvailableValidators = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/available_validators_for_niap_modal/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        let members = response.data;
        let productUserIDs = [];
        let availableMembers = [];
        //get all products that have the currentProductInEval selected as to get users attached
        let filteredProductsInEval = productsInEval.filter(
          (product) =>
            product?.product_id?.product_id ===
            productInfo?.product_id?.product_id,
        );
        for (let product in filteredProductsInEval) {
          productUserIDs.push(filteredProductsInEval[product].user_id.id);
        }
        for (let member in members) {
          if (!productUserIDs.includes(members[member]?.user_id.id)) {
            availableMembers.push(members[member]);
          }
        }
        let availableMemberOptions = availableMembers.map((member) => ({
          value: member?.user_id?.id,
          label: member?.user_id?.first_name + " " + member?.user_id?.last_name,
        }));
        setAvailableValidators(availableMemberOptions);
      })
      .catch((error) => {
        console.log("error getting lab members", error);
      });
  }, [productInfo, productsInEval]);

  const refetchValInfo = () => {
    fetchEvalProducts();
    fetchAvailableValidators();
    if (productInfo?.maintenance_id?.maintenance_id) {
      fetchAMValidators();
    } else {
      fetchValidators();
    }
  };

  useEffect(() => {
    if (show) {
      fetchEvalProducts();
      fetchAvailableValidators();
      fetchValidations();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      if (productInfo?.maintenance_id?.maintenance_id) {
        fetchAMValidators();
      } else {
        fetchValidators();
      }
    }
  }, [productInfo?.product_id, show === true]);

  const handleChange = (e) => {
    setNewValidator({ ...newValidator, [e.target.name]: e.target.value });
    if (e.target.name === "validator") {
      getCompanyOfUser(e.target.value);
    }
  };

  const updateAMProgressPoint = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          maintenance: productInfo?.maintenance_id?.maintenance_id,
          progress_point: 2,
          status: "Completed",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {})
      .catch((error) => console.log(error));
  };

  const handleSend = async (action) => {
    let personnelData = {};

    if (action === "Approved") {
      //we set personnel to approved, and update product statuses to next status
      personnelData = { approved: true, rejected: false };
    } else {
      //we set personnel to rejected, and update product pre_eval_status to rejected
      personnelData = { rejected: true, approved: false };
    }

    let approvingPersonnel = await Promise.all(
      validators.map(async (v) => {
        try {
          await axios.put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${v.id}/`,
            personnelData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          );
          return true;
        } catch (error) {
          console.log("error", error);
          setAlert({
            type: "Error updating personnel. Please try again.",
            variant: "danger",
          });
          return false;
        }
      }),
    );

    //if all personnel were updated successfully, we can update the product
    if (!approvingPersonnel.includes(false)) {
      updateProduct(action);
    }
  };

  const updateProduct = (action) => {
    let productData = {};
    let maintenanceData = {};

    if (action === "Approved") {
      // This will fix any previous products that were in progress and now are approved
      if (
        productInfo?.product_id?.status === "In Progress" ||
        productInfo?.product_id?.status === "Pre-Evaluation"
      ) {
        productData = { pre_eval_status: action };
      } else {
        productData = {
          pre_eval_status: action,
          status: "Pre-Evaluation",
          kickoff_status: "Ready for Recommendation",
        };
      }
      maintenanceData = {
        validation_team_approval_status: "Approved",
        rejection_rationale: null,
      }; //for rejection of maintenance validation team
    } else {
      productData = {
        pre_eval_status: action,
        validation_team_rejection_rationale: reason,
      };
      maintenanceData = {
        validation_team_approval_status: "Rejected",
        rejection_rationale: reason,
      }; //for rejection of maintenance validation team
    }

    if (productInfo?.maintenance_id === null) {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${productInfo?.product_id?.product_id}/`,
          productData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          // handleUpdateProgress(action); // Update Progress Point
          if (action === "Approved") {
            handleSendAlertAndMail(response.data, true);
            if (ears?.length > 0) {
              //if approved, we need to update the ears to approved
              ears
                ?.filter(
                  (ear) =>
                    ear?.pid?.product_id ===
                    productInfo?.product_id?.product_id,
                )
                ?.forEach((ear) => {
                  const update = {
                    ...ear,
                    approved: true,
                    creator: ear.creator.id,
                    pid: ear.pid.product_id,
                    agent: ear.agent.id,
                  };
                  axios
                    .put(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${ear.earid}/`,
                      update,
                      {
                        withCredentials: true,
                        headers: {
                          "X-CSRFToken": csrfToken,
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then(() => {})
                    .catch((error) => console.log(error));
                });
            }
          } else {
            handleSendAlertAndMail(response.data, false);
          }
          refetch();
          handleClose(productInfo?.product_id, action, "Validation");
        })
        .catch((error) => console.log(error));
    } else if (productInfo?.maintenance_id !== null) {
      //if acmr due date, we add field to data
      if (acmrDueDate !== "") {
        maintenanceData["acmr_due_date"] = acmrDueDate;
      }
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${productInfo?.maintenance_id?.maintenance_id}/`,
          maintenanceData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (action === "Approved") {
            handleSendAlertAndMail(response.data, true);
            updateAMProgressPoint(); //we need to update the maintenance progress point if approved
          } else {
            handleSendAlertAndMail(response.data, false);
          }
          handleClose(
            productInfo?.maintenance_id?.product,
            action,
            "Validation",
          );
        })
        .catch((error) => console.log("Unable to update product: " + error));
    }
  };

  const getValidationsByUser = (user_id) => {
    let validationLength = validations.filter(
      (val) => val.user_id === user_id,
    ).length;
    return validationLength;
  };

  const getCompanyOfUser = (user_id) => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${user_id}`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setUserCompany(response.data.company.name);
      })
      .catch(() => {
        setUserCompany("No Company");
      });
  };

  const allowedRoles = [
    "Lead Validator",
    "Senior Validator",
    "ECR Team",
    "ECR Team(Trainee)",
    "Lead Validator(Trainee)",
    "Staff Liaison",
  ];

  let filteredValidators = availableValidators.filter(
    (validator) =>
      !validators.some((member) => member?.user_id?.id === validator.value),
  );

  const columns = [
    {
      title: "Company",
      field: "user_info.company_name",
      cellStyle: { width: "15%", minWidth: "15%" },
      editComponent: () => {
        if (newValidator["validator"]) {
          return <p className="m-0">{userCompany}</p>;
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Name",
      field: "user_id",
      render: (rowData) =>
        rowData?.user_id?.first_name + " " + rowData?.user_id?.last_name,
      editComponent: () => (
        <div data-testid="select-validation-names">
          <ReactSelect
            options={filteredValidators}
            newObj={newValidator}
            handleChange={handleChange}
            name={"validator"}
            isValid={true}
            editComponent={true}
          />
        </div>
      ),
    },
    {
      title: "Active Validations",
      field: "user_id",
      cellStyle: { width: "10%", minWidth: "10%" },
      render: (rowData) => getValidationsByUser(rowData.user_id.id),
      editComponent: () => {
        if (newValidator["validator"]) {
          return (
            <p className="m-0">
              {getValidationsByUser(newValidator["validator"])}
            </p>
          );
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Assigned",
      field: "role",
      editComponent: () => {
        let isFirstOccurrence = true;
        //filter to find the first occurance of a role
        const filteredRoles = allowedRoles?.filter((role) => {
          if (isFirstOccurrence) {
            const isNotFirstOccurrence = validators?.some(
              (member) => member.role === role,
            );
            if (isNotFirstOccurrence) {
              isFirstOccurrence = false;
              return false;
            }
          }
          return true;
        });

        return (
          <>
            <Form.Select
              name="role"
              onChange={handleChange}
              value={newValidator?.role ?? ""}
            >
              <option value={""}></option>
              {filteredRoles?.map((role, idx) => (
                <option value={role} key={idx}>
                  {role}
                </option>
              ))}
            </Form.Select>
          </>
        );
      },
    },
  ];

  const handleRemoveMember = async (rowData) => {
    await axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${rowData.id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        refetchValInfo();
        setAlert({
          type: "Successfully Removed a Member!",
          variant: "success",
        });
      })
      .catch(() =>
        setAlert({ type: "Error Removing Member.", variant: "danger" }),
      );
  };

  const handleAddMember = async () => {
    if (newValidator["role"] && newValidator["validator"]) {
      let data = {
        pp_team_id: productInfo.pp_team_id.org_id,
        assigned_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
        role: newValidator["role"],
        user_id: newValidator["validator"],
        assigned_by: currentUser?.id,
        team_type: "Validation",
        approved: true,
      };
      if (productInfo.maintenance_id?.maintenance_id) {
        data = {
          ...data,
          maintenance_id: productInfo.maintenance_id?.maintenance_id,
        };
      } else {
        data = { ...data, product_id: productInfo?.product_id?.product_id };
      }
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          setAlert({
            type: "Successfully Added a New Member!",
            variant: "success",
          });
          refetchValInfo();
        })
        .catch(() =>
          setAlert({ type: "Error Adding Member.", variant: "danger" }),
        );
    } else {
      setAlert({
        type: "Please select both a name and a role to add member.",
        variant: "danger",
      });
    }
    setNewValidator({});
  };

  const options = {
    search: false,
    pageSize: 10,
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose(false, false, "Validation");
        setAlert(false);
        setRejected(false);
      }}
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title color="primary">
            {rejected
              ? "Rationale"
              : productInfo?.maintenance_id?.maintenance_id
                ? "Proposed Assurance Maintenance Validation Team"
                : "Proposed Validation Team"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={3}>
                <Row>
                  <h5 className="m-0">
                    {/* <strong className="text-uppercase">{productInfo?.product_id?.vendor_id?.name}</strong> */}
                    <strong className="text-uppercase">
                      VID{productInfo?.product_id?.v_id}
                    </strong>
                  </h5>
                </Row>
                <Row>
                  <h5 className="m-0 mb-2">
                    <strong>{productInfo?.product_id?.pp_short_name}</strong>
                  </h5>
                </Row>
              </Col>
              <Col className="m-auto mb-0 d-flex justify-content-end">
                <h6>
                  Project requested by {productInfo?.assigned_by?.first_name}{" "}
                  {productInfo?.assigned_by?.last_name} from{" "}
                  {productInfo?.pp_team_id?.name} on{" "}
                  {moment.utc(productInfo?.assigned_on).format("MM/DD/YYYY")}
                </h6>
              </Col>
            </Row>
            {alert.type && (
              <Alert
                variant={alert.variant}
                className="m-3"
                onClose={() => setAlert({})}
                dismissible
              >
                {alert.type}
              </Alert>
            )}
            {productInfo?.maintenance_id?.maintenance_id && (
              <Row className="mt-3 mb-3">
                <Col sm={12} md={6}>
                  <Form.Group controlId="acmr_due_date">
                    <Form.Label>ACMR Due Date:</Form.Label>
                    <Form.Control
                      className="mb-2"
                      type="date"
                      name="acmr_due_date"
                      onChange={(e) => setAcmrDueDate(e.target.value)}
                      defaultValue={
                        productInfo?.maintenance_id?.acmr_due_date &&
                        moment
                          .utc(productInfo?.maintenance_id?.acmr_due_date)
                          .format("YYYY-MM-DD")
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Container>
          <ResponsiveMaterialTable
            title={
              rejected
                ? "Rationale"
                : productInfo?.maintenance_id?.maintenance_id
                  ? "Proposed Assurance Maintenance Validation Team"
                  : "Proposed Validation Team"
            }
            options={options}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  org: productInfo.pp_team_id.org_id,
                };
                if (productInfo?.maintenance_id?.maintenance_id) {
                  params["maintenance"] =
                    productInfo?.maintenance_id?.maintenance_id;
                } else {
                  params["product"] = productInfo?.product_id?.product_id;
                }
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/niap_validators_view/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                    {
                      withCredentials: true,
                      headers: {
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            columns={columns}
            editable={
              alertView
                ? false
                : {
                    onRowAdd: () => handleAddMember(),
                    onRowDelete: (rowData) => handleRemoveMember(rowData),
                  }
            }
          />
          {rejected && (
            <Form.Group className="mt-4">
              <Form.Label as="h4">Rationale For Rejection</Form.Label>
              <Form.Control
                rows={3}
                as="textarea"
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        {!alertView && (
          <Modal.Footer>
            <Button
              variant="light"
              type="button"
              onClick={() => {
                handleClose(false, false, "Validation");
                setAlert(false);
                setRejected(false);
              }}
            >
              Cancel
            </Button>
            {rejected ? (
              <Button type="button" onClick={() => handleSend("Rejected")}>
                Send
              </Button>
            ) : (
              <>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => setRejected(true)}
                >
                  Clear Recommendation
                </Button>
                <Button type="button" onClick={() => handleSend("Approved")}>
                  Approve For Validation
                </Button>
              </>
            )}
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
}
