import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { addRole } from "../../app/role";
import { addUser } from "../../app/user";
import "./MemberPortalSubheader.css";

export default function Subheader({ userName, selectedDashBoard }) {
  const [cookie, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const memberPortalTitle = () => {
    if (selectedDashBoard === "Lab") {
      return "Lab";
    } else if (selectedDashBoard === "SME") {
      return "My";
    } else {
      return selectedDashBoard;
    }
  };

  const returnToSelf = async () => {
    const tempCookie = cookie["old_auth_token"];
    setCookie("auth_token", tempCookie, { path: "/" });
    removeCookie("old_auth_token", { path: "/" });
    const userData = await axios.get(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
      {
        withCredentials: true,
        headers: { Authorization: `Token ${tempCookie}` },
      },
    );
    dispatch(addUser(userData.data));
    const roleData = await axios.get(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/getRole/`,
      {
        withCredentials: true,
        headers: { Authorization: `Token ${tempCookie}` },
      },
    );
    dispatch(addRole(roleData.data));
    navigate("/community/");
  };

  return (
    <div className="subheader">
      <Container fluid className="text-white">
        {cookie["old_auth_token"] && (
          <Row>
            <Col xs={12} className="text-end">
              <Button
                variant="danger"
                size="lg"
                onClick={returnToSelf}
                data-cy="end-emulation-button"
              >
                End Emulation
              </Button>
            </Col>
          </Row>
        )}
        <Row className="justify-content-start mx-3">
          <Col xs={12} sm={12} md={12} lg={6} className="text-center">
            <div className="p-4 mt-5 mb-5 memberPortalSubheader-row">
              <h1
                className="border-top border-5 border-warning fw-bold p-1 m-4 mb-0"
                style={{ width: "fit-content" }}
              >
                {userName ? `Welcome, ${userName}!` : "Welcome!"}
              </h1>
              <h4
                className="fw-bold text-uppercase m-auto"
                style={{ width: "fit-content" }}
              >
                {memberPortalTitle()} Dashboard
              </h4>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
