import HelpOutline from "@mui/icons-material/HelpOutline";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../Products/Helper/functions";
import ReactSelect from "../../ReactSelect";
import HTMLEditor from "../../UI/HTMLEditor";

export default function RequestAssuranceForm({
  show,
  handleCloseModal,
  product,
  refetch,
  setAlertInfo,
  fromProductDetails,
  setOverlayActive,
  setOverlayText,
  manual,
}) {
  const [cookies] = useCookies();
  const [files, setFiles] = useState([]);
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [formInfo, setFormInfo] = useState({});
  const [labs, setLabs] = useState([]);
  const [labOptions, setLabOptions] = useState([]);
  const [maintenance, setMaintenance] = useState({});
  const permissions = useSelector((state) => state.role.value);
  const currentUser = useSelector((state) => state.user.value);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);
  const [alert, setAlert] = useState("");
  const [projpps, setProjPps] = useState([]);

  const saveDraft = useRef(false);

  const getMaintenance = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${product?.current_assurance_maintenance?.maintenance_id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setMaintenance(response.data);
      })
      .catch((error) => console.log(error));
  };

  const fetchProductPP = useCallback(() => {
    if (product.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_serialized_project_pp?product=${product.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setProjPps(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [product.product_id]);

  useEffect(() => {
    fetchProductPP();
  }, [fetchProductPP]);

  useEffect(() => {
    if (
      product.current_assurance_maintenance?.maintenance_id &&
      !fromProductDetails
    ) {
      getMaintenance();
    } else {
      setMaintenance(false);
    }
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      const newFiles = files ? files : [];
      newFiles.push({
        file_display_name: formInfo.file_display_name,
        uploaded_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
        file_name: e.target.files[0].name,
        file_label: formInfo.file_label,
        file: e.target.files[0],
        active: true,
        private: formInfo.file_public_status === "Public" ? false : true,
      });
      setFiles([...newFiles]);
    }
    if (e.target.name === "file_display_name") {
      setEditedFileLabel(e.target.value);
      if (e.target.value === "Impact Analysis Report (IAR)") {
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          file_label: e.target.value,
          file_public_status: "Proprietary",
        });
      } else {
        setFormInfo({
          ...formInfo,
          [e.target.name]: e.target.value,
          file_label: e.target.value,
        });
      }
    } else if (e.target.name === "file_label") {
      setEditedFileLabel(e.target.value);
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    } else {
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    }
  };

  const removeFile = async (file, idx) => {
    if (file.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => getFiles())
        .catch((error) => console.log("Unable to update file", error));
    } else {
      const newFiles = files.slice();
      newFiles.splice(idx, 1);
      setFiles(newFiles);
    }
  };

  useEffect(() => {
    setFormInfo({
      ...formInfo,
      vendor_poc: product.vendor_poc,
      vendor_email: product.vendor_email,
      vendor_phone: product.vendor_phone,
    });
  }, [product.product_id]);

  const updateProduct = (data, product_id) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product_id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
        handleCloseModal();
        if (!fromProductDetails) {
          setAlertInfo({
            message: "Assurance Maintenance Successfully Created!",
            type: "success",
          });
        }
      })
      .catch((error) => {
        console.log("error updating product: ", error);
      });
  };

  const fetchAssignedLabs = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/testing_labs/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setLabs(response.data);
      })

      .catch((error) => console.log(error));
  }, [authToken]);

  useEffect(() => {
    fetchAssignedLabs();
  }, [fetchAssignedLabs]);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    const submittedData = new FormData();

    submittedData.append("vendor_poc", formInfo.vendor_poc);
    submittedData.append("vendor_email", formInfo.vendor_email);
    submittedData.append("vendor_phone", formInfo.vendor_phone);
    submittedData.append(
      "iar_received",
      new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ).toISOString(),
    );
    if (
      permissions?.role_type === "Vendor" ||
      permissions?.role_type === "NIAP"
    ) {
      //for vendor view
      submittedData.append("vendor_request", currentUser.id);
      submittedData.append(
        "vendor_request_date",
        new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ).toISOString(),
      );
    } else if (permissions?.role_type === "Lab") {
      //for lab view
      submittedData.append("lab_request", currentUser.id);
      submittedData.append(
        "lab_request_date",
        new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ).toISOString(),
      );
    }

    if (saveDraft.draft) {
      submittedData.append("status", "Draft");
    } else if (permissions?.role_type === "NIAP") {
      // if NIAP, should automatically be released
      submittedData.append("status", "Released");
      if (formInfo?.team_due_date) {
        submittedData.append("team_due_date", formInfo.team_due_date);
      }
    } else if (maintenance?.status !== "Requested") {
      submittedData.append("status", "Requested");
    }

    //add despite the role
    if (formInfo.maintenance_release) {
      submittedData.append("maintenance_release", formInfo.maintenance_release);
    }

    if (formInfo.title) {
      submittedData.append("title", formInfo.title);
    } else {
      submittedData.append("title", product?.product_name);
    }

    if (maintenance?.rejection_rationale !== "") {
      submittedData.append("rejection_rationale", "");
    }

    if (Object.keys(maintenance).length === 0) {
      submittedData.append("creator", currentUser.id);
      submittedData.append("editor", currentUser.id);
      submittedData.append(
        "entrydate",
        new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ).toISOString(),
      );
      submittedData.append("product", product?.product_id);
    } else {
      submittedData.append("editor", currentUser.id);
      submittedData.append(
        "moddate",
        new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ).toISOString(),
      );
    }

    if (formInfo.estimated_date) {
      submittedData.append(
        "estimated_date",
        new Date(formInfo.estimated_date).toISOString(),
      );
    }

    if (files.length > 0) {
      for (let f in files) {
        if (!files[f].file_mime_type) {
          // checks if files has aready been added
          submittedData.append("attachments_file", files[f].file);
          submittedData.append(
            "attachments_source_type",
            "Assurance Maintenance",
          );
          submittedData.append("active", JSON.stringify(true));
          submittedData.append("attachments_type", files[f].file_display_name);
          submittedData.append("file_label", files[f].file_label);
          submittedData.append("file_name", files[f].file_name);
          submittedData.append("private", files[f].private);
        }
      }
    }

    //check if ST and IAR are included if not draft
    let missingFiles = false;
    if (!saveDraft.draft) {
      if (
        !files?.some((file) => file.file_display_name === "Security Target")
      ) {
        missingFiles = true;
      }
      if (
        !files?.some(
          (file) => file.file_display_name === "Impact Analysis Report (IAR)",
        )
      ) {
        missingFiles = true;
      }
      if (missingFiles) {
        setAlert(
          "Please include a Security Target file and an IAR file before submitting form.",
        );
      } else {
        setAlert("");
      }
    }

    if (!saveDraft.draft && (!form.checkValidity() || missingFiles)) {
      e.stopPropagation();
    } else {
      if (Object.keys(maintenance).length > 0) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${maintenance?.maintenance_id}/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            if (!fromProductDetails) {
              setAlertInfo({
                message: "Assurance Maintenance Successfully Updated!",
                type: "success",
              });
            }
            if (!saveDraft.draft) {
              handleSendAlertAndMail();
              handleSaveNotes(response.data?.maintenance_id);
            }
            // let data = {
            //   status: "In Assurance Maintenance"
            // }
            // updateProduct(
            //   data,
            //   product?.product_id
            // )
            refetch();
            handleCloseModal();
          })
          .catch((error) => {
            handleCloseModal();
            if (!fromProductDetails) {
              setAlertInfo({
                message: "Error Updating Assurance Maintenance.",
                type: "danger",
              });
            }
            console.log(error);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            let data = {
              status: "In Assurance Maintenance",
              current_assurance_maintenance: response.data?.maintenance_id,
              submission_type: "Assurance Maintenance",
              am_initial_cctl: product?.assigned_lab?.org_id,
            };
            if (
              formInfo?.am_initial_cctl &&
              formInfo?.am_initial_cctl !== product?.assigned_lab?.org_id
            ) {
              data["assigned_lab"] = formInfo?.am_initial_cctl;
            }
            updateProduct(data, product?.product_id);
            if (!saveDraft.draft) {
              handleSendAlertAndMail();
              handleSaveNotes(response.data?.maintenance_id);
            }
            refetch();
            handleCloseModal();
          })
          .catch((error) => {
            handleCloseModal();
            if (!fromProductDetails) {
              setAlertInfo({
                message: "Error Creating Assurance Maintenance.",
                type: "danger",
              });
            }
            console.log(error);
          });
      }
    }
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const getFiles = useCallback(() => {
    if (maintenance?.maintenance_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=assurance-maintenance&file_source_type=Assurance+Maintenance&file_type_id=${maintenance?.maintenance_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setFiles(response.data.filter((f) => (f.active = true)));
        })
        .catch((error) => setFiles([]));
    }
  }, [maintenance?.maintenance_id]);

  const handleSendAlertAndMail = () => {
    let cc_recipents = [];
    if (manual === true) {
      cc_recipents = ["Vendor"];
    } else {
      cc_recipents = ["Lab", "Vendor"];
    }
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: product?.product_id,
      alert_type: "Product",
      alert_source: "Assurance Maintenance",
      subject: `Assurance Maintenance Request for VID${product?.v_id}`,
      recipients: { to: ["NIAP Management"], cc: cc_recipents },
      alert_text: `${product?.assigned_lab.name} has requested Assurance Maintenance for VID${product?.v_id}`,
    });
  };

  const handleSaveNotes = (maintenance_id) => {
    const noteObject = {
      title: "Notes on Changes",
      note: formInfo?.maintenance_release,
      internal_only: true,
      note_type: "assurance_maintenance",
      note_type_id: maintenance_id,
      submitted_by: currentUser?.id,
      submitted_on: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
      visible_to_validators: true,
    };
    axios
      .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, noteObject, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        console.log("note saved");
      })
      .catch((error) => {
        handleCloseModal();
        console.log(error);
      });
  };

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  useEffect(() => {
    let assignedLabs = labs.map((lab) => {
      return { value: lab.org_id, label: lab.name };
    });
    setLabOptions(assignedLabs);
  }, [product?.product_id]);

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus={enforceFocusModal}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            +ASSURANCE MAINTENANCE{" "}
            {permissions?.role_type !== "NIAP" && "REQUEST"}:{" "}
            <span className="text-primary">
              VID {maintenance ? maintenance?.product?.v_id : product.v_id}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mt-3">
              <Col>
                <Row>
                  <Form.Group className="mb-3" controlId="product_name">
                    <Row>
                      <Col sm={4}>
                        <Form.Label column>Product Name:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          plaintext
                          name="product_name"
                          defaultValue={product?.product_name}
                          readOnly
                          hidden
                        />
                        {product?.product_name}
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="certification_date">
                    <Row>
                      <Col sm={4}>
                        <Form.Label>Certification Date:</Form.Label>
                      </Col>
                      <Col className="m-0">
                        <Form.Control
                          plaintext
                          className="p-0"
                          defaultValue={
                            product?.certification_date &&
                            moment
                              .utc(product?.certification_date)
                              .format("MM/DD/YYYY")
                          }
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="expiration_date">
                    <Row>
                      <Col sm={4}>
                        <Form.Label>Expiration Date:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          plaintext
                          className="p-0"
                          defaultValue={
                            product?.assurance_maintenance_date &&
                            moment
                              .utc(product?.assurance_maintenance_date)
                              .format("MM/DD/YYYY")
                          }
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="pp_claim">
                    <Row>
                      <Col sm={4}>
                        <Form.Label>Protection Profile Claims:</Form.Label>
                      </Col>
                      <Col>
                        {projpps?.map((pp, idx) => (
                          <OverlayTrigger
                            key={idx}
                            placement="top"
                            overlay={<Tooltip>{pp.pp.pp_name}</Tooltip>}
                          >
                            <div key={idx} style={{ width: "max-content" }}>
                              {pp.pp.pp_short_name}
                            </div>
                          </OverlayTrigger>
                        ))}
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            {maintenance && maintenance?.rejection_rationale !== null && (
              <Row>
                <Col>
                  <h6 className="fw-bold form-title mb-2">
                    RATIONALE FOR REJECTION
                  </h6>
                  <Form.Control
                    as="textarea"
                    name="rejection_rationale"
                    rows={4}
                    disabled={true}
                    onChange={handleChange}
                    defaultValue={maintenance?.rejection_rationale}
                  />
                </Col>
              </Row>
            )}
            {/* {(permissions?.role_type === "Vendor" || fromProductDetails) &&
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="estimated_date">
                  <Form.Label>Estimated Date:
                   </Form.Label>
                   <OverlayTrigger 
                    overlay={  <Tooltip id="button-tooltip" >
                    Estimated date is when the vendors have expected this Assurance Maintenance to be completed by.
                  </Tooltip>}>
                    <HelpOutline/>
                   </OverlayTrigger>
                  <Form.Control
                    className="mb-3 w-50"
                    type="date"
                    name="estimated_date"
                    onChange={handleChange}
                    defaultValue={moment
                      .utc(maintenance?.estimated_date)
                      .format("YYYY-MM-DD")}
                  />
                </Form.Group>
              </Col>
            </Row>
            } */}
            {permissions?.role_type === "Vendor" && !fromProductDetails && (
              <Row className="mt-3">
                <Col>
                  <Form.Group controlId="am_initial_cctl">
                    <Form.Label>
                      CCTL from Evaluation: {product?.assigned_lab?.name}:
                    </Form.Label>
                    <ReactSelect
                      name="am_initial_cctl"
                      handleChange={handleChange}
                      options={labOptions}
                      newObj={formInfo}
                      isValid={true}
                      defaultValue={labOptions?.find(
                        (lab) => lab.value === product?.assigned_lab?.org_id,
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {permissions?.role_type === "NIAP" && (
              <Row className="mb-3">
                <Form.Group controlId="title">
                  <Form.Label>Maintenance Release:</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    readOnly={permissions?.role_type === "Validator"}
                    defaultValue={product?.product_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mt-3">
              <Col>
                <p>UPDATE POC INFORMATION</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="vendor_poc">
                  <Form.Label>POC NAME: {product?.vendor_poc}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="vendor_poc"
                    onChange={handleChange}
                    value={formInfo.vendor_poc}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="vendor_email">
                  <Form.Label>POC EMAIL: {product?.vendor_email}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="vendor_email"
                    onChange={handleChange}
                    value={formInfo.vendor_email}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="vendor_phone">
                  <Form.Label>
                    POC PHONE NUMBER: {product?.vendor_phone}:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="vendor_phone"
                    onChange={handleChange}
                    value={formInfo.vendor_phone}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h6 className="fw-bold form-title mb-2">ATTACH FILES</h6>
                <Row>
                  <Col>
                    <Form.Select
                      name="file_display_name"
                      onChange={handleChange}
                    >
                      <option value="">Type</option>
                      <option value="Security Target">Security Target</option>
                      {/* <option value="User Guide">User Guide</option> */}
                      <option value="Administrative Guide (AGD)">
                        Admin Guide
                      </option>
                      <option value="Impact Analysis Report (IAR)">IAR</option>
                      <option value="Other Documents">Other Documents</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      name="file_label"
                      placeholder="Label"
                      value={editedFileLabel}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Select
                      name="file_public_status"
                      value={formInfo.file_public_status}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="Public">Public</option>
                      <option value="Proprietary">Proprietary</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Form.Group controlId="formFileMultiple">
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={handleChange}
                        disabled={
                          !formInfo.file_label ||
                          !formInfo.file_display_name ||
                          !formInfo.file_public_status
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <p className="fst-italic text-secondary">
                Security Target and IAR Files Required to Submit
              </p>
            </Row>
            <Row className="mt-3">
              <Col lg={11}>
                {files?.length > 0 && (
                  <div className="border">
                    <p className="mx-3 mt-2">Attached Files</p>
                    {files
                      ?.filter((file) => {
                        return (
                          new Date().getFullYear() ===
                          new Date(file?.uploaded_on).getFullYear()
                        );
                      })
                      ?.map((file, idx) => (
                        <Row
                          className="m-3 d-flex justify-content-around"
                          key={idx}
                        >
                          <Col sm={3}>
                            <Button
                              variant="link"
                              className="text-bright-navy text-decoration-underline p-0"
                              onClick={() => downloadFile(file)}
                            >
                              {file.file_name}
                            </Button>
                          </Col>
                          <Col sm={3}>{file.file_label}</Col>
                          <Col sm={4}>
                            {file.private ? "Proprietary" : "Public"}
                          </Col>
                          <Col sm={1} className="d-flex justify-content-center">
                            <Button
                              variant="outline-primary"
                              className="attachment-remove"
                              onClick={() => removeFile(file, idx)}
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </div>
                )}
              </Col>
            </Row>
            {permissions?.role_type === "NIAP" && (
              <Row>
                <Form.Group controlId="team_due_date">
                  <Form.Label>Validation Team Assignment Due Date:</Form.Label>
                  <Form.Control
                    className="mb-3 w-50"
                    type="date"
                    name="team_due_date"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mt-3">
              <Col>
                <h6 className="fw-bold form-title mb-2">NOTES</h6>
                <HTMLEditor
                  name="maintenance_release"
                  handleChange={handleChange}
                  setIsValid={() => {}}
                  isValid={true}
                  defaultValue={maintenance?.maintenance_release}
                  setEnforceFocusModal={setEnforceFocusModal}
                />
              </Col>
            </Row>
            {alert !== "" && (
              <Row className="m-3 mb-0">
                <Alert
                  className="mb-0"
                  variant="danger"
                  dismissible
                  onClose={() => setAlert("")}
                >
                  {alert}
                </Alert>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleCloseModal}>
            Cancel
          </Button>
          {permissions?.role_type !== "Vendor" &&
            permissions?.role_type !== "NIAP" && (
              <Button
                type="submit"
                onClick={() => {
                  saveDraft.draft = true;
                }}
              >
                Save
              </Button>
            )}
          <Button
            type="submit"
            variant="primary"
            onClick={() => {
              saveDraft.draft = false;
            }}
          >
            {permissions?.role_type === "NIAP" ? "Release AM" : "Request AM"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
