import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import axios from "axios";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";
import MultipleMembersOneListForm from "../Members/MultipleMembersOneListForm";
import OneMemberMultipleListsForm from "../Members/OneMemberMultipleListsForm";
import RemoveOneMemberMultipleListsForm from "../Members/RemoveOneMemberMultipleListsForm";

import ListForm from "./ListForm";

const Lists = () => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showMultipleMemberModal, setShowMultipleMemberModal] = useState(false);
  const [showOneMemberModal, setShowOneMemberModal] = useState(false);
  const [showRemoveOneMemberModal, setShowRemoveOneMemberModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedList, setSelectedList] = useState({});
  const [distributionLists, setDistributionLists] = useState([]);
  const [filteredListRelation, setFilteredListRelation] = useState("NIAP");
  const [filteredLists, setFilteredLists] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);

  const getDistributionLists = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setDistributionLists(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getDistributionLists();
  }, []);

  useEffect(() => {
    if (distributionLists?.length > 0) {
      if (filteredListRelation === "CCP") {
        setFilteredLists(
          distributionLists.filter((list) =>
            list.primarySmtpAddress.includes("commoncriteriaportal"),
          ),
        );
      } else if (filteredListRelation === "NIAP") {
        setFilteredLists(
          distributionLists.filter((list) =>
            list.primarySmtpAddress.includes("niap-ccevs"),
          ),
        );
      }
    }
  }, [filteredListRelation, distributionLists?.length]);

  const handleShowModal = (event, list) => {
    setSelectedList(list);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setSelectedList({});
    setShowModal(false);
  };

  const handleShowMultipleMemberModal = (event, list) => {
    setSelectedList(list);
    setShowMultipleMemberModal(true);
  };

  const handleHideMultipleMemberModal = () => {
    setSelectedList({});
    setShowMultipleMemberModal(false);
  };

  const handleShowOneMemberModal = (event, list) => {
    setSelectedList(list);
    setShowOneMemberModal(true);
  };

  const handleHideOneMemberModal = () => {
    setSelectedList({});
    setShowOneMemberModal(false);
  };

  const handleShowRemoveOneMemberModal = (event, list) => {
    setSelectedList(list);
    setShowRemoveOneMemberModal(true);
  };

  const handleHideRemoveOneMemberModal = () => {
    setSelectedList({});
    setShowRemoveOneMemberModal(false);
  };

  const handleShowDeleteModal = (event, list) => {
    setSelectedList(list);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setSelectedList({});
    setShowDeleteModal(false);
  };

  const handleDeleteList = () => {
    axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/${selectedList.name}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        // tableRef.current.onQueryChange()
        getDistributionLists();
        handleHideDeleteModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useInterval(() => {
    getDistributionLists();
  }, 600000); //10 min

  // const reloadTable = () => {
  //     tableRef.current.onQueryChange()
  // }

  const columns = [
    {
      title: "Name",
      field: "name",
      defaultSort: "asc",
      render: (rowData) => {
        return (
          <Button
            variant="link"
            className="p-0 text-decoration-underline"
            onClick={() => navigate(`members/${encodeURI(rowData.name)}`)}
          >
            {rowData?.name}
          </Button>
        );
      },
    },
    // {title: "Display Name", field: "displayName", defaultSort: 'asc'},
    {
      title: "Primary Address",
      field: "primarySmtpAddress",
      render: (rowData) => {
        return (
          <Button
            variant="link"
            onClick={() => navigate(`members/${encodeURI(rowData.name)}`)}
            className="p-0 text-decoration-underline"
          >
            {rowData?.primarySmtpAddress}
          </Button>
        );
      },
    },
    { title: "Description", field: "description" },
    {
      title: "Actions",
      filtering: false,
      sorting: false,
      rowStyle: { textAlign: "right" },
      disableClick: true,
      render: (rowData) => {
        return (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                    onClick={(e) => handleShowModal(e, rowData)}
                  >
                    <EditIcon color="action" /> Edit
                  </Button>
                  <br />
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                    onClick={(e) => handleShowDeleteModal(e, rowData)}
                  >
                    <DeleteIcon color="action" /> Delete
                  </Button>
                  <nr />
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                    onClick={(e) => handleShowMultipleMemberModal(e, rowData)}
                  >
                    <PersonAddIcon color="action" /> Add Members
                  </Button>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">
              <MoreVertIcon className="float-end" />
            </Button>
          </OverlayTrigger>
        );
      },
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
      textAlign: "left",
    },
    pageSize: 20,
    sorting: true,
    emptyRowsWhenPaging: false,
  };

  return (
    <Container className="mt-4">
      {alert?.message && (
        <Row>
          <Col>
            <Alert
              variant={alert.type}
              onClose={() => setAlert({ message: "", type: "" })}
              dismissible
            >
              {alert.message}
            </Alert>
          </Col>
        </Row>
      )}
      {distributionLists?.length > 0 && (
        <Form>
          <Form.Group controlId="listRelation">
            <Form.Label>Domain</Form.Label> <br />
            <Form.Check
              type="radio"
              label="Common Criteria Portal"
              onChange={() => setFilteredListRelation("CCP")}
              checked={filteredListRelation === "CCP"}
              name="listRelation"
              inline
            />
            <Form.Check
              type="radio"
              label="NIAP-CCEVS"
              onChange={() => setFilteredListRelation("NIAP")}
              checked={filteredListRelation === "NIAP"}
              name="listRelation"
              inline
            />
            {/* <Form.Check type="radio" label="All" onChange={() => setFilteredListRelation("")} checked={filteredListRelation === ""} name="listRelation" inline /> */}
          </Form.Group>
        </Form>
      )}
      <ResponsiveMaterialTable
        title={"Manage Mailing Lists"}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: "Add Mailing List",
            onClick: (e) => {
              handleShowModal(e, {});
            },
            isFreeAction: true,
          },
          {
            icon: () => <PersonAddIcon />,
            tooltip: "Add Member",
            onClick: (e) => {
              handleShowOneMemberModal(e, {});
            },
            isFreeAction: true,
          },
          {
            icon: () => <PersonRemoveIcon />,
            tooltip: "Remove Member",
            onClick: (e) => {
              handleShowRemoveOneMemberModal(e, {});
            },
            isFreeAction: true,
          },
        ]}
        columns={columns}
        data={filteredListRelation === "" ? distributionLists : filteredLists}
        options={options}
        isLoading={isLoading}
      />
      <ListForm
        show={showModal}
        list={selectedList}
        onHide={handleHideModal}
        fetchMailingLists={getDistributionLists}
        setAlert={setAlert}
      />
      <MultipleMembersOneListForm
        show={showMultipleMemberModal}
        listName={selectedList?.name}
        onHide={handleHideMultipleMemberModal}
        getMembers={getDistributionLists}
        setAlert={setAlert}
      />
      <OneMemberMultipleListsForm
        show={showOneMemberModal}
        lists={distributionLists}
        onHide={handleHideOneMemberModal}
        fetchMailingList={getDistributionLists}
        setAlert={setAlert}
      />
      <RemoveOneMemberMultipleListsForm
        show={showRemoveOneMemberModal}
        onHide={handleHideRemoveOneMemberModal}
        fetchMailingList={getDistributionLists}
        setAlert={setAlert}
      />
      <Modal size="md" show={showDeleteModal} onHide={handleHideDeleteModal}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Delete Draft</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">
            Are You Sure You Want to Delete List: {selectedList.name}?
          </h4>
          <div className="d-flex justify-content-center mt-4">
            <Button
              variant="primary"
              className="m-1"
              onClick={handleHideDeleteModal}
            >
              Cancel
            </Button>
            <Button variant="danger" className="m-1" onClick={handleDeleteList}>
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Lists;
