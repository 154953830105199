import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  OverlayTrigger,
  Popover,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

export default function ProductsInDraftModule({
  setProduct,
  handleOpenDraft,
  updateTables,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState({});
  const draftTableRef = useRef();
  const reviewTableRef = useRef();

  const handleShowProductFormModal = (rowData) => {
    setProduct(rowData);
    handleOpenDraft();
  };

  useEffect(() => {
    draftTableRef.current.onQueryChange();
    reviewTableRef.current.onQueryChange();
  }, [updateTables]);

  const handleDeleteDraft = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${deleteProduct.product_id}/`,
        {
          deleted: true,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        draftTableRef.current.onQueryChange();
        setShowDeleteModal(false);
      })
      .catch((error) => console.log("Unable to update product: " + error));
  };

  const columnsForProjectReview = [
    {
      title: "VID No.",
      field: "v_id",
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <Button
          href={`/community/products/details/${rowData.product_id}`}
          size="sm"
          className="rounded-pill"
        >
          REVIEW
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "Type",
      field: "submission_type",
    },
    {
      title: "Date",
      field: "draft_date",
      render: (rowData) => (
        <>
          {rowData.draft_date
            ? moment.utc(rowData?.draft_date).format("MM/DD/YYYY")
            : "No Date"}
        </>
      ),
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                  {permissions?.role_permissions?.find(
                    (permission) => permission.area === "Project",
                  )?.update && (
                    <Button
                      variant="link"
                      className="m-1 p-0 small-font"
                      onClick={() => handleShowProductFormModal(rowData)}
                    >
                      <EditIcon color="action" /> Continue
                    </Button>
                  )}
                  {permissions?.role_permissions?.find(
                    (permission) => permission.area === "Project",
                  )?.destroy && (
                    <Button
                      variant="link"
                      className="m-1 p-0 small-font"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setDeleteProduct(rowData);
                      }}
                    >
                      <DeleteIcon color="action" /> Delete
                    </Button>
                  )}
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">
              <MoreVertIcon className="float-end" />
            </Button>
          </OverlayTrigger>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    search: false,
    toolbar: false,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      {showDeleteModal && (
        <Modal
          size="md"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Delete Draft</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center">
              Are You Sure You Want to Delete VID: {deleteProduct?.v_id}?
            </h4>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="primary"
                className="m-1"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="m-1"
                onClick={handleDeleteDraft}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey="Products in Draft">
            <Tab
              eventKey="Products in Draft"
              title="Products in Internal Draft"
            >
              <ResponsiveMaterialTable
                columns={columns}
                tableRef={draftTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                    } = query;

                    let newFilters = filters.map((filter) => {
                      let value = "";
                      if (Array.isArray(filter.value)) {
                        value = filter.value;
                      } else {
                        value = filter.value.replace(/['"]+/g, "");
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                      status: "Internal Draft",
                    };
                    axios
                      .get(
                        `${
                          process.env.REACT_APP_DJANGO_ENDPOINT
                        }project/product/current_user_products/?${qs.stringify(
                          params,
                          {
                            arrayFormat: "comma",
                          },
                        )}`,
                        {
                          withCredentials: true,
                          headers: {
                            Authorization: `Token ${authToken}`,
                          },
                        },
                      )
                      .then((response) => {
                        resolve({
                          data: response.data.results,
                          page: page,
                          totalCount: response.data.count,
                        });
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                }
                options={options}
              />
            </Tab>
            <Tab
              eventKey="Products in Final Review"
              title="Products in Final Review"
            >
              <ResponsiveMaterialTable
                columns={columnsForProjectReview}
                tableRef={reviewTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                    } = query;

                    let newFilters = filters.map((filter) => {
                      let value = "";
                      if (Array.isArray(filter.value)) {
                        value = filter.value;
                      } else {
                        value = filter.value.replace(/['"]+/g, "");
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                    };
                    axios
                      .get(
                        `${
                          process.env.REACT_APP_DJANGO_ENDPOINT
                        }project/product/lab_final_review/?${qs.stringify(
                          params,
                          {
                            arrayFormat: "comma",
                          },
                        )}`,
                        {
                          withCredentials: true,
                          headers: {
                            Authorization: `Token ${authToken}`,
                          },
                        },
                      )
                      .then((response) => {
                        resolve({
                          data: response.data.results,
                          page: page,
                          totalCount: response.data.count,
                        });
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                }
                options={options}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
}
