import { baseApi } from "api/BaseApi";

import {
  Nist,
  NistStatus,
  NistProductPPs,
  NistComments,
  NistHistory,
} from "./certReview.types";

const NIST_BASE_QUERY = "nist";
const VIRTUAL_TEAM_MEMBER_BASE_QUERY = "virtual-team/members";
const PRODUCT_BASE_QUERY = "project";

const nistApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    isMyNcrt: build.query<boolean, string>({
      query: (crid) => ({
        url: `${NIST_BASE_QUERY}/is_my_ncrt/`,
        params: { nist: crid },
      }),
      transformResponse: (response: { my_ncrt: boolean }) => response.my_ncrt,
    }),

    getNist: build.query<Nist, string>({
      query: (nistId) => `${NIST_BASE_QUERY}/${nistId}/`,
      providesTags: ["NIST"],
    }),

    deleteNist: build.mutation<void, number>({
      query: (nistId) => ({
        url: `${NIST_BASE_QUERY}/${nistId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["NIST"],
    }),

    getAllNistStatuses: build.query<NistStatus[], void>({
      query: () => `${NIST_BASE_QUERY}/status/get_all_statuses/`,
      transformResponse: (response: NistStatus[]) =>
        response.filter(
          (status) =>
            ![
              "Rejected - Revision Required",
              "Approved",
              "Rejected, revisions needed",
            ]?.includes(status.status ?? ""),
        ),
    }),

    getNistHistory: build.query<NistHistory[], string>({
      query: (crid) => ({
        url: `${NIST_BASE_QUERY}/get_history/`,
        params: { crid: crid },
      }),
      providesTags: ["NIST_HISTORY"],
    }),

    getAllNistComments: build.query<NistComments[], string>({
      query: (crid) => ({
        url: `${NIST_BASE_QUERY}/comments/get_all_comments/`,
        params: { crid: crid },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ upid }) => ({
                type: "NIST_COMMENTS" as const,
                upid,
              })),
              "NIST_COMMENTS",
            ]
          : ["NIST_COMMENTS"],
    }),

    deleteNistComment: build.mutation<void, number>({
      query: (commentId) => ({
        url: `${NIST_BASE_QUERY}/comments/${commentId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["NIST_COMMENTS"],
    }),

    updateNistComment: build.mutation<
      void,
      { commentId: string; body: Partial<NistComments> }
    >({
      query: ({ commentId, body }) => ({
        url: `${NIST_BASE_QUERY}/comments/${commentId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { commentId }) => [
        { type: "NIST_COMMENTS", crid: commentId },
      ],
    }),

    addNistComment: build.mutation<NistComments, NistComments>({
      query: (body) => ({
        url: `${NIST_BASE_QUERY}/comments/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["NIST_COMMENTS"],
    }),

    isNcrtTeamMember: build.query<boolean, string>({
      query: (currentUserId) => ({
        url: `${VIRTUAL_TEAM_MEMBER_BASE_QUERY}/is_ncrt_team_member/`,
        params: { user: currentUserId },
      }),
      transformResponse: (response: { is_member: boolean }) =>
        response.is_member,
    }),

    // using Partial here because we only want to update the fields that are being passed in
    updateNist: build.mutation<Nist, { nistId: string; body: Partial<Nist> }>({
      query: ({ nistId, body }) => ({
        url: `${NIST_BASE_QUERY}/${nistId}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["NIST", "NIST_HISTORY"],
    }),

    getNistProductPPs: build.query<NistProductPPs[], string>({
      query: (productId) => ({
        url: `${PRODUCT_BASE_QUERY}/protection-profiles/protection_profiles_by_name/`,
        params: { product: productId },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useIsMyNcrtQuery,
  useGetNistQuery,
  useDeleteNistMutation,
  useIsNcrtTeamMemberQuery,
  useUpdateNistMutation,
  useGetAllNistStatusesQuery,
  useGetNistProductPPsQuery,
  useGetAllNistCommentsQuery,
  useDeleteNistCommentMutation,
  useUpdateNistCommentMutation,
  useAddNistCommentMutation,
  useGetNistHistoryQuery,
} = nistApi;
