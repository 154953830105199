import { useState } from "react";
import { Accordion } from "react-bootstrap";

import NISTNotes from "../NISTNotes";

import DraftResponseField from "./DraftResponseField";
import InitialCommentEditableField from "./InitialCommentEditableField";
import JustificationEditableField from "./JustificationEditableField";
import NistHistory from "./NistHistory";
import OfficialResponse from "./OfficialResponse";

const NISTSummary = ({ certReview, canEditNist }) => {
  const [activeKey, setActiveKey] = useState([]);

  const handleItemClick = (idx) => {
    let newKeys = [...activeKey];
    if (newKeys.includes(idx)) {
      newKeys = newKeys.filter((key) => key !== idx);
    } else {
      newKeys.push(idx);
    }
    setActiveKey(newKeys);
  };

  return (
    <>
      <Accordion
        defaultActiveKey={[
          "just",
          "comments",
          "draft",
          "response",
          "notes",
          "history",
        ]}
        alwaysOpen
        data-cy="nist-summary-accordion"
      >
        <JustificationEditableField
          canEditNist={canEditNist}
          certReview={certReview}
          handleItemClick={handleItemClick}
        />
        <InitialCommentEditableField
          canEditNist={canEditNist}
          certReview={certReview}
          handleItemClick={handleItemClick}
        />
        <DraftResponseField
          canEditNist={canEditNist}
          certReview={certReview}
          handleItemClick={handleItemClick}
        />
        <OfficialResponse
          canEditNist={canEditNist}
          certReview={certReview}
          handleItemClick={handleItemClick}
        />
        <Accordion.Item eventKey="notes" className="border-bottom-dark">
          {certReview?.crid && (
            <>
              <Accordion.Header onClick={() => handleItemClick("notes")}>
                <h4>Certificate Review Notes</h4>
              </Accordion.Header>
              <Accordion.Body>
                <NISTNotes item={certReview} id={certReview?.crid} />
              </Accordion.Body>
            </>
          )}
        </Accordion.Item>
        {canEditNist && (
          <NistHistory
            crid={certReview?.crid}
            handleItemClick={handleItemClick}
          />
        )}
      </Accordion>
    </>
  );
};

export default NISTSummary;
