import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function PolicyLetterModal({
  show,
  setShow,
  currentPolicy,
  refetch,
  editingPolicy,
  setAlert,
  setEditingPolicy,
}) {
  const [currPolicy, setCurrPolicy] = useState({});
  const [newPolicy, setNewPolicy] = useState({});
  const [validated, setValidated] = useState(false);
  const { authToken, csrfToken, currentUser } = useAuth();

  const handleChange = (e) => {
    if (editingPolicy) {
      if (e.target.name === "attachment_file") {
        setCurrPolicy({ ...currPolicy, [e.target.name]: e.target.files[0] });
      } else {
        setCurrPolicy({ ...currPolicy, [e.target.name]: e.target.value });
      }
    } else {
      if (e.target.name === "attachment_file") {
        setNewPolicy({ ...newPolicy, [e.target.name]: e.target.files[0] });
      } else {
        setNewPolicy({ ...newPolicy, [e.target.name]: e.target.value });
      }
    }
  };

  useEffect(() => {
    setValidated(false);
    if (editingPolicy) {
      setCurrPolicy(currentPolicy);
    }
  }, [show]);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (editingPolicy) {
        handleSave();
      } else {
        let submittedData = new FormData();

        for (let key in newPolicy) {
          submittedData.append(key, newPolicy[key]);
        }

        submittedData.append("creator", currentUser.id);
        submittedData.append("editor", currentUser.id);

        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            setAlert({
              message: "Policy Letter Successfully Added",
              type: "success",
            });
            refetch();
            setEditingPolicy(false);
            setShow(false);
          })
          .catch((error) => {
            console.error("Error Updating resource:", error);
          });
      }
    }
    setValidated(true);
  };

  const handleSave = () => {
    let submittedData = new FormData();
    for (let key in currPolicy) {
      if (currPolicy[key] !== null) {
        submittedData.append(key, currPolicy[key]);
      }
    }
    submittedData.append("creator", currPolicy.creator.id);
    submittedData.append("editor", currentUser.id);
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/${currPolicy?.policy_id}/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
        setEditingPolicy(false);
        setShow(false);
      })
      .catch((error) => {
        console.error("Error Updating resource:", error);
      });
  };

  const downloadFile = (fileName) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_policy_letter/?file_name=${fileName}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        fileDownload(response.data, fileName);
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShow(false);
      }}
      size="lg"
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {editingPolicy
              ? "EDIT POLICY INFORMATION"
              : "+NEW POLICY INFORMATION"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="policy_num">
                <Form.Label className="fw-bold"> POLICY NO:*</Form.Label>
                <Form.Control
                  type="number"
                  name="policy_num"
                  onChange={handleChange}
                  value={
                    editingPolicy
                      ? currPolicy?.policy_num
                      : newPolicy?.policy_num
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="update_num">
                <Form.Label className="fw-bold"> UPDATE NO:</Form.Label>
                <Form.Control
                  type="number"
                  name="update_num"
                  onChange={handleChange}
                  value={
                    editingPolicy
                      ? currPolicy?.update_num
                      : newPolicy?.update_num
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="publication_date">
                <Form.Label className="fw-bold"> PUBLICATION DATE:*</Form.Label>
                <Form.Control
                  type="date"
                  name="policy_date"
                  onChange={handleChange}
                  value={
                    editingPolicy
                      ? currPolicy?.policy_date
                        ? moment
                            .utc(currPolicy.policy_date)
                            .format("YYYY-MM-DD")
                        : ""
                      : Object.keys(newPolicy).length !== 0 &&
                          newPolicy?.policy_date
                        ? moment.utc(newPolicy.policy_date).format("YYYY-MM-DD")
                        : ""
                  }
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="addendum_num">
                <Form.Label className="fw-bold"> ADDENDUM NO:</Form.Label>
                <Form.Control
                  type="number"
                  name="addendum_num"
                  onChange={handleChange}
                  value={
                    editingPolicy
                      ? currPolicy?.addendum_num
                      : newPolicy?.addendum_num
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="author">
                <Form.Label className="fw-bold"> AUTHOR:*</Form.Label>
                <Form.Control
                  type="text"
                  name="author"
                  onChange={handleChange}
                  value={editingPolicy ? currPolicy?.author : newPolicy?.author}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="addendum_num">
                <Form.Label className="fw-bold"> SUPERSEDED NO:</Form.Label>
                <Form.Control
                  type="number"
                  name="superseded_num"
                  onChange={handleChange}
                  value={
                    editingPolicy
                      ? currPolicy?.superseded_num
                      : newPolicy?.superseded_num
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="policy_title">
              <Form.Label className="fw-bold"> TITLE:*</Form.Label>
              <Form.Control
                type="text"
                name="policy_title"
                onChange={handleChange}
                value={
                  editingPolicy
                    ? currPolicy?.policy_title
                    : newPolicy?.policy_title
                }
                required
              />
            </Form.Group>
          </Row>
          {editingPolicy && (
            <Row>
              <Form.Label className="fw-bold">CURRENT LETTER:</Form.Label>

              <Row className="mb-3">
                <Button
                  variant="link"
                  className="text-bright-navy text-decoration-underline"
                  onClick={() => downloadFile(currPolicy?.filename)}
                >
                  {currPolicy?.filename}
                </Button>
              </Row>
            </Row>
          )}
          <Row>
            <Form.Group controlId="attachment_file">
              <Form.Label className="fw-bold">
                {editingPolicy ? "ADD NEW LETTER" : "POLICY LETTER*"}
              </Form.Label>
              <Form.Control
                type="file"
                name="attachment_file"
                onChange={handleChange}
                required={!editingPolicy}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          {editingPolicy ? (
            <Button type="submit">Save</Button>
          ) : (
            <Button type="submit">Add</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
