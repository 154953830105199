import { OverlayTrigger, Tooltip, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const PpClaimCell = ({ project, projpps }) => {
  if (!projpps) return null;
  return (
    <ListGroup>
      {projpps[project?.product_id]?.map((projpp) => (
        <OverlayTrigger
          key={projpp.id}
          placement="top"
          overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
        >
          <Link
            to={`/community/protectionprofiles/details/${projpp.id}`}
            className="text-decoration-underline"
          >
            <ListGroup.Item disabled>{projpp.pp_short_name}</ListGroup.Item>
          </Link>
        </OverlayTrigger>
      ))}
    </ListGroup>
  );
};
export default PpClaimCell;
