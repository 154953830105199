import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

export default function InactiveProductsTable({ tableRef, inactiveProducts }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];

  const columns = [
    {
      title: "VID",
      cellStyle: { width: "10%", minWidth: "10%" },
      field: "v_id",
      render: (rowData) => (
        <Link
          to={`products/details/${rowData.product_id}`}
          className="text-decoration-underline"
        >
          {rowData?.v_id}
        </Link>
      ),
    },
    {
      title: "Name",
      field: "product_name",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Submitted",
      field: "check_in_date",
      render: (rowData) => {
        return <>{moment(rowData?.check_in_date).format("MM/DD/YYYY")}</>;
      },
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <Link
              to={`products/details/${rowData.product_id}`}
              className="text-decoration-none"
            >
              <Button variant="link" className="m-1 p-0 btn-link">
                <VisibilityIcon color="action" /> View
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      <ResponsiveMaterialTable
        title={"Inactive Products"}
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_inactive_products/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </>
  );
}
