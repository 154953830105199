import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";
import DeleteModal from "../../UI/DeleteModal";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import NewTDModal from "./NewTDModal";

const CommunityTechDecisionTable = () => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  // const [filtering, setFiltering] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const permissions = useSelector((state) => state.role.value);
  const currentUser = useSelector((state) => state.user.value);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTD, setSelectedTD] = useState({});
  const tableRef = useRef();

  // const handleFiltering = () => setFiltering(!filtering);

  const handleShowModal = (e, rowData) => {
    setShowModal(true);
  };

  const handleCloseModal = async (e) => {
    setShowModal(false);
    tableRef.current.onQueryChange();
  };

  const refetchTD = useCallback(() => {
    tableRef.current.onQueryChange();
  }, []);

  useEffect(() => {
    refetchTD();
  }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 180000); // 3 min

  const handleArchive = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/${selectedTD.td_id}/`,
        {
          removed_by: currentUser.id,
          removed_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          status: "Archived",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setShowDeleteModal(false);
        setSelectedTD({});
        refetchTD();
      })
      .catch((error) => console.log("Unable to archive pp: " + error));
  };

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    pageSizeOptions: [20, 50, 100],
    pageSize: 20,
  };

  const columns = [
    {
      title: "Item",
      field: "identifier",
      render: (rowData) => (
        <Link
          to={`details/${rowData.identifier}`}
          className="text-decoration-underline"
        >
          {rowData?.identifier}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    { title: "Status", field: "status" },
    { title: "Reference", field: "related_to" },
    {
      title: "Publication Date",
      field: "publication_date",
      type: "date",
      render: (rowData) =>
        rowData?.publication_date &&
        moment.utc(rowData?.publication_date).format("MM/DD/YYYY"),
    },
    {
      title: "Last Modified",
      field: "modified_on",
      type: "date",
      render: (rowData) =>
        rowData?.modified_on &&
        moment.utc(rowData?.modified_on).format("MM/DD/YYYY"),
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      headerStyle: { background: "white", position: "sticky", right: 0 },
      cellStyle: { background: "white", position: "sticky", right: 0 },
      render: (rowData) => (
        <div>
          <Link
            to={`details/${rowData.identifier}`}
            className="text-decoration-none"
          >
            <Button variant="primary" className="m-1 p-0 w-fit-content">
              View
            </Button>
          </Link>
          {permissions?.role_permissions?.find(
            (permission) => permission.area === "Technical Decisions",
          )?.destroy &&
            rowData.status !== "Archived" && (
              <Button
                variant="warning"
                className="m-1 p-0 w-fit-content"
                onClick={() => {
                  setSelectedTD(rowData);
                  setShowDeleteModal(true);
                }}
              >
                Archive
              </Button>
            )}
        </div>
      ),
    },
  ];

  return (
    permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Decisions",
    )?.read && (
      <Container fluid className="pt-5">
        <DeleteModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          type={`TD - ${selectedTD?.td_id}`}
          deleteFunction={handleArchive}
          isArchive={true}
        />
        <Row className="align-items-center">
          <Col xs={12} md={6} className="mb-3 d-flex justify-content-md-start">
            <h1 className="fw-bold text-uppercase">Technical Decisions</h1>
          </Col>

          {/* Buttons Container: Align buttons to the end on larger screens */}
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column flex-md-row justify-content-md-end"
          >
            {/* Add Technical Decision Button */}
            {permissions.role_permissions.find(
              (permission) => permission.area === "Technical Decisions",
            )?.create && (
              <div className="d-flex mb-2 mx-3">
                <Button
                  variant="primary"
                  className="w-100 rounded-0"
                  onClick={(e) => handleShowModal(e, {})}
                >
                  + Add Technical Decision
                </Button>
              </div>
            )}

            {/* Filter Button */}
            {/* <div className="d-flex mb-2 mx-3">
                <Button 
                    variant={filtering ? 'primary' : 'light'} 
                    className='w-100 rounded-0' 
                    onClick={handleFiltering}
                >
                    Filter <FilterListIcon />
                </Button>
                </div> */}

            {/* Reset Filters Button */}
            {/* <div className="d-flex mb-2 mx-3">
                <Button 
                    variant="warning" 
                    className='w-100 rounded-0' 
                    onClick={() => tableRef.current.onQueryChange({filters: [], search: ""})}
                >
                    Reset Filters
                </Button>
                </div> */}
          </Col>
        </Row>
        <ResponsiveMaterialTable
          title="Technical Decisions"
          columns={columns}
          tableRef={tableRef}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                tableRef.current &&
                tableRef.current.dataManager.columns.forEach((item) => {
                  tableRef.current.onFilterChange(item.tableData.id, "");
                }),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else if (filter.value instanceof Date) {
                  value = filter.value.toISOString().split("T")[0];
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });

              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
                archived: false,
              };

              axios
                .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
        />
        <NewTDModal show={showModal} handleClose={handleCloseModal} />
      </Container>
    )
  );
};

export default CommunityTechDecisionTable;
