import axios from "axios";
import qs from "qs";
import { useRef } from "react";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useInterval } from "hooks/useInterval";

export default function PolicyLettersTable({ archived }) {
  const tableRef = useRef();

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 600000); //10 min

  const getLatestDate = (policy) => {
    if (!policy.addendums) {
      return getLocalTime(policy.policy_date);
    } else {
      let addendums = policy?.addendums;
      addendums?.sort((a, b) => (a?.policy_date > b?.policy_date ? -1 : 1));
      let latest_addendum = addendums[0];
      if (latest_addendum?.policy_date > policy?.policy_date) {
        return getLocalTime(latest_addendum?.policy_date);
      } else {
        return getLocalTime(policy?.policy_date);
      }
    }
  };

  const getLocalTime = (time) => {
    if (!time) {
      return "Not Modified";
    }
    const newTime = new Date(time);
    return newTime.toLocaleDateString("en-US", { timeZone: "UTC" });
  };

  const getFileUrl = (fileName) => {
    return `${process.env.REACT_APP_DJANGO_ENDPOINT}file/serve_atypical_file/?path=static_html/policies/${fileName}`;
  };

  const columns = [
    {
      title: "No.",
      field: "policy_num",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) => {
        const paddedNum = rowData.policy_num.toString().padStart(3, "0");
        return <p className="mb-0">{paddedNum}</p>;
      },
    },
    {
      title: "Title",
      field: "policy_title",
      render: (rowData) => {
        if (rowData.addendums?.length > 0) {
          return (
            <>
              <p>
                <a
                  href={getFileUrl(rowData.filename)}
                  target="_blank"
                  className="text-bright-navy text-decoration-none"
                  rel="noreferrer"
                >
                  {rowData?.policy_title}
                </a>
              </p>
              <ul className="mb-0">
                {rowData.addendums.map((addendum, index) => (
                  <li key={index}>
                    <a
                      href={getFileUrl(addendum.filename)}
                      target="_blank"
                      className="text-bright-navy text-decoration-none"
                      rel="noreferrer"
                    >
                      Addendum #{addendum?.addendum_num} -{" "}
                      {addendum.policy_title}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          );
        } else {
          return (
            <p>
              <a
                href={getFileUrl(rowData.filename)}
                target="_blank"
                className="text-bright-navy text-decoration-none"
                rel="noreferrer"
              >
                {rowData?.policy_title}
              </a>
            </p>
          );
        }
      },
    },
    {
      title: "Date",
      field: "policy_date",
      cellStyle: {
        width: "30%",
        minWidth: "30%",
      },
      render: (rowData) => getLatestDate(rowData),
      customFilterAndSearch: (input, rowData) => {
        let matcher = new RegExp(input, "i");
        if (matcher.test(getLocalTime(rowData.policy_date))) {
          return true;
        }
      },
    },
  ];

  const options = {
    sorting: true,
    pageSize: 20,
  };

  return (
    <>
      <ResponsiveMaterialTable
        id={
          archived === true
            ? "inactive-policy-letters-table"
            : "active-policy-letters-table"
        }
        title={(archived === true ? "Inactive" : "Active") + " Policy Letters"}
        columns={columns}
        options={options}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });

            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy === "" ? "policy_num" : orderBy,
              orderDirection: orderDirection === "" ? "asc" : orderDirection,
              archived: archived,
            };

            axios
              .get(
                `${
                  process.env.REACT_APP_DJANGO_ENDPOINT
                }publish/policies/get_public_policies/?${qs.stringify(params, {
                  arrayFormat: "comma",
                })}`,
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
      />
    </>
  );
}
