import SaveIcon from "@mui/icons-material/Save";
import parse from "html-react-parser";
import { useState } from "react";
import { Button, Accordion } from "react-bootstrap";

import { useUpdateNistMutation } from "api/memberPortal/certReview/certReview.api";
import HTMLEditor from "components/UI/HTMLEditor";

import DraftResponseComments from "./DraftResponseComments";

const DraftResponseField = ({ canEditNist, certReview, handleItemClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});
  const [showCommentModal, setShowCommentModal] = useState(false);

  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = () => {
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...updatedNist,
      },
    });
    setIsEditing(false);
  };

  const copyAndSave = () => {
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        response: certReview?.draft,
      },
    });
  };

  if (!canEditNist) return null;

  return (
    <Accordion.Item eventKey="draft" className="border-bottom-dark">
      <Accordion.Header onClick={() => handleItemClick("draft")}>
        <h4>Draft Response</h4>
      </Accordion.Header>
      <Accordion.Body>
        {isEditing ? (
          <div className="clearfix mt-3 mx-3">
            <HTMLEditor
              name="draft"
              handleChange={(e) => setUpdatedNist({ draft: e.target.value })}
              defaultValue={certReview?.draft}
              setIsValid={() => {}}
              isValid={true}
            />
            <div className="d-flex justify-content-end">
              <Button className="my-2 mx-2" variant="secondary" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Button className="my-2" onClick={handleSave} data-cy="cert-review-edit-draft-response-save-button">
                <SaveIcon /> Save
              </Button>
            </div>
          </div>
        ) : (
          <>
            <p className="clearfix mt-3 mx-4">{certReview.draft && parse(certReview.draft)}</p>
            <div className="d-flex justify-content-end">
              <Button
                variant="warning"
                className="mb-2"
                onClick={() => setIsEditing(true)}
                data-cy="cert-review-edit-draft-response-button">
                Edit Draft
              </Button>
              {certReview?.draft && (
                <Button variant="secondary" className="mb-2 mx-2" onClick={() => setShowCommentModal(true)}>
                  Add Response
                </Button>
              )}
              {certReview?.draft &&
                certReview?.stid?.status !== "Approved" &&
                certReview?.stid?.status !== "Rejected - Revision Required" && (
                  <Button
                    onClick={copyAndSave}
                    className="mb-2"
                    data-cy="cert-review-copy-draft-to-official-response-button">
                    Copy to Official Response
                  </Button>
                )}
            </div>
            <DraftResponseComments
              certReview={certReview}
              showCommentModal={showCommentModal}
              setShowCommentModal={setShowCommentModal}
            />
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DraftResponseField;
