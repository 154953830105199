import { WarningRounded } from "@mui/icons-material";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function UnauthorizedView() {
  return (
    <div
      className="h-100 d-flex m-5"
      // style={{ margin: "10em 0" }}
    >
      <Container className="d-flex flex-column align-items-center">
        <WarningRounded
          sx={{
            width: "100px",
            height: "100px",
            strokeWidth: 0.6,
          }}
          stroke="#987931"
          color="warning"
        />
        <h1 className="center">Unauthorized</h1>
        <p>Sorry, you do not have the required permissions to view this page</p>
        <Link to="/"> Return Home </Link>
      </Container>
    </div>
  );
}
