import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Form, Col } from "react-bootstrap";

import {
  useGetAllNistStatusesQuery,
  useUpdateNistMutation,
} from "api/memberPortal/certReview/certReview.api";
import { getCurrentDate } from "utils/timeUtils";

const StatusEditableField = ({ value, canEditNist, certReview }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});

  const { data: statuses } = useGetAllNistStatusesQuery();
  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = (event) => {
    event.preventDefault();
    let data = {
      ...updatedNist,
    };

    let found = statuses?.find((element) => element.stid === updatedNist.stid);
    let statusName = found ? found.status : null;
    if (
      [
        "Rejected - Revision Required",
        "Approved",
        "Rejected, revisions needed",
      ]?.includes(statusName)
    ) {
      data["enddate"] = getCurrentDate();
    }
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...data,
      },
    });
    setIsEditing(false);
  };

  return canEditNist ? (
    isEditing ? (
      <Form className="mb-3" style={{ display: "flex", alignItems: "center" }}>
        <Col sm={12} md={8}>
          <Form.Group controlId="stid">
            <Form.Select
              name="stid"
              data-cy="nist-details-status-select"
              onChange={(e) =>
                setUpdatedNist({ ...updatedNist, stid: e.target.value })
              }
              defaultValue={certReview?.stid?.stid}
            >
              <option value="">Select a Status</option>
              {statuses.map((status) => (
                <option key={status.stid} value={status.stid}>
                  {status.status}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={12} md={4}>
          <span
            onClick={handleSave}
            style={{ cursor: "pointer" }}
            data-cy="nist-status-save"
          >
            <SaveIcon style={{ marginLeft: "8px" }} />
          </span>
        </Col>
      </Form>
    ) : (
      <p
        onClick={() => setIsEditing(true)}
        style={{ cursor: "pointer" }}
        data-cy="nist-details-status"
      >
        {value}
        <EditIcon style={{ marginLeft: "8px" }} />
      </p>
    )
  ) : (
    <p>{value}</p>
  );
};

export default StatusEditableField;
