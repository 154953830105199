import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import {
  Popover,
  OverlayTrigger,
  Tab,
  Tabs,
  Stack,
  Alert,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { addRole } from "../../app/role";
import { addUser } from "../../app/user";
import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";

import ResetPasswordModal from "./ResetPasswordModal";
//Need New Route or Fitler for table data
const AccountTable = ({
  handleShowModal,
  handleArchive,
  accountType,
  sidebarFilters,
  permissions,
  currentUser,
  updateRefs,
}) => {
  const [cookies, setCookie] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef();
  const inactiveTableRef = useRef();
  const [userStats, setUserStats] = useState({});
  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [alert, setAlert] = useState({});

  const getUserStats = async (id) => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_user_statistics_by_id/?user_id=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setUserStats(response.data);
      })
      .catch((error) => {
        setUserStats({});
      });
  };

  const createEmulatedLogin = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/login/create_emulated_login/`,
        {
          emulated_user: user_id,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        console.log("created emulated login");
      })
      .catch((error) => {
        console.log("error creating emulated login:", error);
      });
  };

  const emulateUser = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_user_token/?user_id=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then(async (response) => {
        const emulatedToken = response.data.token;
        await createEmulatedLogin(id);
        setCookie("old_auth_token", authToken, { path: "/" });
        setCookie("auth_token", emulatedToken, { path: "/" });
        const userData = await axios.get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${emulatedToken}` },
          },
        );
        dispatch(addUser(userData.data));
        const roleData = await axios.get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/getRole/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${emulatedToken}` },
          },
        );
        dispatch(addRole(roleData.data));
        navigate("/community/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange({ page: 0 });
    }
    if (inactiveTableRef.current) {
      inactiveTableRef.current.onQueryChange({ page: 0 });
    }
  }, [sidebarFilters, updateRefs]);

  // const showActions =
  //   permissions?.role_permissions?.find((permission) => permission.area === "Accounts")?.update ||
  //   permissions?.role_permissions?.find((permission) => permission.area === "Accounts")?.destroy ||
  //   permissions?.role_permissions?.find((permission) => permission.area === "Organization Members")
  //     ?.update ||
  //   permissions?.role_permissions?.find((permission) => permission.area === "Organization Members")
  //     ?.destroy ||
  //   currentUser?.user_role?.role_type === "NIAP";

  const columns = [
    {
      title: "Name",
      cellStyle: { textAlign: "center", position: "sticky", left: 0 },
      headerStyle: { position: "sticky", left: 0, zIndex: 99 },
      customSort: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <p className="fw-bold">Statistics</p>
                <p>User ID: {rowData?.id}</p>
                <p className="mb-0">
                  Created By:{" "}
                  {userStats?.created_by ? userStats.created_by : "No User Set"}
                </p>
                <p>
                  Created On:{" "}
                  {moment.utc(userStats?.created_on).format("MM/DD/YYYY")}
                </p>
                <p className="mb-0">
                  Modified By:{" "}
                  {userStats?.modified_by
                    ? userStats.modified_by
                    : "No User Set"}
                </p>
                <p>
                  Modified On:{" "}
                  {userStats?.modified_on
                    ? moment.utc(userStats?.modified_on).format("MM/DD/YYYY")
                    : "Not Modified"}
                </p>
                <p>
                  Last Login:{" "}
                  {userStats?.last_login
                    ? moment.utc(userStats?.last_login).format("MM/DD/YYYY")
                    : "Never Logged In"}
                </p>
                <p>Logged In Now: {userStats?.logged_in_now}</p>
                <p>Times Logged In: {userStats?.session_tally}</p>
                <p>Days Logged In: {userStats?.daily_tally}</p>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link" onClick={() => getUserStats(rowData.id)}>
            {rowData.first_name} {rowData.last_name}
          </Button>
        </OverlayTrigger>
      ),
    },
    {
      title: "Company",
      field: "company.name",
      cellStyle: { textAlign: "center" },
    },
    {
      title: "User Role",
      field: "user_role.role_name",
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Affiliation",
      field: "affiliation.svc",
      cellStyle: { textAlign: "center" },
    },
    { title: "Email", field: "email", cellStyle: { textAlign: "center" } },
    {
      title: "Phone",
      field: "phone_number",
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      headerStyle: { position: "sticky", right: 0 },
      cellStyle: { position: "sticky", right: 0 },
      hidden: permissions?.role_type !== "NIAP",
      render: (rowData) => {
        return rowData.id === currentUser?.id
          ? null
          : permissions?.role_type === "NIAP" && (
              <Stack className="text-center">
                <Button
                  variant="warning"
                  className="m-1 p-0"
                  onClick={(e) => handleShowModal(e, rowData)}
                >
                  Edit
                </Button>
                <Button
                  variant="secondary"
                  className="m-1 p-0"
                  onClick={(e) => {
                    setSelectedUser(rowData);
                    setShowResetPassModal(true);
                  }}
                >
                  Reset Password
                </Button>
                <Button
                  variant="secondary"
                  className="m-1 p-0"
                  onClick={(e) => {
                    emulateUser(rowData?.id);
                  }}
                  id={`emulate-user-button-${rowData.id}`}
                  data-cy="emulate-user-button"
                >
                  Emulate User
                </Button>
                <Button
                  variant={rowData.is_active ? "danger" : "success"}
                  className="m-1 p-0"
                  onClick={(e) => handleArchive(e, rowData)}
                >
                  {rowData.is_active ? "Deactivate" : "Activate"}
                </Button>
              </Stack>
            );
      },
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
      textAlign: "center",
    },
    pageSize: 20,
  };

  const getTitle = () => {
    if (accountType !== "All") {
      return accountType.toUpperCase() + " MANAGER";
    } else {
      return "ACCOUNT MANAGER";
    }
  };

  return (
    <>
      <ResetPasswordModal
        user={selectedUser}
        show={showResetPassModal}
        handleClose={() => setShowResetPassModal(false)}
        setAlert={setAlert}
      />
      {alert?.message && (
        <Alert variant={alert.type} onClose={() => setAlert({})} dismissible>
          {alert.message}
        </Alert>
      )}
      <Tabs
        id="account-manager-active-tabs"
        className="mb-3"
        mountOnEnter
        animation="true"
      >
        <Tab eventKey="active" title="Active">
          <ResponsiveMaterialTable
            title={getTitle()}
            columns={columns}
            tableRef={tableRef}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  ...sidebarFilters,
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  is_active: "true",
                };
                if (accountType !== "All") {
                  params["role_type"] = accountType;
                }
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/?${qs.stringify(
                      params,
                      {
                        arrayFormat: "comma",
                      },
                    )}`,
                    {
                      withCredentials: true,
                      headers: { Authorization: `Token ${authToken}` },
                    },
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
            key={(rowData) => rowData.id}
          />
        </Tab>
        <Tab eventKey="inactive" title="Inactive">
          <ResponsiveMaterialTable
            title={getTitle()}
            columns={columns}
            tableRef={inactiveTableRef}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  ...sidebarFilters,
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  is_active: "false",
                };
                if (accountType !== "All") {
                  params["role_type"] = accountType;
                }
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/?${qs.stringify(
                      params,
                      {
                        arrayFormat: "comma",
                      },
                    )}`,
                    {
                      withCredentials: true,
                      headers: { Authorization: `Token ${authToken}` },
                    },
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
            key={(rowData) => rowData.id}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default AccountTable;
