import parse from "html-react-parser";
import moment from "moment";
import { useState } from "react";
import { Accordion, Container, Row, Col, Button } from "react-bootstrap";

import { useGetNistHistoryQuery } from "api/memberPortal/certReview/certReview.api";

const NistHistory = ({ crid, handleItemClick }) => {
  const [expandedFields, setExpandedFields] = useState({});
  // Fetch history data, skip if no crid is provided
  const { data: nistHistory = [] } = useGetNistHistoryQuery(crid, {
    skip: !crid,
  });

  const toggleField = (date, field) => {
    setExpandedFields((prev) => ({
      ...prev,
      [`${date}-${field}`]: !prev[`${date}-${field}`],
    }));
  };

  // Check if the content is long enough to be truncated
  const isLongContent = (content) => {
    return content.split("\n").length > 3 || content.length > 200;
  };

  return (
    <Accordion.Item eventKey="history" data-cy="nist-history-accordion">
      <Accordion.Header onClick={() => handleItemClick("comments")}>
        <h4>History</h4>
      </Accordion.Header>
      <Accordion.Body>
        {/* Map through history records, newest first */}
        {nistHistory.map((history) => (
          <Container key={history.date} className="border mb-3 p-3">
            <Row>
              <Col className="text-start">
                <h5>Updated By: {history?.user}</h5>
              </Col>
              <Col className="text-end">
                <h5>
                  Updated On:{" "}
                  {history?.date
                    ? moment(history?.date).format("YYYY.MM.DD [at] HH:mm")
                    : "N/A"}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col data-cy="nist-history-changes">
                {Object.entries(history?.changes || {}).map(
                  ([field, values]) => (
                    <div key={field} className="mb-1 mx-4">
                      <strong>{field}: </strong>
                      {isLongContent(values) ? (
                        <div>
                          <div
                            className={`ms-3 ${!expandedFields[`${history.date}-${field}`] ? "text-truncate" : ""}`}
                            style={{
                              maxHeight: expandedFields[
                                `${history.date}-${field}`
                              ]
                                ? "none"
                                : "100px",
                            }}
                          >
                            {[
                              "Official Response",
                              "Draft Response",
                              "Justification",
                              "Initial Comments",
                            ].includes(field) ? (
                              parse(values)
                            ) : (
                              <div key={field} className="mb-1 mx-4">
                                <strong>{field}: </strong>
                                <div className="ms-3">{values || "None"}</div>
                              </div>
                            )}
                          </div>
                          <Button
                            variant="link"
                            onClick={() => toggleField(history.date, field)}
                            className="p-0 mt-1"
                          >
                            {expandedFields[`${history.date}-${field}`]
                              ? "Show Less"
                              : "Show More"}
                          </Button>
                        </div>
                      ) : (
                        <div className="ms-3">{parse(values)}</div>
                      )}
                    </div>
                  ),
                )}
              </Col>
            </Row>
          </Container>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default NistHistory;
