import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useCallback, useEffect, useRef } from "react";
import { Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";
import { calculateActiveDaysInProgress } from "utils/timeUtils.js";

import NISTForm from "../NISTForm/index.js";

import Filter from "./Filter.js";

const NISTTable = () => {
  const { authToken, permissions, currentUser } = useAuth();
  const navigate = useNavigate();
  const tableRef = useRef();
  const [progressPointSevens, setProgressPointSevens] = useState({});
  const [agents, setAgents] = useState({});
  const [filterOpen, setFilterOpen] = useState(true);
  const [sidebarFilters, setSidebarFilters] = useState({
    status:
      "Pending Review,In Review,Rejected - Revision Required,Pending Final Review",
  });
  const [alert, setAlert] = useState({ message: "", type: "" });

  const [showForm, setShowForm] = useState(false);
  const [isNCRTTeamMember, setIsNCRTTeamMember] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [sidebarFilters]);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const refetch = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const checkIfTeamMember = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ncrt_team_member/?user=${currentUser.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setIsNCRTTeamMember(true);
        } else {
          setIsNCRTTeamMember(false);
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));
  }, [currentUser.id]);

  const loadAgents = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/get_grouped_agents/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setAgents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const loadProgressPoint7 = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/get_grouped_progress_point_7/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProgressPointSevens(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadAgents();
    loadProgressPoint7();
  }, []);

  useEffect(() => {
    checkIfTeamMember();
  }, [checkIfTeamMember]);

  useInterval(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    loadAgents();
    loadProgressPoint7();
  }, 300000); //5 min

  const columns = [
    { title: "VID", field: "pid.v_id" },
    { title: "Status", field: "stid.status" },
    {
      title: "Submitted",
      field: "rcvd",
      render: (rowData) =>
        rowData.rcvd
          ? moment.utc(rowData.rcvd).format("YYYY.MM.DD")
          : rowData.rcvd,
    },
    {
      title: "Days in Progress",
      field: "entrydate",
      render: (rowData) => {
        const daysInProgress = calculateActiveDaysInProgress(rowData.pid);
        const displayText = daysInProgress > 0 ? daysInProgress : "Pending";

        return <p className="mb-0">{displayText}</p>;
      },
    },
    {
      title: "Progress Point 7",
      render: (rowData) =>
        progressPointSevens[rowData.pid.product_id]
          ? moment
              .utc(progressPointSevens[rowData.pid.product_id])
              .format("YYYY.MM.DD")
          : "",
    },
    {
      title: "Agent(s)",
      field: "editorext",
      render: (rowData) =>
        agents[rowData.crid]
          ? agents[rowData.crid]?.map((agent, idx) => (
              <p key={idx} className="p-0 m-0">
                {agent.first_name} {agent.last_name}
              </p>
            ))
          : "---",
      sorting: false,
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 20,
    search: false,
  };

  return (
    <>
      <NISTForm
        show={showForm}
        onHide={handleHideForm}
        refetch={refetch}
        setAlert={setAlert}
      />
      <Container fluid className="mt-3">
        <Row className="mt-3 justify-content-end">
          {alert.message !== "" && (
            <Col>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          )}
          {(permissions.role_type === "NIAP" || isNCRTTeamMember) && (
            <Col sm={12} md={2}>
              <Button
                variant="success"
                onClick={handleShowForm}
                className="w-100"
              >
                <AddIcon />
                New Certificate Review
              </Button>
            </Col>
          )}
          <Col
            sm={12}
            md={2}
            className="d-flex justify-content-end align-content-center mb-2"
          >
            <Button
              variant="link rounded-pill"
              style={{ backgroundColor: "#dedede", maxHeight: 40 }}
              onClick={changeFilter}
            >
              {filterOpen && (
                <>
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                  &nbsp; Close Filter &nbsp;
                  <KeyboardArrowRightIcon />
                  <KeyboardArrowRightIcon />
                </>
              )}
              {!filterOpen && (
                <>
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                  &nbsp; Open Filter &nbsp;
                  <KeyboardArrowLeftIcon />
                  <KeyboardArrowLeftIcon />
                </>
              )}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
            <ResponsiveMaterialTable
              title={"NIST Certificate Reviews"}
              columns={columns}
              tableRef={tableRef}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    ...sidebarFilters,
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  if (permissions.role_type === "Lab") {
                    params["local_only"] = true;
                  }
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/?${qs.stringify(
                        params,
                        {
                          arrayFormat: "comma",
                        },
                      )}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              options={options}
              onRowClick={(e, rowData) => {
                navigate(`details/${rowData.crid}`);
              }}
            />
          </Col>
          {filterOpen && (
            <Col xs={10} md={3}>
              <Filter filters={sidebarFilters} setFilters={setSidebarFilters} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default NISTTable;
