import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import parse from "html-react-parser";
import { useState } from "react";
import { Button, Accordion } from "react-bootstrap";

import { useUpdateNistMutation } from "api/memberPortal/certReview/certReview.api";
import HTMLEditor from "components/UI/HTMLEditor";

const InitialCommentEditableField = ({ canEditNist, certReview, handleItemClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedNist, setUpdatedNist] = useState({});

  const [triggerUpdateNist] = useUpdateNistMutation();

  const handleSave = () => {
    triggerUpdateNist({
      nistId: certReview.crid,
      body: {
        ...updatedNist,
      },
    });
    setIsEditing(false);
  };

  return (
    <Accordion.Item eventKey="comments" className="border-bottom-dark">
      <Accordion.Header onClick={() => handleItemClick("comments")}>
        <h4>Initial Comments</h4>
      </Accordion.Header>
      <Accordion.Body>
        {canEditNist ? (
          isEditing ? (
            <div className="clearfix mt-3 mx-3">
              <HTMLEditor
                name="blurba"
                handleChange={(e) => setUpdatedNist({ blurba: e.target.value })}
                defaultValue={certReview?.blurba}
                setIsValid={() => {}}
                isValid={true}
              />
              <div className="d-flex justify-content-end">
                <Button className="my-2 mx-2" variant="secondary" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
                <Button className="my-2" onClick={handleSave}>
                  <SaveIcon /> Save
                </Button>
              </div>
            </div>
          ) : (
            <>
              <span style={{ cursor: "pointer", float: "right" }} className="mx-3" onClick={() => setIsEditing(true)}>
                <EditIcon />
              </span>
              <p className="clearfix mt-3 mx-4">{certReview.blurba && parse(certReview.blurba)}</p>
            </>
          )
        ) : (
          <p>{certReview.blurba && parse(certReview.blurba)}</p>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default InitialCommentEditableField;
