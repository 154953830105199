import axios from "axios";

export const updateProductProgressPoint = async (
  product_id,
  progress_point,
  status,
  cookies,
) => {
  let csrfToken = cookies["csrfToken"] || cookies["csrftoken"];
  let authToken = cookies["authToken"] || cookies["auth_token"];
  if (product_id && progress_point && status && cookies) {
    try {
      let data = {
        product: product_id,
        progress_point: progress_point,
        status: status,
      };

      if (
        (progress_point === 1 || progress_point === 2) &&
        status === "In Progress"
      ) {
        data["new_est_date"] = true;
      }

      if (
        (progress_point === 1 || progress_point === 2) &&
        status === "Completed"
      ) {
        data["new_submission_date"] = true;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        data,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error("error updating product progress point", error);
      return {};
    }
  }
};
