import { Outlet } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

const AuthenticatedRoute = ({
  requiredPermissions,
}: {
  requiredPermissions?: any;
}) => {
  const { permissions, authToken } = useAuth();
  const isUnauthorized =
    !permissions?.role_type ||
    permissions?.role_type === "External" ||
    !authToken;

  console.log("requiredPermissions", requiredPermissions);
  console.log("permissions", permissions);
  console.log("isUnauthorized", isUnauthorized);

  if (isUnauthorized) return <UnauthorizedView />;

  return <Outlet />;
};
export default AuthenticatedRoute;
