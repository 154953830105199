import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

const FileActions = ({ project }) => {
  return (
    <MenuItem
      component={Link}
      to={`/community/products/details/${project.product_id}/product_files`}
    >
      <ListItemIcon>
        <FileCopyIcon color="action" />
      </ListItemIcon>
      <ListItemText> Files </ListItemText>
    </MenuItem>
  );
};

export default FileActions;
