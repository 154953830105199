import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "../Products.css";
import "./ProductDetails.css";

import Notes from "components/ProtectionProfiles/PPManager/Notes";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";
import { getCurrentUTCDate } from "utils/timeUtils";

import FileUpload from "../FileUpload";

import CertificateModal from "./CertificateModal";
import PCLReviewDetails from "./PCLReviewDetails";
import NonEditableAccordion from "./ProductDetailsAccordions/NonEditableAccordion";
import PostingAccordion from "./ProductDetailsAccordions/PostingAccordion";
import ProjectDetailsAccordion from "./ProductDetailsAccordions/ProjectDetailsAccordion";
import NiapPostingHeader from "./ProductDetailsHeaders/NiapPostingHeader";
import PostingHeader from "./ProductDetailsHeaders/PostingHeader";
import ProductDetailsHeaderInfo from "./ProductDetailsHeaders/ProductDetailsHeaderInfo";
import WithdrawalTerminationFeedbackHeader from "./ProductDetailsHeaders/WithdrawalTerminationFeedbackHeader";
import PostingSideInfo from "./ProductDetailSideInfo/PostingSideInfo";
import SideInfo from "./ProductDetailSideInfo/SideInfo";
import NiapClearPostingVerifier from "./ProductDetailsSubheaderDetails/NiapClearPostingVerifier";
import PostingSubheaderDetails from "./ProductDetailsSubheaderDetails/PostingSubheaderDetails";
import ProductDetailsSubheader from "./ProductDetailsSubheaderDetails/ProductDetailsSubheader";
import ProductKickoffModal from "./ProductKickoffModal";
import InitiateProgressModal from "./ProgressPoints/ProgressPointModals/InitiateProgressModal";
import ScheduleMeetingModal from "./ProgressPoints/ProgressPointModals/ProgressPoint2/ScheduleMeetingModal.js";
import ProgressPointModal345 from "./ProgressPoints/ProgressPointModals/ProgressPoint345/ProgressPointModal345";
import ProgressPointEditModal7 from "./ProgressPoints/ProgressPointModals/ProgressPoint67/ProgressPointEditModal7.jsx";
import ProgressPointModal6 from "./ProgressPoints/ProgressPointModals/ProgressPoint67/ProgressPointModal6.jsx";
import ValidatorCheckoutApprovalModal from "./ProgressPoints/ProgressPointModals/ProgressPoint67/ValidatorCheckoutApprovalModal.js";
import ValidatorCheckoutRejectionModal from "./ProgressPoints/ProgressPointModals/ProgressPoint67/ValidatorCheckoutRejectionModal";
import ValidatorRecommendPostingModal from "./ProgressPoints/ProgressPointModals/ProgressPoint67/ValidatorRecommendPostingModal";
import ProgressPointEditModal8 from "./ProgressPoints/ProgressPointModals/ProgressPoint8/ProgressPointEditModal8.jsx";
import ProgressPoints from "./ProgressPoints/ProgressPoints";
import ValidatorKickoffModal from "./ValidatorKickoffModal";

const CheckProductPermDetails = ({ setOverlayActive, setOverlayText }) => {
  const { authToken, permissions } = useAuth();
  const { id } = useParams();
  const [hasPerm, setHasPerm] = useState();

  useEffect(() => {
    let role = permissions?.role_type;
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/has_permissions_to_view_product/?role=${role}&product_id=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          if (response.data?.authorized === false) {
            setHasPerm(false);
          } else {
            setHasPerm(true);
          }
        })
        .catch(() => {
          setHasPerm(false);
        });
    }
  }, [authToken, id, permissions]);

  if (
    (permissions?.role_permissions?.find(
      (permission) => permission.area === "Project",
    )?.local_only &&
      hasPerm === false) ||
    permissions?.role_type === "Vendor"
  ) {
    return <UnauthorizedView />;
  } else if (
    permissions?.role_permissions?.find(
      (permission) => permission.area === "Project",
    )?.local_only &&
    hasPerm === true
  ) {
    return (
      <ProductDetails
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else if (hasPerm === true) {
    //this would be for NIAP and Validators
    return (
      <ProductDetails
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  }
  return <div></div>;
};

function ProductDetails({ setOverlayActive, setOverlayText }) {
  const { authToken, csrfToken, currentUser, permissions } = useAuth();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [projpps, setProjpps] = useState([]);

  const [show, setShow] = useState(false);
  const [showKickoffModal, setShowKickoffModal] = useState(false);
  const [showValidatorKickoffModal, setShowValidatorKickoffModal] =
    useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isValidator, setIsValidator] = useState({});
  const [meeting, setMeeting] = useState({});
  const [refetchNotes, setRefetchNotes] = useState(false);
  const [
    showValidatorRecommendPostingModal,
    setShowValidatorRecommendPostingModal,
  ] = useState(false);
  const [showPPModal345, setShowPPModal345] = useState(false);
  const [showPPModal6, setShowPPModal6] = useState(false);
  const [showPPModal7, setShowPPModal7] = useState(false);
  const [showPPModal8, setShowPPModal8] = useState(false);
  const [showInitiateProgressModel, setShowInitiateProgressModel] =
    useState(false);
  const [progressPointModalReadOnly, setProgressPointModalReadOnly] =
    useState(false);
  const [progressPointEdit, setProgressPointEdit] = useState(false);
  const [progressPoints, setProgressPoints] = useState([]);
  const [alert, setAlert] = useState({});
  const [currentPP, setCurrentPP] = useState({});
  const [kickoffAlert, setKickoffAlert] = useState({});
  const [updateSidebarFiles, setUpdateSidebarFiles] = useState(0);
  const [assignedOrg, setAssignedOrg] = useState(false);
  const [showValidatorCheckoutModal, setShowValidatorCheckoutModal] =
    useState(false);
  const [
    showValidatorApproveCheckoutModal,
    setShowValidatorApproveCheckoutModal,
  ] = useState(false);
  const [validationReport, setValidationReport] = useState({});
  const [finalDraftValidationReport, setFinalDraftValidationReport] = useState(
    {},
  );
  const [readyToPostChecks, setReadyToPostChecks] = useState([]);
  const [signedCheckIn, setSignedCheckIn] = useState({});
  const [labSignedCheckout, setLabSignedCheckout] = useState({});
  const [vendorSignedCheckout, setVendorSignedCheckout] = useState({});
  const [securityTarget, setSecurityTarget] = useState({});
  const [certificate, setCertificate] = useState({});
  const [assuranceActivityReport, setAssuranceActivityReport] = useState({});
  const [showCertificate, setShowCertificate] = useState(false);
  const [rejectionType, setRejectionType] = useState("");
  const [validationTeam, setValidationTeam] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [nistReview, setNistReview] = useState({});

  const handleCloseProgressPointModal = (product, action) => {
    setShowPPModal345(false);
    setShowPPModal6(false);
    setShowPPModal7(false);
    setShowPPModal8(false);
    setProgressPointModalReadOnly(false);
    setProgressPointEdit(false);
    pullFiles();
    if (product) {
      if (action) {
        if (action === "approved") {
          setAlert({
            variant: "success",
            message: "Progress Point Successfully Approved",
          });
        } else if (action === "rejected") {
          setAlert({
            variant: "dark",
            message: "Progress Point Successfully Rejected",
          });
        } else if (action === "submitting") {
          setAlert({
            variant: "success",
            message: "Progress Point Successfully Submitted",
          });
        } else {
          setAlert({
            variant: "success",
            message: "Progress Point Successfully Updated",
          });
        }
      } else {
        setAlert({
          variant: "success",
          message: "Progress Point Successfully Updated",
        });
      }
    }
  };

  const handleCloseInitiateProgressModal = (action) => {
    setShowInitiateProgressModel(false);
    if (action === "initiate") {
      setAlert({
        variant: "success",
        message: "Progress Point Successfully Initiated",
      });
    } else {
      setAlert({
        variant: "success",
        message: "Progress Point Est. Completion Date Saved",
      });
    }
  };

  const handleOpenKickoffModal = () => {
    setShowKickoffModal(true);
  };

  const handleOpenValidatorKickoffModal = () => {
    setShowValidatorKickoffModal(true);
  };

  const handleCloseValidatorKickoffModal = () => {
    setShowValidatorKickoffModal(false);
  };

  const handleCloseProductKickoffModal = (product, action) => {
    if (product) {
      if (action === "rejected") {
        setKickoffAlert({
          variant: "success",
          message: "Successfully Rejected Kickoff Recommendation",
        });
      } else {
        setKickoffAlert({
          variant: "success",
          message: "Successfully Approved Kickoff Recommendation",
        });
        getProgressPoints();
      }
    }
    setShowKickoffModal(false);
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const fetchBlankCertification = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/generate_document/?document_name=Draft or Final Certificate&product_id=${product?.product_id}&cert_review_option=final`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
          responseType: "blob",
        },
      )
      .then((response) => {
        let url = "";
        if (response.data.size > 0) {
          url = URL.createObjectURL(response.data);
        }
        const link = document.createElement("a");
        link.href = url;

        let fileName = `VID${product?.v_id}-FINAL CERT`;

        link.setAttribute("download", fileName);

        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the file

        window.URL.revokeObjectURL(url); // Clean up by revoking the blob URL
        link.remove(); // Clean up by removing the link
      })
      .catch((error) => {
        console.log("error fetching blank certificate", error);
      });
  };

  const getCertificate = () => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_certificate/?file_type=product&file_type_id=${id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setCertificate(response.data);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getCertificate();
  }, [id, product.product_id]);

  function refreshPage() {
    window.location.reload(false);
  }

  const clearRecommendationNote = () => {
    if (readyToPostChecks.some((check) => Object.values(check)[0] === false)) {
      const uncheckedPosts = readyToPostChecks
        .filter((check) => Object.values(check)[0] === false)
        .map((check) => Object.keys(check)[0]);

      let note = "<ul>";
      const nameMap = {
        "ready-to-post-0": "Product Description",
        "ready-to-post-1": "Security Evaluation Summary",
        "ready-to-post-2": "Environmental Strengths",
        "ready-to-post-3": "Evaluation Configuration",
        "ready-to-post-vendor-info": "Vendor Information",
        "ready-to-post-cctl-info": "CCTL Information",
        "ready-to-post-public-files": "Public Files",
      };

      uncheckedPosts.forEach((post) => {
        note += `<li>${nameMap[post]}</li>`;
      });

      note += "</ul>";

      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
          {
            note: note,
            internal_only: false,
            note_type: "product",
            note_type_id: product.product_id,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setRefetchNotes(response.data.note_id);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (permissions.role_type === "NIAP") {
      const updatedChecks = [
        {
          "ready-to-post-0": true,
        },
        {
          "ready-to-post-1": true,
        },
        {
          "ready-to-post-2": true,
        },
        {
          "ready-to-post-3": true,
        },
        {
          "ready-to-post-vendor-info": true,
        },
        {
          "ready-to-post-cctl-info": true,
        },
        {
          "ready-to-post-public-files": true,
        },
      ];
      setReadyToPostChecks(updatedChecks);
    }
  }, []);

  //updates readyToPost state with checked value
  const handleReadyToPostCheck = (dictChecks) => {
    const updatedChecks = [...readyToPostChecks];
    const key = Object.keys(dictChecks)[0];
    const index = updatedChecks.findIndex((obj) => Object.keys(obj)[0] === key);
    if (index !== -1) {
      updatedChecks[index] = dictChecks;
    } else {
      updatedChecks.push(dictChecks);
    }
    setReadyToPostChecks(updatedChecks);
  };

  const handleCertifyProduct = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          product: product?.product_id,
          progress_point: 8,
          status: "Completed",
          new_submission_date: true,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const note = "Product Approved";
        const submittedBy = currentUser.id;
        const noteType = "Product";
        const noteTypeId = product.product_id;
        createNote(note, submittedBy, noteType, noteTypeId);
        if (response.data.status === "Update Already Exists") {
          refreshPage();
        } else {
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
              {
                status: "Certified",
                certification_status: "Certified",
                certification_date:
                  product?.certification_date !== null
                    ? product?.certification_date
                    : new Date(
                        new Date().setMinutes(
                          new Date().getMinutes() -
                            new Date().getTimezoneOffset(),
                        ),
                      ),
                assurance_maintenance_date:
                  product?.assurance_maintenance_date !== null
                    ? product?.assurance_maintenance_date
                    : moment.utc().add(2, "years").format("YYYY-MM-DD"),
                posted_date: new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() - new Date().getTimezoneOffset(),
                  ),
                ),
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then(() => {
              getProgressPoints();
              loadProject();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => console.log(error));
  };

  const createNote = async (note, submittedBy, noteType, noteTypeId) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}note/`,
        {
          note: note,
          submitted_by: submittedBy,
          note_type: noteType,
          note_type_id: noteTypeId,
          submitted_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setRefetchNotes(response?.data?.note_id);
      })
      .catch((error) => console.log("Unable to add note: " + error));
  };

  const loadValidators = useCallback(() => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_validators/?product=${id}&approved=true`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => setValidationTeam(response.data))
        .catch((error) => console.log(error));
    }
  }, []);

  const loadProject = useCallback(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${id}/`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setProduct(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authToken, id]);

  const IsMemberOfLab = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        //check if user org is assigned lab
        if (
          response.data.name === product.assigned_lab?.name &&
          permissions.role_type === "Lab"
        ) {
          setAssignedOrg(true);
        } else {
          setAssignedOrg(false);
        }
      })
      .catch((error) => console.log(error));
  }, [product?.assigned_lab?.org_id]);

  useEffect(() => {
    if (product?.assigned_lab?.org_id) {
      IsMemberOfLab();
    }
  }, [product?.assigned_lab?.org_id]);

  const fetchIsValidator = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/is_validator/?product=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setIsValidator(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchIsValidator();
    }
  }, [id]);

  const fetchProjectMaintenance = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/get_maintenance_by_product?product_id=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setMaintenances(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const fetchProductPP = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_serialized_project_pp?product=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchProductPP();
      fetchProjectMaintenance();
      setKickoffAlert({});
    }
  }, [id]);

  useInterval(() => {
    fetchProductPP();
  }, 180000); //3 min

  useEffect(() => {
    document.title = "NIAP Community - Product Details";
  }, []);

  const pullFiles = useCallback(() => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=product&file_type_id=${id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setFiles(response.data.filter((f) => f?.active === true));
          // This will return an object with keys of file_display_name
          // and values of an array of all files with that file_display_name
          const files = Object.groupBy(
            response.data,
            (file) => file.file_display_name,
          );

          const getLatestFile = (fileDisplayName) => {
            return (
              // In this function we access the array corresponding to the file_display_name
              // and reduce it down to a single value of the most recently uploaded file
              files[fileDisplayName]?.reduce((mostRecentFile, fileToCheck) => {
                // if we don't have an uploaded_on date to compare to we are comparing to the epoch
                return moment(fileToCheck.uploaded_on).isAfter(
                  moment(mostRecentFile.uploaded_on || 0),
                )
                  ? fileToCheck
                  : mostRecentFile;
                // if we don't have a valid fileDisplayName we return an empty object
              }, {}) || {}
            );
          };

          setValidationReport(getLatestFile("Validation Report"));
          setFinalDraftValidationReport(
            getLatestFile("Final Draft Validation Report"),
          );
          setSecurityTarget(getLatestFile("Security Target"));
          setAssuranceActivityReport(
            getLatestFile("Assurance Activity Report (AAR)"),
          );
          setSignedCheckIn(getLatestFile("Vendor Signed Check In Package"));
          setLabSignedCheckout(getLatestFile("CCTL Signed Check Out Package"));
          setVendorSignedCheckout(
            getLatestFile("Vendor Signed Check Out Package"),
          );
        })
        .catch(() => {
          console.log("Error getting files");
        });
    }
  }, [authToken, id]);

  /*
    Requests validators amend checkout package by setting PP6 status to "In Progress". 
    PP7 status stays the same and progress point counter is updated
  */
  const amendedPackageNeeded = () => {
    let resubmissionCounter = progressPoints[5]?.resubmission_counter;
    if (resubmissionCounter === null) {
      resubmissionCounter = 0;
    } else {
      resubmissionCounter += 1;
    }
    const note = "Product Rejected";
    const submittedBy = currentUser.id;
    const noteType = "Product";
    const noteTypeId = product?.product_id;
    createNote(note, submittedBy, noteType, noteTypeId);
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
        {
          product: product?.product_id,
          progress_point: 6,
          status: "Amended Package Needed",
          resubmission_counter: resubmissionCounter,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
            {
              product: product?.product_id,
              progress_point: 7,
              resubmission_counter: resubmissionCounter,
              status: "Awaiting Changes",
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then(() => {
            getProgressPoints();
            setAlert({
              variant: "success",
              message: "Progress Point Successfully Released To Labs",
            });
          })
          .catch((error) => {
            console.log("error updating progress point", error);
          });
      })
      .catch((error) => {
        console.log("error updating progress point", error);
      });
  };

  const getMeeting = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}top/checkin-meeting/get_meeting_by_pid/?pid=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          if (response.data !== meeting) {
            setMeeting(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authToken, product.product_id]);

  const getProgressPoints = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/progress_points_by_pid/?pid=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setProgressPoints(
            response.data.sort((a, b) =>
              a.progress_point > b.progress_point ? 1 : -1,
            ),
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [authToken, product?.product_id]);

  const updateProgress = () => {
    let progress_id = currentPP?.prog_id;
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/${progress_id}/`,
        {
          status: "Completed",
          creator: currentUser.id,
          created_on: getCurrentUTCDate(),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status === "Update Already Exists") {
          refreshPage();
        } else if (
          response.data?.progress_point === 2 &&
          response?.data.status === "Complete"
        ) {
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
              {
                status: "In Progress",
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then(() => {
              loadProject();
              getProgressPoints();
              handleCloseProgressPointModal();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          getProgressPoints();
          handleCloseProgressPointModal();
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getMeeting();
  }, [getMeeting]);

  useInterval(() => {
    getMeeting();
  }, 180000); //3 min

  const updateFiles = () => {
    setShow(false);
    pullFiles();
    setUpdateSidebarFiles(updateSidebarFiles + 1);
  };

  useEffect(() => {
    getProgressPoints();
  }, [getProgressPoints]);

  useInterval(() => {
    getProgressPoints();
  }, 60000); //1 min

  useEffect(() => {
    loadProject();
  }, [loadProject]);

  useInterval(() => {
    loadProject();
  }, 60000); //1 min

  useEffect(() => {
    loadValidators();
  }, [loadValidators]);

  useInterval(() => {
    loadValidators();
  }, 180000); //3 min

  useEffect(() => {
    pullFiles();
  }, [pullFiles]);

  useInterval(() => {
    pullFiles();
  }, 180000); //3 min

  const getEditButton = (pp) => {
    if (
      permissions?.role_type === "Lab" &&
      (product?.status === "Certified" ||
        product?.status === "In Assurance Maintenance")
    ) {
      return <></>;
    }
    switch (pp?.progress_point) {
      case 2:
        if (
          Object.keys(meeting).length > 0 &&
          permissions?.role_type === "NIAP"
        ) {
          return (
            <EditIcon
              style={{ width: "15px !important", height: "15px !important" }}
              className="edit-button clickable"
              onClick={() => {
                setProgressPointEdit(true);
                setShowScheduleModal(true);
              }}
            />
          );
        } else {
          return <></>;
        }
      case 3:
      case 4:
      case 5:
        if (
          permissions?.role_type === "Lab" ||
          permissions?.role_type === "NIAP"
        ) {
          return (
            <EditIcon
              style={{ width: "15px !important", height: "15px !important" }}
              className="edit-button clickable"
              onClick={() => {
                setCurrentPP(pp);
                setProgressPointEdit(true);
                setShowPPModal345(true);
              }}
            />
          );
        } else {
          return <></>;
        }
      case 6:
        if (
          permissions?.role_type === "Lab" ||
          permissions?.role_type === "NIAP"
        ) {
          return (
            <EditIcon
              style={{ width: "15px !important", height: "15px !important" }}
              className="edit-button clickable"
              onClick={() => {
                setCurrentPP(pp);
                setProgressPointEdit(true);
                setShowPPModal6(true);
              }}
            />
          );
        } else {
          return <></>;
        }
      case 7:
        if (
          (permissions?.role_type === "Validator" &&
            isValidator?.is_validator) ||
          permissions?.role_type === "NIAP"
        ) {
          return (
            <EditIcon
              style={{ width: "15px !important", height: "15px !important" }}
              className="edit-button clickable"
              onClick={() => {
                setCurrentPP(pp);
                setProgressPointEdit(true);
                setShowPPModal7(true);
              }}
            />
          );
        } else {
          return <></>;
        }
      case 8:
        if (permissions?.role_type === "NIAP") {
          return (
            <EditIcon
              style={{ width: "15px !important", height: "15px !important" }}
              className="edit-button clickable"
              onClick={() => {
                setCurrentPP(pp);
                setProgressPointEdit(true);
                setShowPPModal8(true);
              }}
            />
          );
        } else {
          return <></>;
        }
      default:
        return <></>;
    }
  };

  const showButtons = (pp) => {
    switch (pp?.status) {
      case "Est. Completion Date Set":
      case "Not Started":
        switch (pp?.progress_point) {
          case 2:
            if (
              Object.keys(meeting).length === 0 &&
              permissions?.role_type === "NIAP"
            ) {
              return (
                <Button
                  size="sm"
                  variant="warning"
                  className="rounded-pill"
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowScheduleModal(true);
                  }}
                >
                  Schedule Meeting
                </Button>
              );
            } else {
              return <></>;
            }
          case 3:
          case 4:
          case 5:
            if (
              progressPoints[1]?.status === "Completed" &&
              permissions?.role_type === "Lab"
            ) {
              return (
                <Button
                  size="sm"
                  variant="warning"
                  className="rounded-pill small-font"
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowPPModal345(true);
                  }}
                >
                  Mark In-Progress
                </Button>
              );
            } else {
              return <></>;
            }
          case 6:
            if (
              progressPoints[2]?.status === "Completed" &&
              progressPoints[3]?.status === "Completed" &&
              progressPoints[4]?.status === "Completed" &&
              permissions?.role_type === "Lab"
            ) {
              return (
                <Button
                  size="sm"
                  variant="warning"
                  className="rounded-pill small-font"
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowInitiateProgressModel(true);
                  }}
                >
                  Initiate CheckOut
                </Button>
              );
            } else {
              return <></>;
            }
          default:
            return;
        }
      case "In Progress":
        switch (pp?.progress_point) {
          case 2:
            if (
              Object.keys(meeting).length === 0 &&
              permissions?.role_type === "NIAP"
            ) {
              return (
                <Button
                  size="sm"
                  variant="warning"
                  className="rounded-pill"
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowScheduleModal(true);
                  }}
                >
                  Schedule Meeting
                </Button>
              );
            } else {
              return <></>;
            }
          case 3:
          case 4:
          case 5:
            if (permissions?.role_type === "Lab") {
              return (
                <Button
                  size="sm"
                  variant="success"
                  className="rounded-pill small-font"
                  onClick={() => {
                    setCurrentPP(pp);
                    setProgressPointModalReadOnly(true);
                    setShowPPModal345(true);
                  }}
                >
                  Mark Complete
                </Button>
              );
            } else {
              return <></>;
            }
          case 6:
            if (permissions?.role_type === "Lab") {
              return (
                <>
                  <Button
                    size="sm"
                    variant="warning"
                    className="rounded-pill small-font"
                    onClick={() => {
                      setCurrentPP(pp);
                      setShowPPModal6(true);
                    }}
                  >
                    {progressPoints[5].status === "Rejected"
                      ? "Resubmit"
                      : "Submit"}{" "}
                    Checkout Package
                  </Button>
                </>
              );
            } else {
              return <></>;
            }
          case 7:
            if (
              (permissions?.role_type === "Validator" ||
                permissions?.role_type === "NIAP") &&
              isValidator?.is_validator
            ) {
              return (
                <Container>
                  <Row className="d-flex justify-content-center">
                    {progressPoints[5].status !== "In Progress" &&
                      progressPoints[5].status !== "Rejected" &&
                      (permissions?.is_senior === true ||
                        permissions?.is_lead === true ||
                        (permissions?.role_type === "NIAP" &&
                          isValidator?.is_validator)) && (
                        <Col sm={10}>
                          <Row className="mb-1">
                            <Button
                              size="sm"
                              variant="warning"
                              className="rounded-pill small-font p-1"
                              onClick={() => {
                                setCurrentPP(progressPoints[5]);
                                setRejectionType("Amended Package");
                                amendedPackageNeeded();
                              }}
                            >
                              Amended Package Needed
                            </Button>
                          </Row>

                          <Row className="mb-1">
                            <Button
                              size="sm"
                              variant="success"
                              className="rounded-pill small-font p-1"
                              onClick={() => {
                                setCurrentPP(pp);
                                setShowValidatorApproveCheckoutModal(true);
                              }}
                            >
                              Submit Final Validator Check
                            </Button>
                          </Row>
                        </Col>
                      )}
                  </Row>
                </Container>
              );
            } else {
              return <></>;
            }
          case 8:
            if (
              progressPoints[6]?.status === "Completed" &&
              permissions?.role_type === "NIAP"
            ) {
              return (
                <Button
                  size="sm"
                  variant="success"
                  className="rounded-pill small-font"
                  //disabled if checks are less than 8 or include an unchecked option
                  disabled={
                    readyToPostChecks.length < 7 ||
                    readyToPostChecks.some((obj) =>
                      Object.values(obj).includes(false),
                    )
                  }
                  onClick={handleCertifyProduct}
                >
                  Post to PCL
                </Button>
              );
            } else {
              return <></>;
            }
          default:
            return;
        }
      case "Awaiting Checkout":
        switch (pp?.progress_point) {
          case 6:
            if (
              (permissions?.role_type === "Validator" ||
                permissions?.role_type === "NIAP") &&
              isValidator?.is_validator &&
              progressPoints[5]?.status === "Awaiting Checkout" &&
              progressPoints[5]?.resubmission_counter == null &&
              progressPoints[6]?.status !== "Ready for Release"
            ) {
              return (
                <Button
                  size="sm"
                  variant="danger"
                  className="rounded-pill small-font"
                  onClick={() => {
                    setCurrentPP(pp);
                    setRejectionType("Rejection");
                    setShowValidatorCheckoutModal(true);
                  }}
                >
                  Reject Package
                </Button>
              );
            } else {
              return <></>;
            }
          default:
            return;
        }
      case "Resubmitted":
        switch (pp?.progress_point) {
          case 6:
            if (
              (permissions?.role_type === "Validator" ||
                permissions?.role_type === "NIAP") &&
              isValidator?.is_validator &&
              progressPoints[6]?.status !== "Ready for Release"
            ) {
              return (
                <Button
                  size="sm"
                  variant="danger"
                  className="rounded-pill small-font"
                  onClick={() => {
                    setCurrentPP(pp);
                    setRejectionType("Rejection");
                    setShowValidatorCheckoutModal(true);
                  }}
                >
                  Reject Package
                </Button>
              );
            } else {
              return <></>;
            }
          default:
            return;
        }
      case "Ready for Release":
        switch (pp?.progress_point) {
          case 7:
            if (
              (permissions?.role_type === "Validator" &&
                isValidator?.is_validator === true &&
                permissions?.is_senior === true) ||
              (permissions?.role_type === "NIAP" && isValidator?.is_validator)
            ) {
              return (
                <Button
                  size="sm"
                  variant="success"
                  className="rounded-pill small-font"
                  disabled={
                    readyToPostChecks.length < 7 ||
                    readyToPostChecks.some((obj) =>
                      Object.values(obj).includes(false),
                    )
                  }
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowValidatorRecommendPostingModal(true);
                  }}
                >
                  Recommend for Posting
                </Button>
              );
            } else {
              return <></>;
            }
          default:
            return;
        }
      case "Completed":
        switch (pp?.progress_point) {
          case 2:
            if (
              Object.keys(meeting).length === 0 &&
              permissions?.role_type === "NIAP"
            ) {
              return (
                <Button
                  size="sm"
                  variant="warning"
                  className="rounded-pill"
                  onClick={() => {
                    setCurrentPP(pp);
                    setShowScheduleModal(true);
                  }}
                >
                  Schedule Meeting
                </Button>
              );
            } else {
              return <></>;
            }
          case 3:
          case 4:
          case 5:
            return (
              <Container>
                <Row>
                  <Col>
                    <Row>
                      <small className="small-font">Submitted by</small>
                    </Row>
                    <Row>
                      <small className="small-font">
                        {pp?.editor
                          ? pp?.editor?.first_name + " " + pp?.editor?.last_name
                          : pp?.creator?.first_name +
                            " " +
                            pp?.creator?.last_name}
                      </small>
                    </Row>
                    <Row>
                      <small className="mb-3 small-font">
                        on{" "}
                        {moment(
                          pp?.submission_dates?.[0]?.estimated_date,
                        ).format("MM/DD/YYYY")}
                      </small>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          case 6:
            return (
              <Container>
                <Row>
                  <Col>
                    <Row>
                      <small className="small-font">Submitted by</small>
                    </Row>
                    <Row>
                      <small className="small-font">
                        {pp?.editor
                          ? pp?.editor?.first_name + " " + pp?.editor?.last_name
                          : pp?.creator?.first_name +
                            " " +
                            pp?.creator?.last_name}
                      </small>
                    </Row>
                    <Row>
                      <small className="mb-3 small-font">
                        on{" "}
                        {moment
                          .utc(
                            pp?.submission_dates?.[
                              pp?.submission_dates?.length - 1
                            ]?.estimated_date,
                          )
                          .format("MM/DD/YYYY")}
                      </small>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          case 7:
          default:
            return (
              <Container>
                <Row>
                  <Col>
                    <Row>
                      <small className="small-font">Submitted by</small>
                    </Row>
                    <Row>
                      <small className="small-font">
                        {pp?.editor
                          ? pp?.editor?.first_name + " " + pp?.editor?.last_name
                          : pp?.creator?.first_name +
                            " " +
                            pp?.creator?.last_name}
                      </small>
                    </Row>
                    <Row>
                      <small className="mb-3 small-font">
                        on{" "}
                        {moment
                          .utc(
                            pp?.submission_dates?.[
                              pp?.submission_dates?.length - 1
                            ]?.estimated_date,
                          )
                          .format("MM/DD/YYYY")}
                      </small>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
        }
      default:
        return <></>;
    }
  };

  const getNistReview = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/get_by_product/?product=${product.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setNistReview(response.data);
        })
        .catch(() => {
          setNistReview({});
        });
    }
  }, [product?.product_id]);

  useEffect(() => {
    getNistReview();
  }, [getNistReview]);

  const refresh = () => {
    loadProject();
    fetchProductPP();
    fetchProjectMaintenance();
  };

  const showPageComponents = () => {
    if (
      //validator final review page
      (permissions?.role_type === "Validator" ||
        permissions?.role_type === "NIAP") &&
      isValidator?.is_validator &&
      product.cctl_checkout_signer !== null &&
      product.vendor_checkout_signer !== null &&
      progressPoints.length > 0 &&
      progressPoints[6].status === "Ready for Release"
    ) {
      return (
        <>
          <Col sm={12} md={8} lg={9}>
            <Row>
              <Col sm={12} className="mt-3">
                <ProductDetailsHeaderInfo
                  product={product}
                  projpps={projpps}
                  maintenances={maintenances}
                  setShow={setShow}
                  isValidator={isValidator}
                  certificate={certificate}
                  downloadFile={downloadFile}
                  user={currentUser}
                  showModal={handleOpenKickoffModal}
                  showValidatorModal={handleOpenValidatorKickoffModal}
                  validatorFinalReview={true}
                  refresh={refresh}
                />
              </Col>
              <Col sm={12} className="mt-3">
                <ProductDetailsSubheader
                  product={product}
                  loadProject={loadProject}
                />
              </Col>
              <Col sm={12}>
                <h5 className="fw-bold mt-3">PROGRESS POINTS</h5>
              </Col>
              {progressPoints.length > 0 && (
                <ProgressPoints
                  progressPoints={progressPoints}
                  getEditButton={getEditButton}
                  showButtons={showButtons}
                  product={product}
                />
              )}
              <Col sm={12} className="mt-3" id="accordion-section">
                <PostingAccordion
                  permissions={permissions}
                  project={product}
                  refetch={loadProject}
                  readyToPostChecks={readyToPostChecks}
                  handleReadyToPostCheck={handleReadyToPostCheck}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <PostingSideInfo
              product={product}
              files={files}
              readyToPostChecks={readyToPostChecks}
              handleReadyToPostCheck={handleReadyToPostCheck}
              nistReview={nistReview}
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
              setAlert={setAlert}
            />
          </Col>
        </>
      );
    } else if (permissions?.role_type === "Vendor") {
      return (
        <>
          <Col sm={12} md={8} lg={9}>
            <Row>
              <Col sm={12} className="mt-3">
                {progressPoints[6]?.status === "Ready for Release" ? (
                  <PostingHeader
                    product={product}
                    refetch={loadProject}
                    projpps={projpps}
                    validationReport={
                      validationReport.file_label
                        ? validationReport
                        : finalDraftValidationReport
                    }
                    vendorSignedCheckout={vendorSignedCheckout}
                    pcl={signedCheckIn}
                    securityTarget={securityTarget}
                    certificate={certificate}
                    assuranceActivityReport={assuranceActivityReport}
                    downloadFile={downloadFile}
                    setShowCertificate={setShowCertificate}
                    setOverlayActive={setOverlayActive}
                    setOverlayText={setOverlayText}
                  />
                ) : (
                  <ProductDetailsHeaderInfo
                    product={product}
                    projpps={projpps}
                    maintenances={maintenances}
                    setShow={setShow}
                    isValidator={isValidator}
                    certificate={certificate}
                    downloadFile={downloadFile}
                    user={currentUser}
                    showModal={handleOpenKickoffModal}
                    showValidatorModal={handleOpenValidatorKickoffModal}
                    refresh={refresh}
                  />
                )}
              </Col>
              <Col sm={12} className="mt-3" id="accordion-section">
                <NonEditableAccordion project={product} />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <PostingSideInfo
              permissions={permissions}
              product={product}
              files={files}
              readyToPostChecks={readyToPostChecks}
              handleReadyToPostCheck={handleReadyToPostCheck}
              readOnly={true}
              nistReview={nistReview}
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
              setAlert={setAlert}
            />
          </Col>
        </>
      );
    } else if (
      // niap posting view
      permissions.role_type === "NIAP" &&
      progressPoints.length > 0 &&
      progressPoints[7].status === "In Progress"
    ) {
      return (
        <>
          <Col sm={12} md={8} lg={9}>
            <Row>
              <Col sm={12} className="mt-3">
                <NiapPostingHeader
                  product={product}
                  maintenances={maintenances}
                  refetch={loadProject}
                  projpps={projpps}
                  certificate={certificate}
                  downloadFile={fetchBlankCertification}
                  readyToPostChecks={readyToPostChecks}
                />
              </Col>
              <Col sm={12} className="mt-3">
                <ProductDetailsSubheader
                  product={product}
                  loadProject={loadProject}
                />
              </Col>
              <Col sm={12}>
                <h5 className="fw-bold mt-3">PROGRESS POINTS</h5>
              </Col>
              <ProgressPoints
                progressPoints={progressPoints}
                getEditButton={getEditButton}
                showButtons={showButtons}
                product={product}
              />
              <Col
                sm={12}
                id="posting-subheader-details"
                className="border-top border-dark"
              >
                <PostingSubheaderDetails
                  product={product}
                  validationReport={
                    validationReport.file_label
                      ? validationReport
                      : finalDraftValidationReport
                  }
                  projpps={projpps}
                  setShowCertificate={setShowCertificate}
                  setOverlayActive={setOverlayActive}
                  setOverlayText={setOverlayText}
                />
                {product?.validator_posting_verifier && (
                  <NiapClearPostingVerifier
                    clearRecommendationNote={clearRecommendationNote}
                    product={product}
                    readyToPostChecks={readyToPostChecks}
                    setReadyToPostChecks={setReadyToPostChecks}
                    refreshPage={refreshPage}
                    refetch={() => {
                      getProgressPoints();
                      loadProject();
                    }}
                    progressPoint={progressPoints[6]}
                    setRefetchNotes={setRefetchNotes}
                  />
                )}
              </Col>
              <Col sm={12} className="mt-3" id="accordion-section">
                <PostingAccordion
                  permissions={permissions}
                  project={product}
                  refetch={loadProject}
                  readyToPostChecks={readyToPostChecks}
                  handleReadyToPostCheck={handleReadyToPostCheck}
                  setOverlayActive={setOverlayActive}
                  setOverlayText={setOverlayText}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <SideInfo
              product={product}
              updateSidebarFiles={updateSidebarFiles}
              refetch={loadProject}
              validationTeam={validationTeam}
              nistReview={nistReview}
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
              setAlert={setAlert}
            />
          </Col>
        </>
      );
    } else if (product.certification_status === "Certified") {
      return (
        <>
          <Col sm={12} md={8} lg={9}>
            <Row>
              <Col sm={12} className="mt-3">
                <ProductDetailsHeaderInfo
                  product={product}
                  projpps={projpps}
                  maintenances={maintenances}
                  setShow={setShow}
                  isValidator={isValidator}
                  user={currentUser}
                  certificate={certificate}
                  downloadFile={fetchBlankCertification}
                  showModal={handleOpenKickoffModal}
                  showValidatorModal={handleOpenValidatorKickoffModal}
                  refresh={refresh}
                />
              </Col>
              <Col sm={12} className="mt-3">
                <ProductDetailsSubheader
                  product={product}
                  loadProject={loadProject}
                />
              </Col>
              <Col sm={12}>
                <h5 className="fw-bold mt-3">PROGRESS POINTS</h5>
              </Col>
              {progressPoints.length > 0 && (
                <ProgressPoints
                  progressPoints={progressPoints}
                  getEditButton={getEditButton}
                  showButtons={showButtons}
                  product={product}
                />
              )}
              {progressPoints.length > 0 &&
                progressPoints[6].status === "Ready for Release" &&
                assignedOrg && (
                  <Col
                    sm={12}
                    className="mt-3 border-top border-bottom border-dark"
                    id="pcl-section"
                  >
                    <PCLReviewDetails
                      product={product}
                      pcl={signedCheckIn}
                      labSignedCheckout={labSignedCheckout}
                      securityTarget={securityTarget}
                      validationReport={
                        validationReport.file_label
                          ? validationReport
                          : finalDraftValidationReport
                      }
                      certificate={certificate}
                      assuranceActivityReport={assuranceActivityReport}
                      setOverlayActive={setOverlayActive}
                      setOverlayText={setOverlayText}
                      refetch={() => {
                        loadProject();
                      }}
                    />
                  </Col>
                )}
              <Col sm={12} className="mt-3" id="accordion-section">
                <ProjectDetailsAccordion
                  id={id}
                  project={product}
                  refetch={loadProject}
                  assignedOrg={assignedOrg}
                  permissions={permissions}
                  setOverlayActive={setOverlayActive}
                  setOverlayText={setOverlayText}
                />
                <Notes
                  item={product}
                  note_type="product"
                  user={currentUser}
                  id={id}
                  refetchNotes={refetchNotes}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} lg={3}>
            {permissions?.role_type === "NIAP" ||
            permissions?.role_type === "Validator" ||
            permissions?.role_type === "Lab" ? (
              <SideInfo
                product={product}
                updateSidebarFiles={updateSidebarFiles}
                refetch={loadProject}
                validationTeam={validationTeam}
                nistReview={nistReview}
                setOverlayActive={setOverlayActive}
                setOverlayText={setOverlayText}
                setAlert={setAlert}
              />
            ) : (
              <PostingSideInfo
                permissions={permissions}
                product={product}
                files={files}
                readyToPostChecks={readyToPostChecks}
                handleReadyToPostCheck={handleReadyToPostCheck}
                validationTeam={validationTeam}
                readOnly={true}
                nistReview={nistReview}
                setOverlayActive={setOverlayActive}
                setOverlayText={setOverlayText}
                setAlert={setAlert}
              />
            )}
          </Col>
        </>
      );
    } else {
      //regular project details page
      return (
        <>
          <Col sm={12} md={8} lg={9}>
            <Row>
              <Col sm={12} className="mt-3">
                <ProductDetailsHeaderInfo
                  product={product}
                  projpps={projpps}
                  maintenances={maintenances}
                  setShow={setShow}
                  isValidator={isValidator}
                  user={currentUser}
                  certificate={certificate}
                  downloadFile={downloadFile}
                  showModal={handleOpenKickoffModal}
                  showValidatorModal={handleOpenValidatorKickoffModal}
                  refresh={refresh}
                />
              </Col>
              <Col sm={12} className="mt-3">
                <ProductDetailsSubheader
                  product={product}
                  loadProject={loadProject}
                />
              </Col>
              <Col sm={12}>
                <h5 className="fw-bold mt-3">PROGRESS POINTS</h5>
              </Col>
              {progressPoints.length > 0 && (
                <ProgressPoints
                  progressPoints={progressPoints}
                  getEditButton={getEditButton}
                  showButtons={showButtons}
                  product={product}
                />
              )}
              {progressPoints.length > 0 &&
                progressPoints[6].status === "Ready for Release" &&
                assignedOrg && (
                  <Col
                    sm={12}
                    className="mt-3 border-top border-bottom border-dark"
                    id="pcl-section"
                  >
                    <PCLReviewDetails
                      product={product}
                      pcl={signedCheckIn}
                      labSignedCheckout={labSignedCheckout}
                      securityTarget={securityTarget}
                      validationReport={
                        validationReport.file_label
                          ? validationReport
                          : finalDraftValidationReport
                      }
                      certificate={certificate}
                      assuranceActivityReport={assuranceActivityReport}
                      setOverlayActive={setOverlayActive}
                      setOverlayText={setOverlayText}
                      refetch={() => {
                        loadProject();
                      }}
                    />
                  </Col>
                )}
              <Col sm={12} className="mt-3" id="accordion-section">
                <ProjectDetailsAccordion
                  id={id}
                  project={product}
                  refetch={loadProject}
                  assignedOrg={assignedOrg}
                  permissions={permissions}
                  setOverlayActive={setOverlayActive}
                  setOverlayText={setOverlayText}
                />
                <Notes
                  item={product}
                  note_type="product"
                  user={currentUser}
                  id={id}
                  refetchNotes={refetchNotes}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4} lg={3}>
            <SideInfo
              product={product}
              updateSidebarFiles={updateSidebarFiles}
              refetch={loadProject}
              validationTeam={validationTeam}
              nistReview={nistReview}
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
              setAlert={setAlert}
            />
          </Col>
        </>
      );
    }
  };

  return (
    <>
      <Container fluid>
        {showInitiateProgressModel && (
          <InitiateProgressModal
            show={showInitiateProgressModel}
            handleClose={() => handleCloseInitiateProgressModal()}
            product={product}
            progressPoint={currentPP}
            isEdit={progressPointEdit}
            isValidator={isValidator}
            refetch={() => {
              getProgressPoints();
              loadProject();
            }}
          />
        )}
        {showPPModal345 && (
          <ProgressPointModal345
            show={showPPModal345}
            handleClose={handleCloseProgressPointModal}
            product={product}
            progressPoint={currentPP}
            readOnly={progressPointModalReadOnly}
            allFiles={files}
            isEdit={progressPointEdit}
            refetch={() => {
              getProgressPoints();
              loadProject();
              getNistReview();
              updateFiles();
            }}
            setRefetchNotes={setRefetchNotes}
          />
        )}
        {showPPModal6 && (
          <ProgressPointModal6
            show={showPPModal6}
            handleClose={handleCloseProgressPointModal}
            product={product}
            setProduct={setProduct}
            protectionProfiles={projpps}
            progressPoint={currentPP}
            updateProgress={updateProgress}
            readOnly={progressPointModalReadOnly}
            allFiles={files}
            isEdit={progressPointEdit}
            isValidator={isValidator}
            refetch={() => {
              getProgressPoints();
              loadProject();
              getNistReview();
              updateFiles();
            }}
            setRefetchNotes={setRefetchNotes}
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        )}
        {showPPModal7 && (
          <ProgressPointEditModal7
            show={showPPModal7}
            handleClose={handleCloseProgressPointModal}
            product={product}
            setProduct={setProduct}
            protectionProfiles={projpps}
            progressPoint={currentPP}
            updateProgress={updateProgress}
            readOnly={progressPointModalReadOnly}
            allFiles={files}
            isEdit={progressPointEdit}
            isValidator={isValidator}
            refetch={() => {
              getProgressPoints();
              loadProject();
              getNistReview();
              updateFiles();
            }}
            setRefetchNotes={setRefetchNotes}
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        )}
        {showPPModal8 && (
          <ProgressPointEditModal8
            show={showPPModal8}
            handleClose={handleCloseProgressPointModal}
            product={product}
            setProduct={setProduct}
            protectionProfiles={projpps}
            progressPoint={currentPP}
            updateProgress={updateProgress}
            readOnly={progressPointModalReadOnly}
            allFiles={files}
            isEdit={progressPointEdit}
            isValidator={isValidator}
            refetch={() => {
              getProgressPoints();
              loadProject();
              getNistReview();
              updateFiles();
            }}
            setRefetchNotes={setRefetchNotes}
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        )}
        <Row>
          <Col sm={12} md={8} lg={9}>
            <Row>
              {alert.message && (
                <Alert
                  variant={alert.variant}
                  onClose={() => setAlert({})}
                  dismissible
                >
                  {alert.message}
                </Alert>
              )}
              {kickoffAlert.message && (
                <Alert
                  variant={kickoffAlert.variant}
                  dismissible
                  onClose={() => setKickoffAlert({})}
                >
                  {kickoffAlert.message} for VID: {product.v_id}
                </Alert>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <WithdrawalTerminationFeedbackHeader
            product={product}
            setAlertInfo={setAlert}
            progressPoints={progressPoints}
            validationReport={
              validationReport.file_label
                ? validationReport
                : finalDraftValidationReport
            }
            refetch={loadProject}
          />
        </Row>
        <Row>{showPageComponents()}</Row>
        <FileUpload
          show={show}
          updateFiles={updateFiles}
          type="product"
          id={id}
          vid={product.v_id}
        />
        <ValidatorKickoffModal
          show={showValidatorKickoffModal}
          onHide={handleCloseValidatorKickoffModal}
          product={product}
          refetch={() => {
            getProgressPoints();
            loadProject();
            setShowValidatorKickoffModal(false);
          }}
        />
        <ProductKickoffModal
          show={showKickoffModal}
          handleClose={handleCloseProductKickoffModal}
          product={product}
          productPersonnel={validationTeam}
          refetch={() => {
            getProgressPoints();
            loadProject();
          }}
        />
        <ScheduleMeetingModal
          show={showScheduleModal}
          product={product}
          handleClose={() => {
            setProgressPointEdit(false);
            setShowScheduleModal(false);
          }}
          isEdit={progressPointEdit}
          user={currentUser}
          meetingInfo={meeting}
          refetch={() => {
            getMeeting();
            getProgressPoints();
          }}
          progressPoint={currentPP}
          refetchProgress={() => getProgressPoints()}
          validationTeam={validationTeam}
        />
        <ValidatorCheckoutApprovalModal
          show={showValidatorApproveCheckoutModal}
          handleClose={() => {
            setShowValidatorApproveCheckoutModal(false);
          }}
          product={product}
          setProduct={setProduct}
          currentPP={currentPP}
          refetch={() => {
            getProgressPoints();
            updateFiles();
          }}
          setAlert={setAlert}
        />
        <ValidatorCheckoutRejectionModal
          show={showValidatorCheckoutModal}
          handleClose={() => {
            setShowValidatorCheckoutModal(false);
          }}
          project={product}
          currentPP={currentPP}
          refetch={() => {
            getProgressPoints();
            updateFiles();
          }}
          setAlert={setAlert}
          reasoning={rejectionType}
          setRefetchNotes={setRefetchNotes}
        />
        <ValidatorRecommendPostingModal
          show={showValidatorRecommendPostingModal}
          handleClose={() => {
            setShowValidatorRecommendPostingModal(false);
          }}
          product={product}
          files={files}
          refreshPage={refreshPage}
          refetch={() => {
            getProgressPoints();
            loadProject();
          }}
          currentPP={currentPP}
        />
        <CertificateModal
          show={showCertificate}
          handleClose={() => setShowCertificate(false)}
          product={product}
          validationReport={
            validationReport.file_label
              ? validationReport
              : finalDraftValidationReport
          }
          protectionProfiles={projpps}
          refetch={loadProject}
        />
      </Container>
    </>
  );
}

export default CheckProductPermDetails;
